import React, { Fragment } from 'react';

import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css'; 

import { Oval } from  'react-loader-spinner';
import { POST, validateERROR } from '../commons/Service';
import { Button, Modal, TextField, Autocomplete } from '@mui/material';

class Endereco extends React.Component {

    constructor(props) {
        super();
        this.state = {
            clientHeight: window.innerHeight - 160, 
            validando: false,
            labelloader: '', 
            idcidade: 0,
            idbairro: 0,

            cidadedisplay: '', 
            bairrodisplay: '', 
            complemento: '',
            endereco: '',
            numero: '',
            CEP: '',
            
            cidadeslist: [],   
            bairroslist: [], 
            cidades: [],
            bairros: []
        }
    }

    componentDidMount() {
        window.addEventListener('resize', this.handleResizedScreen);

        let cidadeslist = [];
        for (let index = 0; index < this.props.counties.length; index++) {
            const countie = this.props.counties[index];
            let name = `${countie.name} - ${countie.uf}`;            
            let item = cidadeslist.find(({ label }) => label === name);

            if (!item){
                cidadeslist.push({ label: name, id: countie.id });
            }
        }

        this.setState({ 
            cidades: this.props.counties,
            bairros: this.props.districts,
            cidadeslist: cidadeslist
        })
    }

    handleResizedScreen = () => {        
        this.setState({ clientHeight: window.innerHeight - 160 });
    };
      
    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResizedScreen);
    }

    onSelectCountie = (cidade) => {
        let limparBairro = true;
        if ((this.state.idcidade > 0) && 
            (cidade && (cidade.id > 0)) && 
            (this.state.idcidade === cidade.id)){
            limparBairro = false;
        }    

        if (cidade && (cidade.id > 0)){
            let lista = this.state.bairros.filter((bairro) => { return bairro.county === cidade.id });
            
            let bairroslist = [];
            for (let index = 0; index < lista.length; index++) {
                const district = lista[index];           
                let item = bairroslist.find(({ label }) => label === district.name);

                if (!item){
                    bairroslist.push({ label: district.name, id: district.id });
                }
            }

            let idbairro = limparBairro ? 0 : this.state.idbairro;
            let bairrodisplay = limparBairro ? '' : this.state.bairrodisplay;
            this.setState({ 
                cidadedisplay: cidade.label,
                bairrodisplay: bairrodisplay,
                bairroslist: bairroslist, 
                idcidade: cidade.id, 
                idbairro: idbairro
            });
        } else {
            this.setState({ 
                cidadedisplay: '',
                bairrodisplay: '',
                bairroslist: [], 
                idcidade: 0, 
                idbairro: 0
            });
        }
    }

    onSelectDistrict = (bairro) => {
        if (bairro && (bairro.id > 0)){
            this.setState({ 
                bairrodisplay: bairro.label,
                idbairro: bairro.id
            })
        } else {
            this.setState({ bairrodisplay: '', idbairro: 0 })
        }
    }

    onConfirmar = () => {
        if (this.state.CEP === ''){
            confirmAlert({
                title: 'Atenção!',
                message: 'CEP não informado.',
                buttons: [{ label: 'Ok' }],
                closeOnEscape: false,
                closeOnClickOutside: false,
                overlayClassName: "class-alert-livn"
            })
        } else {
            let mensagem = this.validaOnObrigatorios();
            if (mensagem === ''){

                this.setState({ labelloader: 'Consultado CEP. Aguarde...', validando: true }, () => {
                    POST('person/address/' + this.props.personId + '/', {
                        county: this.state.idcidade, 
                        address: this.state.endereco,
                        number: this.state.numero,
                        complement: this.state.complemento,
                        cep: this.state.CEP,
                        district: this.state.idbairro
                    }, 20000).then((result) => {
                        
                        this.setState({ validando: false }, () => {
                            this.props.onConfirme(result)
                        })                        
                        
                    }).catch((error) => {
                        let message = '';
                        let erro = validateERROR(error);
                        if ((erro) && (erro.message) && (erro.message !== '')){ 
                            message = erro.message;
                        } else {
                            message = 'Erro ao salvar novo endereço';
                        }
                        this.setState({ validando: false }, () => {
                            confirmAlert({
                                title: 'Erro ao salvar endereço!',
                                message: message,
                                buttons: [{ label: 'Ok' }],
                                closeOnEscape: false,
                                closeOnClickOutside: false,
                                overlayClassName: "class-alert-livn"
                            }) 
                        })        
                    })
                })                    

            } else {
                confirmAlert({
                    title: 'Atenção!',
                    message: `Informações obrigatórias não preenchidas: \n${mensagem}`,
                    buttons: [{ label: 'Ok' }],
                    closeOnEscape: false,
                    closeOnClickOutside: false,
                    overlayClassName: "class-alert-livn"
                })
            }
        }
    }

    validaOnObrigatorios = () => {
        let mensagem = '';
        if (this.state.idcidade === 0){
            mensagem += ` » MUNICÍPIO\n`;
        }
        if (this.state.idbairro === 0){
            mensagem += ` » BAIRRO\n`;
        }
        if (this.state.endereco.trim() === ''){
            mensagem += ` » ENDEREÇO\n`;
        }
        if (this.state.numero.trim() === ''){
            mensagem += ` » NUMERO\n`;
        }
        return mensagem;
    }
    
    onChangeCEP = (text) => {
        var cep = text.replace(/[^0-9]/g,'');
        this.setState({ CEP: cep }, () => {
            if (cep.length === 8){
                this.onValidaCPF();
            }
        });
    }

    onValidaCPF = () => {
        if(this.state.CEP.length === 8) {
            this.setState({ labelloader: 'Consultado CEP. Aguarde...', validando: true }, () => {

                fetch(`https://viacep.com.br/ws/${this.state.CEP}/json/`)
                    .then((res) => res.json())
                    .then((data) => {
                        let idcidade = this.state.idcidade;
                        let endereco = this.state.endereco;
                        let complemento = this.state.complemento;
                        let cidadedisplay = this.state.cidadedisplay;

                        if (data.ibge && data.ibge !== ''){                            
                            let county = this.state.cidades.find(({ ibge }) => ibge === data.ibge); 
                            if (county && (county.id > 0)){
                                idcidade = county.id;
                                cidadedisplay = county.name;

                                let limparBairro = true;
                                if ((this.state.idcidade > 0) && 
                                    (this.state.idcidade === county.id)){
                                    limparBairro = false;
                                } 

                                if (limparBairro){
                                    let lista = this.state.bairros.filter((bairro) => { return bairro.county === county.id });                                
                                    let bairroslist = [];
                                    for (let index = 0; index < lista.length; index++) {
                                        const district = lista[index];           
                                        let item = bairroslist.find(({ label }) => label === district.name);
                        
                                        if (!item){
                                            bairroslist.push({ label: district.name, id: district.id });
                                        }
                                    }
                        
                                    let idbairro = 0;
                                    let bairrodisplay = '';

                                    /* Tenta localizar o bairro pelo nome */
                                    if (data.bairro !== ''){
                                        let districtname = data.bairro.toUpperCase();
                                        let item = bairroslist.find(({ label }) => label === districtname);
                                        if (item && item.id > 0){
                                            idbairro = item.id;
                                            bairrodisplay = item.label;
                                        }
                                    }

                                    this.setState({ 
                                        bairrodisplay: bairrodisplay,
                                        bairroslist: bairroslist, 
                                        idbairro: idbairro
                                    });
                                } else if (this.state.idbairro === 0){
                                    /* Tenta localizar o bairro pelo nome */
                                    if (data.bairro !== ''){
                                        let districtname = data.bairro.toUpperCase();
                                        let item = this.state.bairroslist.find(({ label }) => label === districtname);
                                        if (item && item.id > 0){
                                            this.setState({ bairrodisplay: item.label, idbairro: item.id });
                                        }
                                    }
                                }
                            } else {
                                let mensagem = '';
                                if (data.localidade.trim() !== ''){
                                    mensagem += ` » ${data.localidade} - ${data.uf}\n`;
                                }
                                if (data.bairro.trim() !== ''){
                                    mensagem += ` » ${data.bairro}\n`;
                                }
                                if (data.logradouro.trim() !== ''){
                                    mensagem += ` » ${data.logradouro}\n`;
                                }
                                if (data.complemento.trim() !== ''){
                                    mensagem += ` » ${data.complemento}\n`;
                                }

                                confirmAlert({
                                    title: 'Atenção!',
                                    message: `CEP informado não é valido para entrega: \n${mensagem}`,
                                    buttons: [{ label: 'Ok' }],
                                    closeOnEscape: false,
                                    closeOnClickOutside: false,
                                    overlayClassName: "class-alert-livn"
                                })
                                
                            }                           
                        }

                        if (data.logradouro && (data.logradouro !== '')){
                            endereco = data.logradouro;
                        }

                        if (data.complemento && (data.complemento !== '')){
                            endereco = data.complemento;
                        }

                        this.setState({ 
                            cidadedisplay: cidadedisplay,
                            complemento: complemento,
                            endereco: endereco,
                            validando: false, 
                            idcidade: idcidade 
                        })

                    });
            })
        }

    }

    render() {
        return ( 
            <Modal
                open={this.props.show}
                disableAutoFocus={true}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <div className='pedido-detail'>
                    {
                        this.state.validando ?
                        <div className='loader-valid'> 
                            <div className='loader-loading'>
                                <Oval
                                    height={20}
                                    width={20}
                                    color="#fff"
                                    wrapperStyle={{}}
                                    wrapperClass=""
                                    visible={true}
                                    ariaLabel='oval-loading'
                                    secondaryColor="#ffb03b"
                                    strokeWidth={5}
                                    strokeWidthSecondary={5}/>
                                <p className='loader-label-loader'>{this.state.labelloader}</p>
                            </div>
                        </div> : <Fragment />
                    }

                    <div className='pedido-detail-header' style={{marginBottom: 0}}>
                        <h2 className='pedido-title'>Cadastrar novo endereço</h2> 
                        <div className='pedido-detail-close' onClick={() => { this.props.onClose() }}><ArrowBackIosNewIcon/></div>
                    </div>    

                    <div className='pedido-detail-container' style={{maxHeight: this.state.clientHeight}}>
                        <div className='pedido-detail-document'>
                            <TextField
                                inputProps = {{autoFocus: true}}
                                variant="standard"
                                label="Informe seu CEP"
                                id="textarea-comand"
                                margin="normal" 
                                color="warning"
                                fullWidth 
                                type="number"
                                value={this.state.CEP}
                                disabled={this.state.validando}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    this.onChangeCEP(event.target.value)
                                }} /> 
                        </div>

                        <div className='dados-divider'/> 

                        <div className='MuiInputDate-div'>
                            <div style={{paddingTop: 20}}>
                                <Autocomplete
                                    fullWidth 
                                    required={true}
                                    disabled={false}
                                    id="textarea-detail"
                                    value={this.state.cidadedisplay}
                                    options={this.state.cidadeslist} 
                                    renderInput={(params) => <TextField {...params} variant="standard" label='MUNICÍPIO' />}                
                                    onChange={(event, newValue) => { this.onSelectCountie(newValue) }} />
                            </div>
                            
                            <div style={{paddingTop: 10}}>
                                <Autocomplete
                                    fullWidth 
                                    required={true}
                                    id="textarea-detail"
                                    value={this.state.bairrodisplay}
                                    options={this.state.bairroslist}
                                    disabled={this.state.idcidade === 0}
                                    renderInput={(params) => <TextField {...params} variant="standard" label='BAIRRO' />}                
                                    onChange={(event, newValue) => { this.onSelectDistrict(newValue) }} /> 
                            </div>

                            <div style={{paddingTop: 10}}>
                                <TextField
                                    variant="standard"
                                    label='ENDEREÇO'
                                    id="textarea-detail"
                                    margin="dense" 
                                    color="info"
                                    fullWidth 
                                    required={true}
                                    value={this.state.endereco}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        this.setState({ endereco: event.target.value })
                                    }} /> 
                            </div>

                            <div style={{paddingTop: 10}}>
                                <TextField
                                    variant="standard"
                                    label='NÚMERO'
                                    id="textarea-detail"
                                    color="info"
                                    fullWidth 
                                    required={true}
                                    value={this.state.numero}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        this.setState({ numero: event.target.value })
                                    }} /> 
                            </div>

                            <div style={{paddingTop: 10}}>
                                <TextField
                                    variant="standard"
                                    label='COMPLEMENTO'
                                    id="textarea-detail"
                                    margin="dense" 
                                    color="info"
                                    fullWidth 
                                    required={true}
                                    value={this.state.complemento}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        this.setState({ complemento: event.target.value })
                                    }} /> 
                            </div>
                        </div> 
                    </div> 

                    <div className='cliente-footer'>
                        <Button className='cliente-confirm' onClick={() => {this.onConfirmar()}}>
                            <p className='confirme-label'>Cadastrar endereço</p>
                        </Button>
                    </div>
                </div>
            </Modal> 
        )
    }
}

export default Endereco;
