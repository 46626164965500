import React, { Fragment } from 'react';

import RemoveIcon from '@mui/icons-material/Remove';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import AddIcon from '@mui/icons-material/Add';
import Checkbox from '@mui/material/Checkbox';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import { Button, Modal, TextField } from '@mui/material';
import { global } from '../commons/Globals';

import 'react-confirm-alert/src/react-confirm-alert.css'; 

import { getAdditionalValueComposition } from '../commons/Service.Product';

class Pizza extends React.Component {
    constructor(props) {
        super();
        this.state = {
            clientHeight: window.innerHeight - 60,
            pizza: null,
            
            items: [],
            bordas: [],
            lista: [],
            listasabores: [],
            product: null,
            sabor: null,
            valor: 0, 
            sabores: 0, 
            stage: 1
        }
    }

    componentDidMount() {
        window.addEventListener('resize', this.handleResizedScreen);
        
        let outrosSabores = false;
        let qtdeOutrosSabores = 0;
        let listasabores = [];
        let bordas = [];

        let semborda = {
            id: 0,
            product: 0,
            componentId: 0,
            variableComposition: null,
            order: 0,
            quantity: 1,
            value: 0,
            price: 0,
            default: "N",
            customize: "S",
            selected: 1, 
            component: {
                id: 0,
                salesgroup: 0,
                barcode: "",
                groupId: 0,
                type: 6,
                sheet: null,
                productionImmediateId: null,
                productionScheduledId: null,
                name: "SEM BORDA",
                unit: "UN",
                enabled: "S",
                manufactured: "N",
                productTypeControl: "N",
                typeControlStock: null,
                stock: 1,
                manufacturedAntecipated: "N",
                serviceProvision: "N",
                quantityFlavor: null,
                unitPrice: 0,
                barcodes: [],
                structure: []
            }
        }
        bordas.push(semborda);        

        let _permiteEditar = false;        
        for (let index = 0; index < this.props.pizza.structure.length; index++) {
            const element = this.props.pizza.structure[index];
            
            if (element.component && (element.component.id > 0)){ 
                if (element.component.type === 5){
                    outrosSabores = true;
                    qtdeOutrosSabores += 1;
                } else if (element.component.type === 6){
                    const myObj = JSON.stringify(element)
                    let borda = JSON.parse(myObj)
                    bordas.push(borda)
                }

            } else if ( (element.variable) && 
                        (element.variable.components) &&
                        (element.variable.components.length > 0)){   
                let sabor = {id: 0, name: `${element.variable.name} ( ${element.variable.components.length} )`, component: {name: ''}};
                listasabores.push(sabor);

                for (let c = 0; c < element.variable.components.length; c++) {
                    const component = element.variable.components[c];                        
                    if ((component) && (component.id > 0)){
                        const myObj = JSON.stringify(component);
                        let sabor = JSON.parse(myObj);
                        listasabores.push(sabor);
                                   
                        if (component.component && (component.component.id > 0)){ 
                            if (component.component.permite_editar){
                                _permiteEditar = true;
                            }
                        }
                    }
                }
            }              
        } 
        
        if (outrosSabores){
            let sabor = {id: 0, name: `Outros sabores ( ${qtdeOutrosSabores} )`, component: {name: ''}};
            listasabores.push(sabor);
            
            for (let index = 0; index < this.props.pizza.structure.length; index++) {
                const element = this.props.pizza.structure[index];                
                if (element.component && (element.component.id > 0)){ 
                    if (element.component.type === 5){                        
                        const myObj = JSON.stringify(element);
                        let sabor = JSON.parse(myObj);
                        listasabores.push(sabor);

                        if (element.component.permite_editar){
                            _permiteEditar = true;
                        }
                    }     
                }
            }   
        }

        const pizzaJSON = JSON.stringify(this.props.pizza);
        let pizza = JSON.parse(pizzaJSON);
        pizza.note = '';

        this.setState({
            bordas: bordas,
            lista: listasabores,
            listasabores: listasabores,
            outrosSabores: outrosSabores, 
            permiteEditar: _permiteEditar,
            items: this.props.pizza.structure, 
            pizza: pizza
        })
    }

    handleResizedScreen = () => {        
        this.setState({ clientHeight: window.innerHeight - 60 });
    };
      
    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResizedScreen);
    }

    getName = (texto) => {
        let nameFormat = '';
        if (texto && (texto !== '')){
            nameFormat = texto.charAt(0).toUpperCase() + texto.slice(1).toLowerCase();
        }  
        return nameFormat;      
    }    

    onPressAdd = (item) => {
        if ((item.component) && (item.component.id > 0)){  
            this.addItem(item);
        }
    }

    addItem = (item) => {
        let lista  = [...this.state.items]
        let sabores = 0;
        
        for (let index = 0; index < lista.length; index++) {
            const element = lista[index];

            if ((element.variable) && 
                (element.variable.components) &&
                (element.variable.components.length > 0)){
                
                for (let c = 0; c < element.variable.components.length; c++) {
                    const component = element.variable.components[c];
                    
                    if ((component) && (component.id > 0) && (component.id === item.id)){
                        if (component.selected){
                            component.selected += 1
                        } else { 
                            component.selected = 1 
                        }
                    }

                    if ((component) && (component.id > 0) && (component.selected) && (component.selected > 0)){
                        sabores += component.selected
                    }
                }
            } else if (element.component && (element.component.id > 0)){ 
                if (element.id === item.id){
                    if (element.selected){
                        element.selected += 1
                    } else { 
                        element.selected = 1 
                    }
                }

                if ((element.component.type === 5) && (element.selected > 0)){
                    sabores += element.selected
                } 
            }
        }
        
        let listasabores  = [...this.state.listasabores]
        for (let index = 0; index < listasabores.length; index++) {
            const element = listasabores[index];
            if (element.id === item.id){
                if (element.selected){
                    element.selected += 1
                } else { 
                    element.selected = 1 
                }
                break;
            }
        }
        
        this.setState({ 
            items: lista, 
            sabores: sabores, 
            listasabores: listasabores,
            valor: getAdditionalValueComposition(lista, true, 1) 
        });
    }

    onPressRemove = (item) => {        
        let lista  = [...this.state.items]
        let sabores = 0;

        for (let index = 0; index < lista.length; index++) {
            const element = lista[index];

            if ((element.variable) && 
                (element.variable.components) &&
                (element.variable.components.length > 0)){
                
                for (let c = 0; c < element.variable.components.length; c++) {
                    const component = element.variable.components[c];
                    
                    if ((component) && (component.id > 0) && (component.id === item.id)){
                        if (component.selected){
                            component.selected -= 1
                        } else { 
                            component.selected = 0 
                        }
                    }

                    if ((component) && (component.id > 0) && (component.selected) && (component.selected > 0)){
                        sabores += component.selected
                    }
                }
            } else if (element.component && (element.component.id > 0)){
                if (element.id === item.id){
                    if (element.selected){
                        element.selected -= 1
                    } else { 
                        element.selected = 0 
                    }
                }

                if ((element.component.type === 5) && (element.selected > 0)){
                    sabores += element.selected
                } 
            }
            
        }

        let listasabores  = [...this.state.listasabores]
        for (let index = 0; index < listasabores.length; index++) {
            const element = listasabores[index];
            if (element.id === item.id){
                if (element.selected){
                    element.selected -= 1
                } else { 
                    element.selected = 0 
                }
                break;
            }
        }
        
        this.setState({ 
            items: lista, 
            sabores: sabores, 
            listasabores: listasabores,
            valor: getAdditionalValueComposition(lista, true, 1) 
        });
    }

    onPressBorda = (borda) => {
        if (borda.id === 0){            
            let lista = [...this.state.items]
            for (let index = 0; index < lista.length; index++) {
                const element = lista[index];
    
                if (element.component){
                    if (element.id === 0){
                        element.selected = 1
                    } else {
                        element.selected = 0
                        element.quantity = 1
                    }
                }
            }
            
            let l_bordas = [...this.state.bordas]
            for (let index = 0; index < l_bordas.length; index++) {
                const element = l_bordas[index];
                
                if (element.id === 0){
                    element.selected = 1
                } else {
                    element.selected = 0
                    element.quantity = 1
                }
            }
            this.setState({ 
                valor: getAdditionalValueComposition(lista, true, 1),
                bordas: l_bordas,
                items: lista
            })

        } else {            
            let qtdeBordas    = 0;
            let indexBorda    = 0;
            let qtdeAcumulada = 0;
            
            let lista = [...this.state.items]
            for (let index = 0; index < lista.length; index++) {
                const element = lista[index];
    
                if (element.component){
                    if (element.id === 0){
                        element.selected = 0
                    } else if (element.id === borda.id) {
                        if (borda.selected === 1){
                            element.selected = 0
                            element.quantity = 1
                        } else {
                            element.selected = 1
                            qtdeBordas += 1
                        }
                    } else if (element.selected > 0){
                        qtdeBordas += 1
                    }
                }
            }

            if (qtdeBordas > 0){
                for (let index = 0; index < lista.length; index++) {
                    const element = lista[index];
                    
                    if (element.component && (element.component.id > 0)){
                        if ((element.component.type === 6) && (element.selected) && (element.selected > 0)){
                            element.quantity = parseFloat((1 / qtdeBordas).toFixed(3)) 
                            
                            qtdeAcumulada += element.quantity
                            indexBorda += 1
    
                            if (indexBorda === qtdeBordas){
                                if (qtdeAcumulada !== 1){
                                    element.quantity = parseFloat((element.quantity + 1 - qtdeAcumulada).toFixed(3))
                                }
                            }
                        }
                    }              
                }      
            } 
            
            let bordas = [...this.state.bordas]
            for (let index = 0; index < bordas.length; index++) {
                const element = bordas[index];
                
                if (element.id === 0){
                    element.selected = qtdeBordas > 0 ? 0 : 1
                } else if (element.id === borda.id) {
                    if (borda.selected === 1){
                        element.selected = 0
                    } else {
                        element.selected = 1
                    }
                }
            }
            this.setState({ 
                valor: getAdditionalValueComposition(lista, true, 1),
                bordas: bordas,
                items: lista
            })
        }
    }

    onConfirmar = () => {
        if (this.state.stage === 1){
            this.setState({ stage: 2 })
        } else {             
            if (this.props.confirm){

                const pizzaJSON = JSON.stringify(this.props.pizza);
                let item = JSON.parse(pizzaJSON);
                item.structure = [];
                item.structure = [...this.state.items];

                let additionalValue = 0;
                if (item.structure && (item.structure.length > 0)){
                    additionalValue = getAdditionalValueComposition(item.structure, item.type === 3, 1);
                }

                let structure = undefined;
                if (item.structure && (item.structure.length > 0)){
                    structure = item.structure;
                }

                let printerId = 0;
                if (item.printerId && (item.printerId > 0)){
                    printerId = item.printerId;
                }

                let note = '';
                if (this.state.pizza && this.state.pizza.note && (this.state.pizza !== '')){
                    note = this.state.pizza.note;
                }

                let nControle = global.order.items.length;
                for (let index = 0; index < global.order.items.length; index++) {
                    if (global.order.items[index].controle && (global.order.items[index].controle > 0)){
                        if (global.order.items[index].controle > nControle){
                            let nControle = global.order.items[index].controle;  
                        }
                    }            
                }
                nControle += 1;
                global.order.items.push({
                    controle: nControle,
                    id: item.id, 
                    group: item.groupId,
                    name: item.name,
                    unitPrice: item.unitPrice, 
                    manufactured: item.manufactured, 
                    manufacturedAntecipated: item.manufacturedAntecipated,
                    timeControl: item.timeControl,
                    serviceProvision: item.serviceProvision, 
                    component: null,
                    additionalValue: additionalValue,
                    salesgroup: item.salesgroup, 
                    amount: 1, 
                    type: item.type,
                    sheet: item.sheet,
                    stock: item.stock,
                    typeControlStock: item.typeControlStock,
                    typeProductionControlStock: 0,
                    typeProduction: 0,
                    structure: structure, 
                    printerId: printerId,
                    note: note              
                });

                this.props.onConfirmPressed();

            } else {
                const pizzaJSON = JSON.stringify(this.props.pizza);
                let pizza = JSON.parse(pizzaJSON);
                pizza.structure = [];
                pizza.structure = [...this.state.items];
                this.props.onConfirmPressed(pizza);
            }           
            
        }
    }

    renderSabores = (item) => { 
        if (item.id === 0){ 
            return (
                <p className='product-detail-title' style={{marginLeft: 0, marginTop: 20}}>
                    {item.name}
                </p>  
            )            
        }  
        
        return ( this.renderSabor(item) );
    }

    onChangeNote = (text) => {
        let pizza = this.state.pizza;
        pizza.note = text;
        this.setState({ pizza: pizza });
    }

    renderSabor = (item) => {
        let widthAction = 0;
        let widthName = '100%';
        if (this.props.permitefazerpedido){
            if (item.selected && item.selected > 0){
                if (item.value && (item.value > 0)){
                    widthName = 'calc(100% - 185px)';
                    widthAction = 185;
                } else {
                    widthName = 'calc(100% - 105px)';
                    widthAction = 105;
                } 
            } else {
                if (item.value && (item.value > 0)){
                    widthName = 'calc(100% - 125px)';
                    widthAction = 125;
                } else {
                    widthName = 'calc(100% - 45px)';
                    widthAction = 45;
                } 
            }
        } else {
            if (item.value && (item.value > 0)){
                widthName = 'calc(100% - 90px)';
                widthAction = 90;
            } 
        }

        return (
            <div className='product-sabor-pizza' style={{display: 'flex', alignItems: 'center'}}>
                <div style={{ width: widthName, flexDirection: 'row' }}>
                    <p className='pizza-aditional-component' style={{width: '100%'}}>{this.getName(item.component.name)}</p>           
                    {
                        item.component.detail && item.component.detail !== '' ?
                        <p className='pizza-aditional-component' style={{ marginBottom: 10, fontSize: 11, width: '100%' }}>{item.component.detail}</p> : <Fragment />
                    }
                </div>

                <div style={{float: 'right', width: widthAction, right: 0, height: 45, top: 0}}>
                {
                    this.props.permitefazerpedido ?
                    <Fragment>                            
                        {
                            this.state.sabores === this.props.pizza.quantityFlavor ? 
                            <div className='product-action'>
                                <AddIcon color="disabled"/>
                            </div> : 
                            <div className='product-action' onClick={() => {this.onPressAdd(item)}}>
                                <AddIcon color="primary"/>
                            </div>
                        }
                        {
                            item.selected && 
                            item.selected > 0 ?
                            <Fragment>                                    
                                <div className='product-selected'>
                                    <p className='product-text-selected'>{item.selected}</p>
                                </div>
                                <div className='product-action' onClick={() => {this.onPressRemove(item)}}>
                                    <RemoveIcon color="error"/>
                                </div>
                            </Fragment> : <Fragment />
                        }
                    </Fragment> : <Fragment />
                }
                {
                    item.value && item.value > 0 ?
                    <div className='product-detail-value'>
                        <span className='product-label-value'>+ R$ {item.value.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}</span>
                    </div> : <Fragment />
                }  
                </div>                      
            </div>
        )
    }

    renderBordas = () => {
        return (
            <Fragment>
                {
                    this.state.bordas.map((borda, index) => {
                        return (
                            <Fragment key={index}>
                                {
                                    this.props.permitefazerpedido ? 
                                    <div className='product-detail-aditional' onClick={() => {this.onPressBorda(borda)}}> 
                                        {                                                    
                                            borda.selected > 0 ?
                                            <Checkbox checked sx={{ float: 'left' }} /> : 
                                            <Checkbox checked={false} sx={{ float: 'left' }}/>
                                        }
                                        <p className='product-aditional-component' style={{width: 'calc(100% - 150px)'}}>{this.getName(borda.component.name)}</p>
                                        {
                                            borda.value && borda.value > 0 ?
                                            <div className='product-detail-value'>
                                                <span className='product-label-value'>+ R$ {borda.value.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}</span>
                                            </div> : <Fragment />
                                        } 
                                    </div> : 
                                    <Fragment>
                                        {
                                            borda.id > 0 ?
                                            <div className='product-detail-aditional'>
                                                <p className='product-aditional-component' style={{width: 'calc(100% - 120px)'}}>{this.getName(borda.component.name)}</p>
                                                {
                                                    borda.value && borda.value > 0 ?
                                                    <div className='product-detail-value'>
                                                        <span className='product-label-value'>+ R$ {borda.value.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}</span>
                                                    </div> : <Fragment />
                                                } 
                                            </div> : <Fragment />
                                        }
                                    </Fragment>
                                }
                            </Fragment>
                        )
                    })
                }
            </Fragment>
        )
    }

    render() {
        return ( 
            <Modal
                open={this.props.show}
                disableAutoFocus={true}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                {
                    this.props.pizza ? 
                    <div className='product-detail' style={{maxHeight: this.state.clientHeight}}>
                        <div className='product-detail-close' onClick={() => { this.props.onCancelPressed() }}><CloseIcon/></div>                                                   
                        
                        <div className='product-detail-header' style={{marginBottom: 0}}>
                            {
                                this.props.pizza.image && 
                                this.props.pizza.image !== '' ? 
                                <div className='product-detail-image' style={{backgroundImage: 'url(data:image/png;base64,'+this.props.pizza.image+')'}}/> :
                                <div className='product-detail-image-notfound'/>
                            }               
                            <h5 className='product-detail-name'>{this.props.pizza.name}</h5>
                            {
                                this.props.pizza.unitPrice > 0 ||
                                this.state.valor > 0 ?                                
                                <Fragment>
                                    {
                                        this.props.pizza.unitPrice > 0 ? 
                                        <h1 className='product-value'>
                                            {this.props.pizza.unitPrice.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}
                                            {
                                                this.state.valor && this.state.valor > 0 ? 
                                                <span className='product-value-aditional'> + {this.state.valor.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}</span> : <Fragment />
                                            }
                                        </h1> : 
                                        <h1 className='product-value'>
                                            <span className='product-value-aditional'> + {this.state.valor.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}</span> 
                                        </h1>
                                    }
                                </Fragment> : <Fragment />
                            }
                        </div>
                        
                        {
                            this.state.stage === 1 ?
                            <div className='combo-stage'>
                                <p className='combo-label-stage'>Selecione ate</p> 
                                <p className='combo-item-stage'>{this.props.pizza.quantityFlavor}</p>

                                {
                                    this.props.permitefazerpedido ? 
                                    <Fragment>
                                        <p className='combo-label-stage'>{`  sabores    (`}</p>                                                
                                        <p className='combo-item-stage' style={{paddingLeft: 5}}>{this.state.sabores}</p> 
                                        {
                                            this.state.sabores && 
                                            this.state.sabores === 1 ?
                                            <p className='combo-label-stage'>{` selecionado  )`}</p> : 
                                            <p className='combo-label-stage'>{` selecionados  )`}</p> 
                                        }
                                    </Fragment> : 
                                    <p className='combo-label-stage'>sabores</p>
                                }
                            </div> :
                            <div className='combo-stage'>
                                <p className='combo-label-stage'>Selecione a borda</p> 
                            </div>
                        }

                        

                        <div className='product-detail-container' style={{
                            maxHeight: this.props.permitefazerpedido ? this.state.clientHeight - 365 : this.state.clientHeight - 265
                        }}> 
                            {
                                this.state.stage === 1 ? 
                                <Fragment>
                                    {
                                        this.state.listasabores.map(item => (
                                            <Fragment key={item.id}>
                                                { this.renderSabores(item) }
                                            </Fragment>                                                    
                                        ))   
                                    }
                                    {
                                        this.props.permitefazerpedido ? <Fragment /> :
                                        <Fragment>
                                            <p className='product-detail-title' style={{marginLeft: 0, marginTop: 20}}>Bordas</p> 
                                            { this.renderBordas() }
                                        </Fragment>
                                    }
                                </Fragment> : 
                                <div style={{marginTop: 20, paddingBottom: 10}}>
                                    { this.renderBordas() }
                                </div>
                            }

                            {
                                this.state.pizza ?
                                <TextField
                                    placeholder="Observação"
                                    id="textarea-obs"
                                    margin="normal" 
                                    color="warning"
                                    fullWidth 
                                    multiline
                                    rows={6} 
                                    value={this.state.pizza.note}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        this.onChangeNote(event.target.value);
                                    }}/> : <Fragment />
                            }
                                                   
                        </div>

                        {
                            this.props.permitefazerpedido ?
                            <div className='product-footer'>
                                {
                                    this.state.stage === 1 ?
                                    <Fragment>
                                        {
                                            this.state.sabores === 0 ? 
                                            <Button className='product-confirm-disable' disabled={true}>
                                                <NavigateNextIcon sx={{ marginLeft: 1 }}/>
                                                <p className='confirme-label' style={{marginRight: 15}}>Avançar</p>
                                            </Button> : 
                                            <Button className='product-confirm' onClick={() => {this.onConfirmar()}}>
                                                <NavigateNextIcon sx={{ marginLeft: 1 }}/>
                                                <p className='confirme-label' style={{marginRight: 15}}>Avançar</p>
                                            </Button>
                                        }
                                    </Fragment> :
                                    <Button className='product-confirm' onClick={() => {this.onConfirmar()}}>
                                        <CheckIcon checked sx={{ marginLeft: 2 }} />
                                        <p className='confirme-label' style={{marginRight: 15}}>Adicionar pizza</p>
                                    </Button>
                                }
                            </div> : <Fragment />
                        }

                    </div> : <Fragment />
                }
                
            </Modal>  
        )
    }
}

export default Pizza;
