import React, { Fragment } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css'; 

import { GET, validateERROR } from '../commons/Service';
import { Modal } from '@mui/material';

class Info extends React.Component {

    constructor(props) {
        super();
        this.state = {
            clientHeight: parseInt(window.innerHeight * 0.95) - 65, 
            paymentmethods: [],
            consultando: true,
            deliveryfee: 0,
            districts: [],
            counties: [],
            address: [],
            flags: []
        }
    }

    componentDidMount() {
        window.addEventListener('resize', this.handleResizedScreen);
        
        GET('validate/', 20000).then((response) => { 
            let deliveryfee = 0;
            if (response.deliveryfee && (response.deliveryfee > 0)){
                deliveryfee = response.deliveryfee;
            }

            let address = [];
            if ( (response.counties && response.counties.length > 0) && 
                 (response.districts && response.districts.length > 0) ){
                for (let index = 0; index < response.counties.length; index++) {
                    const element = response.counties[index];
                    
                    let bairros = response.districts.filter(district => { return district.county === element.id })
                    if (bairros && (bairros.length > 0)){
                        address.push({county: element, districts: bairros})
                    }                    
                }
            }

            this.setState({ 
                address: address,
                deliveryfee: deliveryfee,
                counties: response.counties,
                districts: response.districts
            })

            GET('finance/releasetype/', 20000).then((res) => {                         
                
                this.setState({ 
                    paymentmethods: res.paymentmethods, 
                    moneytype: res.moneytype, 
                    consultando: false,
                    flags: res.flags
                }) 

            }).catch((error) => {
                let message = '';
                let erro = validateERROR(error);
                if ((erro) && (erro.message) && (erro.message !== '')){ 
                    message = erro.message;
                } else {
                    message = 'Falha ao consultar informações de pagamento';
                }

                this.setState({ consultando: false }, () => {
                    confirmAlert({
                        title: 'Erro ao consultar formas de pagamentos!',
                        message: message,
                        buttons: [{ label: 'Ok' }],
                        closeOnEscape: false,
                        closeOnClickOutside: false,
                        overlayClassName: "class-alert-livn"
                    }) 
                })
            })

        }).catch((error) => {
            let message = '';
            let erro = validateERROR(error);
            if ((erro) && (erro.message) && (erro.message !== '')){ 
                message = erro.message;
            } else {
                message = 'Falha ao consultar informações';
            }

            this.setState({ consultando: false }, () => {
                confirmAlert({
                    title: 'Erro ao consultar dados!',
                    message: message,
                    buttons: [{ label: 'Ok' }],
                    closeOnEscape: false,
                    closeOnClickOutside: false,
                    overlayClassName: "class-alert-livn"
                }) 
            })
        })

    }

    handleResizedScreen = () => {   
        this.setState({ clientHeight: parseInt(window.innerHeight * 0.95) - 65 });   
    };
      
    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResizedScreen);
    }

    onValidaFeeAdress = (district) => {
        let deliveryFee = 0;                
        if (district && (district.id > 0)){
            if (district.idfee && (district.idfee > 0)){
                deliveryFee = district.fee;
            } else {
                deliveryFee = this.state.deliveryfee;
            }
        }

        let feeFormat = '';
        if (deliveryFee === 0){
            feeFormat = 'GRÁTIS'
        } else {
            feeFormat = `R$ ${deliveryFee.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}`;
        }
        return feeFormat;
    }

    getWeekdayName = (diasemana) => {
        let WeekdayName = '';
        switch(diasemana) {      
            case 1: WeekdayName = 'DOMINGO'; break;           
            case 2: WeekdayName = 'SEGUNDA'; break;
            case 3: WeekdayName = 'TERÇA'; break;
            case 4: WeekdayName = 'QUARTA'; break;
            case 5: WeekdayName = 'QUINTA'; break;
            case 6: WeekdayName = 'SEXTA'; break;
            case 7: WeekdayName = 'SÁBADO'; break;
        }
        return WeekdayName;
    }

    leftPad = (value, totalWidth, paddingChar) => {
        let length = totalWidth - value.toString().length + 1;
        return Array(length).join(paddingChar || '0') + value;
    }

    getWeekdateFormat = (value) => {
        let dateFormat = '00:00';
        if (value && value !== ''){            
            let hour   = parseInt(value.substring(1, 3));
            let minute = parseInt(value.substring(4, 6));            
            dateFormat = `${this.leftPad(hour, 2, '0')}:${this.leftPad(minute, 2, '0')}`;
        }
        return dateFormat;
    }

    render() {
        return ( 
            <Modal
                open={this.props.show}
                disableAutoFocus={true}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <div className='modal-info-detail'>                    

                    <div className='pedido-detail-header' style={{marginBottom: 0}}>
                        <h2 className='modal-info-title'>Informações</h2>
                        <div className='modal-info-close' onClick={() => { this.props.onClose() }}><CloseIcon/></div>
                    </div>

                    <div className='div-info-container' style={{maxHeight: this.state.clientHeight}}>

                    {
                        this.props.weekday && 
                        this.props.weekday.length > 0 ?
                        <div className='div-info-group'>
                            <p className='label-order-product'>Horário de Funcionamento</p>

                            <div style={{paddingBottom: 10, paddingTop: 5, borderBottomWidth: 1, borderBottomStyle: 'dotted', borderBottomColor: '#bdb7b7'}}>
                            {             
                                this.props.weekday.map((weekday, index)  => (
                                    <Fragment key={index}>
                                        {
                                            weekday.available === 'S' ? 
                                            <div className='div-weekday-info'>
                                                <p className='div-weekdayname-info'>{this.getWeekdayName(weekday.weekDayId)}</p>
                                                <div className='div-weekday-time'>
                                                    <p className='div-weekday-lebeltime'>{this.getWeekdateFormat(weekday.start)}</p>
                                                    <p className='div-weekday-lebeltime'>-</p>
                                                    <p className='div-weekday-lebeltime'>{this.getWeekdateFormat(weekday.stop)}</p>
                                                </div>
                                            </div> : <Fragment />
                                        }
                                    </Fragment>
                                ))
                            }
                            </div>
                        </div> : <Fragment />
                    }

                    {
                        this.state.paymentmethods && 
                        this.state.paymentmethods.length > 0 ?
                        <div className='div-info-group'>
                            <p className='label-order-product'>Formas de Pagamento</p>

                            <div>
                            {             
                                this.state.paymentmethods.map((payment, index)  => (
                                    <div key={index} className='div-payment-info'>
                                        <p className='div-name-info'>{payment.name}</p>
                                        {
                                            this.state.flags && 
                                            this.state.flags.length > 0 && 
                                            payment.debit.type === 8 && 
                                            payment.requireflag === 'S' ? 
                                            <div>
                                            {
                                                this.state.flags.map((flag, index)  => (
                                                    <div key={index} className='div-band-info'>
                                                        {
                                                            flag.image !== '' ?
                                                            <img className='div-flag-image-info' src={`data:image/png;base64,${flag.image}`}/> :
                                                            <div className='div-flag-image-info' />
                                                        }
                                                        <p className='div-band-name-info'>{flag.name}</p>
                                                    </div>
                                                ))
                                            }
                                            </div> : <Fragment />
                                        }
                                    </div>                                            
                                ))                                        
                            }
                            </div>
                        </div> : <Fragment />
                    }

                    {
                        this.state.address && 
                        this.state.address.length > 0 ?
                        <div className='div-info-group'>
                            <p className='label-order-product'>Bairros para Entrega</p>
                            {
                                this.state.address.map((address, index)  => (
                                    <div key={index} className='div-payment-info'>
                                        <p className='div-name-info' style={{fontWeight: 700}}>{address.county.name}</p>
                                        <div>
                                            {
                                                address.districts.map((district, index)  => (
                                                    <div key={index} className='div-band-info' style={{paddingLeft: 0, position: 'relative'}}>
                                                        <p className='div-band-name-info'>{district.name}</p>
                                                        <p className='div-fee-info'>{this.onValidaFeeAdress(district)}</p>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                ))
                            }
                        </div> : <Fragment />
                    }

                    {
                        this.state.consultando ?
                        <div className='div-progress-info'>
                            <CircularProgress size={15} sx={{'& .MuiLinearProgress-bar': {backgroundColor: '#3f51b5'}}} />
                            <p className='label-progress-status'>Consultando informações</p>
                        </div> : <Fragment />
                    }
                    </div>

                </div>
            </Modal> 
        )
    }
}

export default Info;
