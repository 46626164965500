import React, { Fragment } from 'react';

import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { Button, Modal, TextField } from '@mui/material';
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css'; 

class PagamentoDetalhe extends React.Component {

    constructor(props) {
        super();
        this.state = {
            clientHeight: window.innerHeight - 220,
            operationType: '',
            cardtypes: [],
            operators: [],
            flagsType: [],
            payment: null,
            moneytype: 0,
            idFlag: 0,
            stage: 0,
            flags: [], 
            valor: ''
        }
    }

    componentDidMount() {
        window.addEventListener('resize', this.handleResizedScreen); 
        
        let stage = 1;
        let cardtypes = [];
        let operationType = '';
        if (this.state.moneytype === this.props.payment.id){
            
        } else {
            if (this.props.payment.cardtype && (this.props.payment.cardtype !== '')){
                if (this.props.payment.cardtype === 'C'){
                    operationType = 'C';
                    stage = 2;
                } else if (this.props.payment.cardtype === 'D'){
                    operationType = 'D';
                    stage = 2;
                } else {
                    if (this.props.payment.cardtype === 'P'){
                        cardtypes.push({type: 'C', selected: false, name: 'CARTÃO DE CRÉDITO'});
                        cardtypes.push({type: 'D', selected: false, name: 'CARTÃO DE DÉBITO'});
                    } else {
                        if (this.props.payment.cardtype.includes("C")){
                            cardtypes.push({type: 'C', selected: false, name: 'CARTÃO DE CRÉDITO'});
                        }
                        if (this.props.payment.cardtype.includes("D")){
                            cardtypes.push({type: 'D', selected: false, name: 'CARTÃO DE DÉBITO'});
                        }
                        if (this.props.payment.cardtype.includes("V")){
                            cardtypes.push({type: 'V', selected: false, name: 'VOUCHER'});
                        }
                        if (this.props.payment.cardtype.includes("F")){
                            cardtypes.push({type: 'F', selected: false, name: 'FROTA'});
                        }
                    }
                }
            } else {
                cardtypes.push({type: 'C', selected: false, name: 'CARTÃO DE CRÉDITO'});
                cardtypes.push({type: 'D', selected: false, name: 'CARTÃO DE DÉBITO'});
            }

            if (cardtypes.length === 1){
                operationType = cardtypes[0].type;
                stage = 2;
            }
        } 
        
        let flags = [];
        for (let index = 0; index < this.props.flags.length; index++) {
            const element = this.props.flags[index];
            element.selected = false;
            flags.push(element);
        }

        this.setState({
            operators: this.props.operators,
            moneytype: this.props.moneytype,
            operationType: operationType,
            payment: this.props.payment, 
            cardtypes: cardtypes,
            flags: flags, 
            stage: stage
        }, () => {
            if (this.state.stage === 2){
                this.onValidaFlag(); 
            }
        })
    }

    handleResizedScreen = () => {        
        this.setState({ clientHeight: window.innerHeight - 220 });
    };
      
    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResizedScreen);
    }

    getName = (texto) => {
        let nameFormat = '';
        if (texto && (texto !== '')){
            const arrayText = texto.split(' ')
            if (arrayText && (arrayText.length > 0)){
                for (let i = 0; i < arrayText.length; i++) {    
                    let name = arrayText[i].substring(0, 1);
                    name += arrayText[i].substring(1, arrayText[i].length).toLowerCase();
                    nameFormat += ' ' + name;
                }
            }
        }
        return nameFormat;    
    }

    onValidaFlag = () => {
        /* Se possui bandeira configurada no tipo de lançamento */
        if (this.state.payment.flag > 0){
            this.props.onConfirme(
                this.props.valorPedido, 
                this.state.operationType, 
                this.onGetDetailCard(this.state.payment.flag),
                this.state.payment.flag, 
                true
            )

        } else { 
            /* Se pergunta a Bandeira */               
            if (this.state.payment.requireflag === 'S'){
                let flags = [];
                /* Valida as bandeiras validas para o tipo de cartão selecionado */
                for (let index = 0; index < this.state.flags.length; index++) {
                    if (this.state.flags[index].operation === 'T'){                         
                        let flagJOSN = JSON.stringify(this.state.flags[index]);
                        const element = JSON.parse(flagJOSN);
                        element.selected = false;
                        flags.push(element);

                    } else if (this.state.flags[index].operation.includes(this.state.operationType)) {                        
                        let flagJOSN = JSON.stringify(this.state.flags[index]);
                        const element = JSON.parse(flagJOSN);
                        element.selected = false;
                        flags.push(element);
                    } 
                }  

                if (flags.length === 0){
                    confirmAlert({
                        title: 'Atenção!',
                        message: 'Não existe nenhuma bandeira disponível para operação',
                        buttons: [{ label: 'Ok' }],
                        closeOnEscape: false,
                        closeOnClickOutside: false,
                        overlayClassName: "class-alert-livn"
                    })
                    
                } else if (flags.length === 1){
                    this.props.onConfirme(
                        this.props.valorPedido, 
                        this.state.operationType,
                        this.onGetDetailCard(flags[0].id), 
                        flags[0].id, 
                        true
                    )

                } else {
                    this.setState({ stage: 2, flagsType: flags })
                }
            } else {
                this.props.onConfirme(
                    this.props.valorPedido, 
                    this.state.operationType, 
                    this.onGetDetailCard(0), 
                    0, 
                    true
                )
            }
        }
    }

    onChangeValor = (text) => {
        this.setState({ valor: text });
    }

    onValorSemTroco = () => {
        this.props.onConfirme(
            this.props.valorPedido, 
            '', 
            'não preciso de troco', 
            0, 
            true
        )
    }

    onConfirmar = () => {  
        if (this.state.moneytype === this.props.payment.id){            
            let valorInformado = 0;
            try {
                if (typeof this.state.valor === "string"){
                    if (this.state.valor.trim().length > 0){
                        valorInformado = parseFloat(this.state.valor);
                    }
                } else if (typeof this.state.valor === "number"){
                    valorInformado = this.state.valor;
                }
            } catch (error) {}
            
            if (valorInformado === 0){
                confirmAlert({
                    title: 'Atenção!',
                    message: 'Valor a pagar não informado',
                    buttons: [{ label: 'Ok' }],
                    closeOnEscape: false,
                    closeOnClickOutside: false,
                    overlayClassName: "class-alert-livn"
                })
            } else if (valorInformado < this.props.valorPedido){
                confirmAlert({
                    title: 'Atenção!',
                    message: 'Valor a pagar deve ser maior que o valor do pedido',
                    buttons: [{ label: 'Ok' }],
                    closeOnEscape: false,
                    closeOnClickOutside: false,
                    overlayClassName: "class-alert-livn"
                })
            } else {
                this.props.onConfirme(
                    valorInformado, 
                    '', 
                    `troco para R$ ${valorInformado.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}`, 
                    0, 
                    false);
            }
        } else {
            if ((this.state.cardtypes.length > 1) && (this.state.stage === 1)){
                if (this.state.operationType === ''){
                    confirmAlert({
                        title: 'Atenção!',
                        message: 'Necessário selecionar o tipo do cartão',
                        buttons: [{ label: 'Ok' }],
                        closeOnEscape: false,
                        closeOnClickOutside: false,
                        overlayClassName: "class-alert-livn"
                    })
                } else {
                    this.onValidaFlag()
                }

            } else if (this.state.stage === 2) {
                if (this.state.idFlag === 0){
                    confirmAlert({
                        title: 'Atenção!',
                        message: 'Necessário selecionar a bandeira do seu cartão',
                        buttons: [{ label: 'Ok' }],
                        closeOnEscape: false,
                        closeOnClickOutside: false,
                        overlayClassName: "class-alert-livn"
                    })
                } else {
                    this.props.onConfirme(
                        this.props.valorPedido, 
                        this.state.operationType, 
                        this.onGetDetailCard(this.state.idFlag),
                        this.state.idFlag, 
                        true
                    )
                }
            } else {
                this.props.onClose()
            }
        }     
    }

    onGetDetailCard = (idFlag) => {
        let paymentDetail = '';
        if (this.state.operationType === "C"){
            paymentDetail = 'Crédito'
        } else if (this.state.operationType === "D"){
            paymentDetail = 'Débito'
        } else if (this.state.operationType === "V"){
            paymentDetail = 'Voucher'
        } else if (this.state.operationType === "F"){
            paymentDetail = 'Frota'
        }

        if (idFlag > 0){
            let flag = this.state.flags.find(({ id }) => id === idFlag);                 
            if (flag && (flag.id > 0)){
                paymentDetail = `${paymentDetail}  |  ${this.getName(flag.name)}`
            }
        }
        return paymentDetail;
    }

    onSelectCardType = (type) => {
        let list =  [...this.state.cardtypes];
        for (let index = 0; index < list.length; index++) {
            list[index].selected = list[index].type === type.type;
        }
        this.setState({ cardtypes: list, operationType: type.type }) 
    }

    onSelectFlag = (flag) => {
        let list =  [...this.state.flagsType];
        for (let index = 0; index < list.length; index++) {
            list[index].selected = list[index].id === flag.id;
        }
        this.setState({ flagsType: list, idFlag: flag.id })
    }

    render() {
        return ( 
            <Modal
                open={this.props.show}
                disableAutoFocus={true}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <div>
                <div className='pagamento-detail'>
                    <div className='pagamento-detail-header' style={{marginBottom: 0}}>
                        {
                            this.state.moneytype === this.props.payment.id ?
                            <h2 className='pagamento-title'>vai precisar de troco?</h2> : 
                            <Fragment>
                                {
                                    this.state.cardtypes.length > 1 && 
                                    this.state.stage === 1 ?
                                    <h2 className='pagamento-title'>selecione o tipo de cartão</h2> : 
                                    <h2 className='pagamento-title'>informe a bandeira de seu cartão</h2>
                                }
                            </Fragment>
                        }
                    </div>
                    
                    <div className='pedido-detail-container' style={{maxHeight: this.state.clientHeight}}> 
                        <div className='div-payment'>

                        {
                            this.state.moneytype === this.props.payment.id ?
                            <Fragment>
                                <div className='pagamento-div-subtitle'>
                                    <h2 className='pagamento-subtitle'>digite o quanto vai pagar, para calcularmos o troco e enviar com seu pedido</h2>
                                    <div className='pagamento-valor-div'>
                                        <h2 className='pagamento-subtitle'>valor do pedido:</h2>
                                        <h2 className='pagamento-valor'>
                                            {this.props.valorPedido.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}
                                        </h2>
                                    </div>
                                </div>
                                <TextField
                                    inputProps = {{ sx: {'&::placeholder': { 
                                        color: '#cfcfcf',  
                                        fontSize: '14px'
                                    }} }}
                                    placeholder="Digite o valor que vai pagar"
                                    id="textarea-reference"
                                    type='number'
                                    margin="normal" 
                                    color="warning"
                                    fullWidth 
                                    value={this.state.valor}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        this.onChangeValor(event.target.value);
                                    }} />
                            </Fragment> :
                            <Fragment>
                                {
                                    this.state.cardtypes.length > 1 && 
                                    this.state.stage === 1 ?
                                    <div>
                                    { 
                                        this.state.cardtypes.map((type, index) => {
                                            return ( 
                                                <div key={index} className='card-card-type' onClick={() => { this.onSelectCardType(type) }}>
                                                    {
                                                        type.selected ?
                                                        <RadioButtonCheckedIcon sx={{ color: '#5046BB', fontSize: 20 }}/> : 
                                                        <RadioButtonUncheckedIcon sx={{ color: '#5046BB', fontSize: 20 }}/>
                                                    }
                                                    <div>
                                                        <p className='name-payment'>{this.getName(type.name)}</p>
                                                    </div>
                                                </div>    
                                            )
                                        }) 
                                    }
                                    </div> : 
                                    <div>
                                    { 
                                        this.state.flagsType.map((flag, index) => {
                                            return ( 
                                                <div key={index} className='card-flag-type' onClick={() => { this.onSelectFlag(flag) }}>
                                                    {
                                                        flag.selected ?
                                                        <RadioButtonCheckedIcon sx={{ color: '#5046BB', fontSize: 20 }}/> : 
                                                        <RadioButtonUncheckedIcon sx={{ color: '#5046BB', fontSize: 20 }}/>
                                                    }
                                                    <div className='div-flag-image'>
                                                    {
                                                        flag.image !== '' ?
                                                        <img className='flag-image' src={`data:image/png;base64,${flag.image}`}/> :
                                                        <div className='flag-image' />
                                                    }
                                                        <p className='name-payment'>{this.getName(flag.name)}</p>
                                                    </div>
                                                </div>    
                                            )
                                        }) 
                                    }
                                    </div>
                                }
                            </Fragment>
                        }
                        </div>
                    </div>           

                    <div className='pagamento-footer'>
                        <Button className='cliente-confirm' onClick={() => {this.onConfirmar()}}>
                            <p className='confirme-label'>Confirmar</p>
                        </Button>
                        {
                            this.state.moneytype === this.props.payment.id ?                            
                            <div style={{cursor: 'pointer'}} onClick={() => { this.onValorSemTroco() }}>
                                <p className='pagamento-default'>não preciso de troco</p>
                            </div> : 
                            <div style={{cursor: 'pointer'}} onClick={() => { this.props.onClose() }}>
                                <p className='pagamento-default'>voltar</p>
                            </div>
                        }
                    </div>
                </div>
                </div>
            </Modal> 
        )
    }
}

export default PagamentoDetalhe;
