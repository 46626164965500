import React from 'react';
import { global } from '../commons/Globals';

export const setStoreData = (key, value) => {
    localStorage.setItem(key, value);
}

const getHeader = () => {
    const headers = {
        'Content-Type': 'application/json;charset/UTF-8', 
        'Access-Control-Allow-Origin': '*'
    };

    if ((global.Store) && (global.Store !== '')){
        headers['store'] = global.Store;
    }

    if ((global.deviceId) && (global.deviceId !== '')){
        headers['deviceId'] = global.deviceId;
    }

    if ((global.authorization) && (global.authorization !== '')){
        headers['Authorization'] = 'Bearer ' + global.authorization;           
    } 
    
    if ((global.token) && (global.token !== '')){
        headers['Token'] = '' + global.token;
    } 

    return headers;
}

const IFetch = (url, options, timeout) => {    
    const headers = getHeader();
    return fetch(url, {
        timeout: timeout,
        headers,
        ...options
    })
    .then((response) => response.json())
    .catch(function(error) {
        if (error.message.toUpperCase() === ('network request failed').toUpperCase()){
            throw Error(`ERRO: Falha ao conectar com o servidor!`)
        } else if (error.message.toUpperCase() === ('Failed to fetch').toUpperCase()){
            throw Error(`ERRO: Falha ao conectar com o servidor!`)
        } else {
            throw Error(`ERRO: ${error.message}`)
        }
    })
}

export const POST = (url, obj, timeout) => {
    return IFetch(global.URL + url, {method: 'POST', body: JSON.stringify(obj)}, timeout)
        .then(response => { 
            if ((response.error) && (response.error !== '')){
                if (response.error.toUpperCase() === ('network request failed').toUpperCase()){
                    throw Error(`ERRO: Falha ao conectar com o servidor!`)
                } else {
                    throw Error(`ERRO: ${response.error}`)
                }
            } else if ((response.status) && (response.status !== 200)){
                if ((response.detail) && (response.detail !== '')){
                    if (response.status === 500){
                        throw Error(`ERRO: ${response.detail}`)
                    } else {
                        throw Error(JSON.stringify(response))
                    }
                } else {
                    throw Error(`ERRO: ${response.status}`)
                }
            } else {
                return Promise.resolve(response);
            }
        })
}

export const GET = (url, timeout) => {
    return IFetch(global.URL + url, {method: 'GET'}, timeout)
        .then(response => { 
            if ((response.error) && (response.error !== '')){
                if (response.error.toUpperCase() === ('network request failed').toUpperCase()){
                    throw Error(`ERRO: Falha ao conectar com o servidor!`)
                } else {
                    throw Error(`ERRO: ${response.error}`)
                }
            } else if ((response.status) && (response.status !== 200)){
                if ((response.detail) && (response.detail !== '')){
                    if (response.status === 500){
                        throw Error(`ERRO: ${response.detail}`)
                    } else {
                        throw Error(JSON.stringify(response))
                    }
                } else {
                    throw Error(`ERRO: ${response.status}`)
                }
            } else {
                return Promise.resolve(response);
            }
        })
}

export const validateERROR = (error) => {
    if ((typeof error === "object") && (error.message) && (error.message !== '')){
        let obj = null
        try {
            obj = JSON.parse(error.message)
        } catch (ex) {}
        
        if (obj){
            let status = 0
            let message = ''

            if (obj){
                if ((obj.status) && (obj.status > 0)){
                    status = obj.status      
                }
    
                if ((obj.detail) && (obj.detail !== '')){
                    message = obj.detail    
                } else if ((obj.message) && (obj.message !== '')){
                    message = obj.message    
                }    

                return {message: message, status: status}
            } else {
                return {message: error, status: 0}
            }
        } else if ((error.detail) && (error.detail !== '')){
            return {message: error.detail, status: 0}

        } else if ((error.message) && (error.message !== '')){
            return {message: error.message, status: 0}

        } else {
            return null
        } 

    } else if ((error.detail) && (error.detail !== '')){
        return {message: error.detail, status: 0}

    } else if ((error.message) && (error.message !== '')){
        return {message: error.message, status: 0}

    } else {
        return null
    }  
}
