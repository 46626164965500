import React, { Fragment } from 'react';

import { Button, Modal, TextField } from '@mui/material';
import RemoveIcon from '@mui/icons-material/Remove';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import Checkbox from '@mui/material/Checkbox';
import AddIcon from '@mui/icons-material/Add';
import { global } from '../commons/Globals';

import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css'; 

import {
    getAdditionalValueComposition,
    onConfirmeStructureProduto, 
    compositionValidate,
    compositionEditable, 
    onConfirmeVariable,
    getVariable
} from '../commons/Service.Product';
import Variavel from './Variavel';


class Composicao extends React.Component {
    constructor(props) {
        super();
        this.state = {
            clientHeight: window.innerHeight - 60, 
            showVariable: false,
            productAmount: 1,
            variable: null,
            product: null, 
            valor: 0
        }
    }

    componentDidMount() {
        window.addEventListener('resize', this.handleResizedScreen);
        this.setState({ product: this.props.product });
    }

    handleResizedScreen = () => {        
        this.setState({ clientHeight: window.innerHeight - 60 });
    };
      
    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResizedScreen);
    }

    getName = (texto) => {
        let nameFormat = '';
        if (texto && (texto !== '')){
            nameFormat = texto.charAt(0).toUpperCase() + texto.slice(1).toLowerCase();
        }  
        return nameFormat;       
    }

    onPressItem = (item) => {
        if ((item.default === 'S') && (item.customize === 'S')){
            let product = this.state.product;
            let component = product.structure.find(({ id }) => id === item.id);                 
            if (component && (component.id > 0)){
                if (component.selected === 0){
                    component.selected = 1;
                } else { 
                    component.selected = 0;
                }
            }            
            this.setState({ product: product })
        }
    }

    onPressAdd = (item) => {          
        if ((item.variableComposition) && (item.variable) && (item.variableComposition > 0)){
            this.setState({ 
                variable: getVariable(item.variable),
                showVariable: true
            });

        } else {             
            if ((item.component) && 
                (item.component.manufactured === 'S') && 
                (item.component.manufacturedAntecipated === 'N')) {
                if (compositionEditable(item.component.structure)){
                    /*this.setState({ 
                        product: getComponentManufactured(item.component, item.value, item.id, false), 
                        showStructure: true 
                    })*/
                } else {
                    let product = this.state.product;
                    let component = product.structure.find(({ id }) => id === item.id);                 
                    if (component && (component.id > 0)){
                        component.selected += 1;

                        if (component.component && component.component.structure && (component.component.structure.length > 0)){
                            for (let index = 0; index < component.component.structure.length; index++) {
                                const element = component.component.structure[index];
                                element.selected = component.selected;
                            }
                        }
                    }  
                    this.setState({ 
                        product: product,
                        valor: getAdditionalValueComposition(product.structure, false, 1)
                    })
                }
            } else {
                let product = this.state.product;
                let component = product.structure.find(({ id }) => id === item.id);                 
                if (component && (component.id > 0)){
                    component.selected += 1;
                }    

                this.setState({ 
                    product: product,
                    valor: getAdditionalValueComposition(product.structure, false, 1)
                })
            }            
        }       
    }    

    onPressRemove = (item) => {
        let product = this.state.product;
        let component = product.structure.find(({ id }) => id === item.id);                 
        if (component && (component.id > 0)){
            component.selected -= 1

            if ((component.component) && 
                (component.component.manufactured === 'S') && 
                (component.component.manufacturedAntecipated === 'N') &&
                (!compositionEditable(component.component.structure))) {
                if (component.component.structure && (component.component.structure.length > 0)){
                    for (let index = 0; index < component.component.structure.length; index++) {
                        const element = component.component.structure[index];
                        element.selected = component.selected
                    }
                }
            } else if ((component.variableComposition) && (component.variable) && (component.variableComposition > 0)){
                for (let index = 0; index < component.variable.components.length; index++) {
                    const element = component.variable.components[index];                    
                    element.selecionados = [];
                }
            }
        };

        this.setState({ 
            product: product,
            valor: getAdditionalValueComposition(product.structure, false, 1)
        })
    }

    onConfirmar = () => {        
        if ((this.state.product.manufactured === 'S') && 
            (this.state.product.manufacturedAntecipated === 'N') &&
            (this.state.product.structure) && 
            (this.state.product.structure.length > 0)){ 

            let compositionValid = compositionValidate(this.state.product.structure);
            if (compositionValid.valid){
                onConfirmeStructureProduto(this.state.product.structure);
                this.addItem(this.state.product);
            } else {
                confirmAlert({
                    title: 'Atenção!',
                    message: compositionValid.message,
                    buttons: [{ label: 'Ok' }],
                    closeOnEscape: false,
                    closeOnClickOutside: false,
                    overlayClassName: "class-alert-livn"
                })
            }
        } else {
            this.addItem(this.state.product);
        }

    }

    onChangeNote = (text) => {
        let product = this.state.product;
        product.note = text;
        this.setState({ product: product });
    }

    addItem = (item) => {
        if (this.props.confirm){

            let additionalValue = 0;
            if (item.structure && (item.structure.length > 0)){
                additionalValue = getAdditionalValueComposition(item.structure, item.type === 3, 1);
            }

            let structure = undefined;
            if (item.structure && (item.structure.length > 0)){
                structure = item.structure;
            }

            let printerId = 0;
            if (item.printerId && (item.printerId > 0)){
                printerId = item.printerId;
            }

            let nControle = global.order.items.length;
            for (let index = 0; index < global.order.items.length; index++) {
                if (global.order.items[index].controle && (global.order.items[index].controle > 0)){
                    if (global.order.items[index].controle > nControle){
                        let nControle = global.order.items[index].controle;  
                    }
                }            
            }
            nControle += 1;

            global.order.items.push({
                controle: nControle,
                id: item.id, 
                group: item.groupId,
                name: item.name,
                unitPrice: item.unitPrice, 
                manufactured: item.manufactured, 
                manufacturedAntecipated: item.manufacturedAntecipated,
                timeControl: item.timeControl,
                serviceProvision: item.serviceProvision, 
                component: null,
                additionalValue: additionalValue,
                salesgroup: item.salesgroup, 
                amount: this.state.productAmount > 0 ? this.state.productAmount : 1, 
                type: item.type,
                sheet: item.sheet,
                stock: item.stock,
                typeControlStock: item.typeControlStock,
                typeProductionControlStock: 0,
                typeProduction: 0,
                structure: structure, 
                printerId: printerId,
                note: item.note              
            });

            this.props.onConfirme();
        } else {
            this.props.onConfirme(item);
        }
    }

    onCancelVariable = () => {
        this.setState({ 
            showVariable: false, 
            variable: null
        });
    }

    onVariableConfirme = (variable) => {
        if ((variable) && (variable.id > 0)){            
            let product = this.state.product;
            onConfirmeVariable(variable, product.structure);

            this.setState({
                showVariable: false, 
                product: product,
                variable: null, 
                valor: getAdditionalValueComposition(product.structure, false, 1)
            })
        }
    }

    renderItemPadrao = (item) => {
        if (item.default && (item.default === 'S')){
            return (
                <div className='product-detail-composition' onClick={() => {this.onPressItem(item)}}>                                                         
                    
                    {
                        this.props.permitefazerpedido ?
                        <Fragment>
                            {
                                item.customize && 
                                item.customize === 'S' ?
                                <Fragment>
                                    {
                                        item.selected && item.selected > 0 ? <Checkbox checked /> : <Checkbox checked={false}/>
                                    }
                                </Fragment> : 
                                <Checkbox disabled checked />
                            }
                        </Fragment> : <Fragment />
                    }                    
                    
                    <p className='product-detail-component' style={{marginLeft: this.props.permitefazerpedido ? '0px' : '10px'}}>{this.getName(item.component.name)}</p>
                </div>
            )
        } else if (this.props.permitefazerpedido && item.variable && (item.variable.id > 0) && (item.variable.requerid === 'S')){
            return (
                <div className='product-detail-aditional'>
                    <p className='product-aditional-component' style={{width: 'calc(100% - 105px)'}}>
                        {this.getName(item.variable.name)}
                    </p>
                    {
                        item.variable && 
                        item.selected > 0 ?
                        <div className='product-action'><AddIcon color="disabled"/></div> : 
                        <div className='product-action' onClick={() => {this.onPressAdd(item)}}>
                            <AddIcon color="primary"/>
                        </div>
                    }
                    <div className='product-selected'>
                        <p className='product-text-selected'>{item.selected}</p>
                    </div>
                    {
                        item.selected && 
                        item.selected > 0 ?
                        <div className='product-action' onClick={() => {this.onPressRemove(item)}}>
                            <RemoveIcon color="error"/>
                        </div> : 
                        <div className='product-action'><RemoveIcon color="disabled"/></div>
                    }
                </div>
            )
        } else {
            return ( <Fragment /> )
        }
    }

    renderItemOpcional = (item) => {
        if (item.default && (item.default !== 'S')){

            let widthName = '100%';
            if (this.props.permitefazerpedido){
                if (item.value && (item.value > 0)){
                    widthName = 'calc(100% - 185px)';
                } else {
                    widthName = 'calc(100% - 105px)';
                } 
            } else {
                if (item.value && (item.value > 0)){
                    widthName = 'calc(100% - 90px)';
                } 
            }

            return (
                <div className='product-detail-aditional'>
                    <p className='product-aditional-component' style={{ width: widthName }}>
                        {this.getName(item.component.name)}
                    </p>
                    {
                        this.props.permitefazerpedido ?
                        <Fragment>
                            <div className='product-action' onClick={() => {this.onPressAdd(item)}}>
                                <AddIcon color="primary"/>
                            </div>
                            <div className='product-selected'>
                                <p className='product-text-selected'>{item.selected}</p>
                            </div>
                            {
                                item.selected && 
                                item.selected > 0 ?
                                <div className='product-action' onClick={() => {this.onPressRemove(item)}}>
                                    <RemoveIcon color="error"/>
                                </div> : 
                                <div className='product-action'><RemoveIcon color="disabled"/></div>
                            }
                        </Fragment> : <Fragment />
                    }
                    {
                        item.value && item.value > 0 ?
                        <div className='product-detail-value'>
                            <span className='product-label-value'>+ R$ {item.value.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}</span>
                        </div> : <Fragment />
                    }
                </div>
            )
        } else if (this.props.permitefazerpedido && item.variable && (item.variable.id > 0) && (item.variable.requerid !== 'S')){
            return (
                <div className='product-detail-aditional'>
                    <p className='product-aditional-component' style={{width: 'calc(100% - 105px)'}}>
                        {this.getName(item.variable.name)}
                    </p>
                    {
                        item.variable && 
                        item.selected > 0 ?
                        <div className='product-action'><AddIcon color="disabled"/></div> : 
                        <div className='product-action' onClick={() => {this.onPressAdd(item)}}>
                            <AddIcon color="primary"/>
                        </div>
                    }
                    <div className='product-selected'>
                        <p className='product-text-selected'>{item.selected}</p>
                    </div>
                    {
                        item.selected && 
                        item.selected > 0 ?
                        <div className='product-action' onClick={() => {this.onPressRemove(item)}}>
                            <RemoveIcon color="error"/>
                        </div> : 
                        <div className='product-action'><RemoveIcon color="disabled"/></div>
                    }
                </div>
            )
        } else {
            return ( <Fragment /> )
        }
    }

    render() {
        return ( 
            <Modal
                open={this.props.show}
                disableAutoFocus={true}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">

                {
                    this.state.product ? 
                    <div className='product-detail' style={{maxHeight: this.state.clientHeight}}>
                        <div className='product-detail-close' onClick={() => { this.props.onClose() }}><CloseIcon sx={{color: '#373636'}}/></div>                                 
                        {
                            this.state.showVariable && 
                            this.state.variable && 
                            this.state.variable.id > 0 &&
                            <Variavel 
                                inComponent={false}
                                open={this.state.showVariable}
                                variable={this.state.variable}
                                onCancel={this.onCancelVariable.bind()}
                                onConfirme={this.onVariableConfirme.bind()}/> 
                        }                            
                        
                        <div className='product-detail-header'>
                            {
                                this.state.product.image && 
                                this.state.product.image !== '' ? 
                                <div className='product-detail-image' style={{backgroundImage: 'url(data:image/png;base64,'+this.state.product.image+')'}}/> :
                                <div className='product-detail-image-notfound'/>
                            }               
                            <h5 className='product-detail-name'>{this.state.product.name}</h5>                            
                            <h1 className='product-value'>
                                {
                                    this.state.productAmount > 1 ? 
                                    <span className='product-value-amount'>{this.state.productAmount} x </span> : <Fragment />
                                }
                                {this.state.product.valor.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}
                                {
                                    this.state.productAmount > 1 ? 
                                    <span className='product-value-total'> = R$ {(this.state.productAmount * this.state.product.unitPrice).toLocaleString('pt-BR', { minimumFractionDigits: 2 })} </span> : <Fragment />
                                }
                                {
                                    this.state.valor && this.state.valor > 0 ? 
                                    <span className='product-value-aditional'> + {this.state.valor.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}</span> : <Fragment />
                                }
                            </h1>

                        </div>

                        <div className='product-detail-container' style={{
                            maxHeight: this.props.permitefazerpedido ? this.state.clientHeight - 325 : this.state.clientHeight - 225
                        }}> 
                            {
                                this.state.product.structure && 
                                this.state.product.structure.length > 0 ? 
                                <Fragment>
                                    {
                                        this.props.tempadrao && this.props.temopcional ?
                                        <Fragment>
                                            <p className='product-detail-title'>Composição</p>
                                            {             
                                                this.state.product.structure.map(item => (
                                                    <Fragment key={item.id}>
                                                        { this.renderItemPadrao(item) }
                                                    </Fragment>                                                    
                                                ))                                        
                                            }
                                            <p className='product-detail-title' style={{marginTop: 25}}>Opcionais</p>
                                            {             
                                                this.state.product.structure.map(item => (
                                                    <Fragment key={item.id}>
                                                        { this.renderItemOpcional(item) }
                                                    </Fragment>                                                    
                                                ))                                        
                                            }
                                        </Fragment> : 
                                        <Fragment>
                                            {
                                                this.props.temopcional ? 
                                                <p className='product-detail-title'>Opcionais</p> : 
                                                <p className='product-detail-title'>Composição</p>
                                            }
                                            {             
                                                this.state.product.structure.map(item => (
                                                    <Fragment  key={item.id}>
                                                        { this.renderItemPadrao(item) }
                                                        { this.renderItemOpcional(item) }
                                                    </Fragment>             
                                                ))                                        
                                            }
                                        </Fragment>
                                    }

                                    {
                                        this.props.permitefazerpedido && 
                                        this.props.confirm ? 
                                        <TextField
                                            placeholder="Observação"
                                            id="textarea-obs"
                                            margin="normal" 
                                            color="warning"
                                            fullWidth 
                                            multiline
                                            rows={4}
                                            value={this.state.product.note}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                this.onChangeNote(event.target.value);
                                            }}/> : <Fragment />
                                    }
                                </Fragment> :                                 
                                <TextField
                                    placeholder="Observação"
                                    id="textarea-obs"
                                    margin="normal" 
                                    color="warning"
                                    fullWidth 
                                    multiline
                                    rows={6} 
                                    value={this.state.product.note}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        this.onChangeNote(event.target.value);
                                    }}/>
                            }

                        </div>

                        {
                            this.props.permitefazerpedido ?
                            <div className='product-footer' style={this.state.product.structure && this.state.product.structure.length > 0 ? {height: 60} : {height: 110}}>

                                {
                                    this.state.product.structure && 
                                    this.state.product.structure.length > 0 ? 
                                    <Fragment /> :
                                    <div className='product-amount'>

                                        {
                                            this.state.productAmount && 
                                            this.state.productAmount > 1 ?
                                            <div className='product-action' onClick={() => { this.setState({productAmount: this.state.productAmount - 1}) }}>
                                                <RemoveIcon color="error"/>
                                            </div> : 
                                            <div className='product-action'><RemoveIcon color="disabled"/></div>
                                        }

                                        <p className='product-label-amount'>{this.state.productAmount}</p>

                                        <div className='product-action' onClick={() => { this.setState({productAmount: this.state.productAmount + 1}) }}>
                                            <AddIcon color="primary"/>
                                        </div>
                                    </div>
                                }

                                <Button className='product-confirm' onClick={() => {this.onConfirmar()}}>
                                    <CheckIcon />
                                    <p className='confirme-label'>Adicionar produto</p>
                                </Button>
                            </div> : <Fragment />
                        }

                    </div> : <Fragment />
                }
            </Modal>  
        )
    }
}

export default Composicao;
