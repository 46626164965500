import React, { Fragment } from 'react';
import { GET, POST, validateERROR } from '../commons/Service';
import { global } from '../commons/Globals';
import { Oval } from  'react-loader-spinner';
import { Button, Modal, TextField } from '@mui/material';

import "@fontsource/poppins";
import '../commons/Globals'; 
import '../App.css';

import "../assets/css/style.css";
import "../assets/css/bootstrap.min.css";
import 'react-confirm-alert/src/react-confirm-alert.css'; 

import banner from "../assets/images/banner.png";

import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import StoreIcon from '@mui/icons-material/Store';
import InfoIcon from '@mui/icons-material/Info';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import LocalMallIcon from '@mui/icons-material/LocalMall';
import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';

import logo from "../assets/images/livn.png";

import { getComponentManufactured, getValue } from '../commons/Service.Product';
import Composicao from './Composicao';
import Pedido from './Pedido';
import Combo from './Combo';
import Pizza from './Pizza';
import Historico from './Historico';
import Info from './Info';

class Cardapio extends React.Component {
    constructor(props) {
        super();
        this.state = { 
            categories: [],
            filters: [], 
            items: [],
            code: '', 
            name: '',
            start: false,
            error: false,
            loader: true,
            search: false,
            message: '',
            typeservice: 1,
            departament: 0,    
            confimar: false,
            showcomposicao: false,
            showHistorico: false,
            showpizza: false,
            showcompo: false,
            showInfo: false,
            combo: null,
            product: null,
            tempadrao: false,
            confirmar: false,
            temopcional: false,
            permitefazerpedido: false,
            permitechamaratendente: false,
            chamaratendentehide: true,
            openSearch: false,
            scrollTo: true,
            textSearch: '',
            stickyClass: '',
            companyname: '',
            logo: '',
            open: '',
            close: '',
            available: '',
            description: '',
            address: '',
            uf: '',
            cep: '',
            phone: '',
            cellphone: '',
            phonelink: '',
            cellphonelink: '',
            countyName: '',
            districtName: '',
            number: '',
            houropen: '',
            hourclose: '',
            minimumdeliverytime: 0,
            maximumdeliverytime: 0, 
            timereadytopickup: 0,
            weekday: [],
            document: '', 
            orderId: 0
        }
    }
    
    componentDidMount() { 
        this.onLoadCardapio();
        window.addEventListener('scroll', this.stickNavbar);
    }
      
    componentWillUnmount() {
        window.removeEventListener('scroll', this.stickNavbar);
    }
    
    stickNavbar = (event) => {
        if (this.state.scrollTo && window !== undefined) {            
            this.setState({ stickyClass:''/* window.scrollY > 150 ? 'fixed-top' : ''*/ });

            if (this.state.categories && this.state.categories.length > 0){
                    
                let id = 0;
                const scrollElement = document.scrollingElement || document.documentElement;
                const center = {
                    x: scrollElement.scrollLeft + window.innerWidth / 2,
                    y: scrollElement.scrollTop + window.innerHeight / 2           
                }

                for (let index = 0; index < this.state.categories.length; index++) {
                    const target = document.getElementById(`group-${this.state.categories[index].id}`);

                    if (target){
                        let headerHeight = 0;
                        const header = document.getElementById('header');
                        if (header){
                            headerHeight = header.offsetHeight;
                        }

                        let porcentVisible = 100;
                        if ((scrollElement.scrollTop - headerHeight) > target.offsetTop){                            
                            porcentVisible = target.offsetHeight + headerHeight - scrollElement.scrollTop;
                            
                            if (porcentVisible > 0){
                                porcentVisible = ((porcentVisible * 100) / target.offsetHeight);                        
                                if (porcentVisible < 0){
                                    porcentVisible = 0;
                                }
                            }
                        }
                        if (porcentVisible > 50){
                            id = this.state.categories[index].id;
                            break;
                        }

                        const visibleHorizontal =
                            target.offsetLeft >= 0 &&
                            center.x >= target.offsetLeft &&
                            center.x < target.offsetLeft + target.offsetWidth;

                        const visibleVertical =
                            target.offsetTop >= 0 &&
                            center.y >= target.offsetTop &&
                            center.y < target.offsetTop + target.offsetHeight;

                        if (visibleVertical && visibleHorizontal){
                            id = this.state.categories[index].id;
                            break;
                        } 
                    }                    
                }

                if (id > 0){
                    for (let index = 0; index < this.state.categories.length; index++) {
                        const target = document.getElementById(`menu-categorie-${this.state.categories[index].id}`);
                        if (target){
                            if (this.state.categories[index].id === id){
                                target.classList.remove("filter");
                                target.classList.add("filter-active");

                                target.scrollIntoView();
                                /*const element = document.getElementById("menu-flters");
                                if (element){
                                    if (target.previousElementSibling){
                                        element.scrollTo({top: 0, left: target.offsetLeft, behavior: "smooth"});
                                    } else {
                                        element.scrollTo({top: 0, left: 0, behavior: "smooth"});
                                    }  
                                }*/

                            } else {
                                target.classList.add("filter");
                                target.classList.remove("filter-active");
                            }
                        }
                    }  
                }
            }

        }
    }

    onFilterChange = (newFilter, idfilter) => {
        const element = document.getElementById(newFilter);
        if (element && element.offsetTop && element.offsetTop > 0) {

            this.setState({ scrollTo: false }, () => {  

                if (element.previousElementSibling){
                    this.setState({ stickyClass: ''/*element.offsetTop > 150 ? 'fixed-top' : ''*/ }, () => {
                        window.scrollTo({ top: element.offsetTop -210/*+ 60*/, behavior: "smooth"})
                    });
                } else {
                    window.scrollTo({ top: 0, behavior: "smooth"});
                    this.setState({ stickyClass: '' });
                }              

                for (let index = 0; index < this.state.categories.length; index++) {
                    const target = document.getElementById(`menu-categorie-${this.state.categories[index].id}`);
                    if (target){
                        if (this.state.categories[index].id === idfilter){
                            target.classList.remove("filter");
                            target.classList.add("filter-active");
                        } else {
                            target.classList.remove("filter-active");
                            target.classList.add("filter");
                        }
                    }
                } 

                const interval = setInterval(() => {     
                    this.setState({ scrollTo: true });     
                    clearInterval(interval);          
                }, 1000)
                
            })

        }
            
    }

    leftPad = (value, totalWidth, paddingChar) => {
        let length = totalWidth - value.toString().length + 1;
        return Array(length).join(paddingChar || '0') + value;
    }

    onLoadCardapio = () => {
        this.setState({  
            loader: true, 
            start: false,
            error: false, 
            message: ''
        });

        let host = window.location.hostname.split('.');
        if ((host.length > 1) && (host[0] !== '') && (host[0] !== '192')){
            global.URL = `https://${host[0]}.livn.com.br:9199/api/v1/`;
        }
        global.authorization = '';
        global.token = '';

        /*  Realizar autenticação do parceiro */
        POST('partner', {
            username: global.username, 
            password: global.password 
        }, 10000).then((result) => {
            global.Store = result.store;
            global.authorization = result.authorization;

            /*  Realizar autenticação do usuário */
            POST('oauth', {
                username: 'livn_cardapio', 
                password: `livn_cardapio_${result.store}`
            }, 10000).then((response) => {

                if (response.token){
                    global.token = response.token; 
                }

                /* Valida status da loja */
                GET('store/status', 10000).then((result) => {                    
                    let logo = '';
                    if (result.logoBase64 && (result.logoBase64 !== '')){
                        logo = result.logoBase64
                    }
                    
                    global.logo = logo;
                    global.open = result.open;
                    global.close = result.close;
                    global.weekday = result.items;
                    global.minimumdeliverytime = result.minimumdeliverytime;
                    global.maximumdeliverytime = result.maximumdeliverytime;
                    global.timereadytopickup = result.timereadytopickup;
                    global.description = result.description;
                    global.address = result.address;
                    global.uf = result.uf;
                    global.cep = result.cep;
                    global.phone = result.phone;
                    global.cellphone = result.cellphone;
                    global.countyName = result.countyName;
                    global.districtName = result.districtName;
                    global.number = result.number;

                    let houropen = '';
                    let hourclose = '';
                    let phonelink = '';
                    let cellphonelink = '';

                    if (result.cellphone && result.cellphone !== ''){
                        cellphonelink = result.cellphone.replace(/[^0-9]/g,'');
                    }
                    if (result.phone && result.phone !== ''){
                        phonelink = result.phone.replace(/[^0-9]/g,'');
                    }                    

                    if (result.open && result.open !== ''){
                        let data = new Date(result.open);
                        if (data){
                            let hour = data.getHours();
                            let minute = data.getMinutes();
                            houropen = `${this.leftPad(hour, 2, '0')}:${this.leftPad(minute, 2, '0')}`;
                        }
                    }
                    if (result.close && result.close !== ''){
                        let data = new Date(result.close);
                        if (data){
                            let hour = data.getHours();
                            let minute = data.getMinutes();
                            hourclose = `${this.leftPad(hour, 2, '0')}:${this.leftPad(minute, 2, '0')}`;                            
                        }
                    }

                    let weekday = [];
                    if (result.items && result.items.length > 0){
                        weekday = result.items;
                    }   

                    this.setState({
                        permitefazerpedido: result.available === 'A',
                        weekday: weekday,
                        open: result.open,
                        close: result.close,
                        minimumdeliverytime: result.minimumdeliverytime,
                        maximumdeliverytime: result.maximumdeliverytime,
                        timereadytopickup: result.timereadytopickup,
                        description: result.description,
                        available: result.available,
                        address: result.address,
                        uf: result.uf,
                        cep: result.cep,
                        phone: result.phone,
                        phonelink: phonelink,
                        cellphone: result.cellphone,
                        cellphonelink: cellphonelink,
                        countyName: result.countyName,
                        districtName: result.districtName,
                        number: result.number,
                        houropen: houropen,
                        hourclose: hourclose,
                        logo: logo
                    }) 

                    /* Carrega configurações */
                    GET('settings', 10000).then((res) => {                       
                        
                        if (res.department && res.department > 0){
                            this.onLoadProdutos(res.department);
                        } else {
                            this.setState({ loader: false });
                        }

                    }).catch((error) => { 
                        let message = ''
                        let erro = validateERROR(error)
                        if ((erro) && (erro.message) && (erro.message !== '')){ 
                            message = erro.message
                        } else {
                            message = 'Não foi possível consultar configurações';
                        }                            
                        this.setState({ message: message, error: true })
                    }); 

                }).catch((error) => { 
                    let message = ''
                    let erro = validateERROR(error)
                    if ((erro) && (erro.message) && (erro.message !== '')){ 
                        message = erro.message
                    } else {
                        message = 'Não foi possível consultar o status da loja!';
                    }                            
                    this.setState({ message: message, error: true })
                });


            }).catch((error) => { 
                let message = ''
                let erro = validateERROR(error)
                if ((erro) && (erro.message) && (erro.message !== '')){ 
                    message = erro.message
                } else {
                    message = 'Não foi possível realizar o login';
                }                            
                this.setState({ message: message, error: true })
            });

        }).catch((error) => { 
            let message = ''
            let erro = validateERROR(error)
            if ((erro) && (erro.message) && (erro.message !== '')){ 
                message = erro.message
            } else {
                message = 'Não foi possível realizar o partner'
            }                            
            this.setState({ message: message, error: true })
        });  
    }

    onLoadProdutos = (departament) => {
        GET('product/' + departament, 30000).then((response) => {             
            if (response.groups && (response.groups.length > 0)){    
                this.setState({ filters: response.groups })

                if (response.products && (response.products.length > 0)){    
                    this.setState({ items: response.products })
                    
                    let companyname = '';
                    if (response.companyname && (response.companyname !== '')){
                        companyname = response.companyname
                    }

                    this.setState({
                        departament: departament,
                        companyname: companyname
                    })

                    let lista = [];
                    if (response && 
                        response.groups && (response.groups.length > 0) &&
                        response.products && (response.products.length > 0)){

                        for (let index = 0; index < response.groups.length; index++) {
                            const group = response.groups[index];
                            
                            let products = response.products.filter(product => { return product.salesgroup === group.id })
                            if (products && (products.length > 0)){  
                                let items = []
                                const list = JSON.stringify(products)
                                items = JSON.parse(list)

                                if (items && (items.length > 0)){
                                    for (let i = 0; i < items.length; i++) {
                                        const item = items[i];
                                        item.valor = `R$ ${item.unitPrice.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}`                                            
                                    }
                                }
                                
                                lista.push({
                                    id: group.id,
                                    name: group.name,
                                    items: items
                                })   
                            }
                        }
                            
                        this.setState({ categories: lista, start: true });
                    } 

                } else {                    
                    this.setState({ 
                        message: 'Sem produtos disponível para o módulo!',
                        error: true 
                    })
                }


            } else {
                this.setState({ 
                    message: 'Sem grupos de venda disponível para o módulo!',
                    error: true 
                })
            }            
        }).catch((error) => {
            let message = ''
            let erro = validateERROR(error)
            if ((erro) && (erro.message) && (erro.message !== '')){ 
                message = erro.message
            } else {
                message = 'Falha ao consultar produtos!'
            }     
            this.setState({ 
                message: message,
                error: true 
            })
        })
    }    

    onStart = () => {
        this.setState({ loader: false });
    }

    onDetalhes = (item) => {
        if (this.state.permitefazerpedido){

            this.onAddProduct(item);

        } else {
            /* Produto */
            if ((item.type === 1) || (item.type === 4)){
                if ((item.manufactured === 'S') && 
                    (item.manufacturedAntecipated === 'N') && 
                    (item.structure) && (item.structure.length > 0)){
                    let tempadrao = false;
                    let temopcional = false;

                    for (let index = 0; index < item.structure.length; index++) {
                        const element = item.structure[index];
                        if (element.default && (element.default === 'S')){
                            tempadrao = true
                        } else {
                            temopcional = true
                        }

                        if (tempadrao && temopcional){
                            break;
                        }
                    }
                    
                    this.setState({
                        showcomposicao: true, 
                        product: item, 
                        tempadrao: tempadrao,
                        temopcional: temopcional
                    })
                }

            /* Kit de Produto */
            } else if (item.type === 2){
                const kitJSON = JSON.stringify(item)
                let kit = JSON.parse(kitJSON)
                for (let index = 0; index < kit.structure.length; index++) {
                    const element = kit.structure[index]  
                    if ((element.variableComposition) && (element.variable) && (element.variableComposition > 0)){
                        for (let index = 0; index < element.variable.components.length; index++) {
                            element.variable.components[index].selected = 0
                        }   
                    }                    
                }
                this.setState({ showcompo: true, combo: kit })
    
            /* Pizza */
            } else if (item.type === 3){
                let pizza = getComponentManufactured(item, item.unitPrice, item.id, false);
                this.setState({ showpizza: true, pizza: pizza });
            }
        }
    }

    onAddProduct = (item) => {                
        /* Produto ou Serviço Para Prestação */
        if ((item.type === 1) || (item.type === 4)){

            if ((item.manufactured === 'S') && 
                (item.manufacturedAntecipated === 'N') && 
                (item.structure) && (item.structure.length > 0)){
                
                let tempadrao = false;
                let temopcional = false;

                for (let index = 0; index < item.structure.length; index++) {
                    const element = item.structure[index];
                    if (element.default && (element.default === 'S')){
                        tempadrao = true
                    } else {
                        temopcional = true
                    }

                    if (tempadrao && temopcional){
                        break;
                    }
                }
                
                this.setState({
                    product: getComponentManufactured(item, item.unitPrice, item.id, false), 
                    temopcional: temopcional,
                    tempadrao: tempadrao,
                    showcomposicao: true
                })
            } else {
                this.setState({
                    showcomposicao: true, 
                    temopcional: false,
                    tempadrao: false,
                    product: item
                })
            }

        /* Kit de Produto */
        } else if (item.type === 2){

            const kitJSON = JSON.stringify(item)
            let kit = JSON.parse(kitJSON)
            for (let index = 0; index < kit.structure.length; index++) {
                const element = kit.structure[index]  
                if ((element.variableComposition) && (element.variable) && (element.variableComposition > 0)){
                    for (let index = 0; index < element.variable.components.length; index++) {
                        element.variable.components[index].selected = 0
                    }   
                }                    
            }
            this.setState({ showcompo: true, combo: kit })

        /* Pizza */
        } else if (item.type === 3){
            let pizza = getComponentManufactured(item, item.unitPrice, item.id, false);
            this.setState({ showpizza: true, pizza: pizza });
        }
    }

    onCloseDetalhes = () => {
        this.setState({ 
            showcomposicao: false, 
            product: null, 
            tempadrao: false,
            temopcional: false
        })   
    }

    onConfirmeDetalhes = () => {
        this.setState({ 
            showcomposicao: false, 
            product: null, 
            tempadrao: false,
            temopcional: false
        })   
    }

    onConfirmeKit = () => {
        this.setState({ showcompo: false, combo: null })
    }

    onCancelKit = () => {
        this.setState({ showcompo: false, combo: null })  
    }

    onConfirmePizza = () => {
        this.setState({ showpizza: false, pizza: null }) 
    }

    onCancelPizza = () => {
        this.setState({ showpizza: false, pizza: null }) 
    }

    onConfirmar = () => {
        this.setState({ confirmar: true }) 
    }

    onClosePedido = () => {
        this.setState({ confirmar: false })  
    }

    onConfirmPedido = (document, orderId) => {
        this.setState({
            loader: true,
            confirmar: false,
            orderId: orderId, 
            document: document, 
            showHistorico: orderId > 0
        })
    }

    onCloseSearch = () => {
        this.setState({ openSearch: false }, () => {
            this.onChangeText('')
        })
    }

    onChangeText = (value) => {
        let lista = [];
        if (this.state.filters && this.state.filters.length > 0 &&
            this.state.items && this.state.items.length > 0){

            let produtos = [];
            if (value && value !== ''){
                produtos = this.state.items.filter(item => item.name.toUpperCase().includes(value.toUpperCase() ))
            } else {
                produtos = [...this.state.items]
            }

            for (let index = 0; index < this.state.filters.length; index++) {
                const group = this.state.filters[index];
                
                let products = produtos.filter(product => { return product.salesgroup === group.id })
                if (products && (products.length > 0)){  
                    let items = []
                    const list = JSON.stringify(products)
                    items = JSON.parse(list)

                    if (items && (items.length > 0)){
                        for (let i = 0; i < items.length; i++) {
                            const item = items[i];
                            item.valor = `R$ ${item.unitPrice.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}`                                            
                        }
                    }
                    
                    lista.push({
                        id: group.id,
                        name: group.name,
                        items: items
                    })   
                }
            }
                
            this.setState({ categories: lista, textSearch: value });
        }
    }

    onCloseHistorico = () => {
        this.setState({ showHistorico: false, document: '', orderId: 0 })
    }

    onCloseInfo = () => {
        this.setState({ showInfo: false })
    }

    render() {

        if (this.state.showHistorico){
            return (
                <Historico 
                    orderId={this.state.orderId}
                    document={this.state.document}
                    show={this.state.showHistorico} 
                    onClose={this.onCloseHistorico.bind(this)} />
            )
        }

        if (this.state.loader){
            return (
                <Modal
                    open={this.state.loader}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description">
                    <div className='modal-loader'> 

                        <div className='modal-content-app'>

                            {
                                this.state.start ?
                                <Fragment>
                                    
                                    <div className='div-store-content'>
                                        {
                                            this.state.logo !== '' ?
                                            <img className='menu-logo' src={`data:image/png;base64,${this.state.logo}`}/> :
                                            <Fragment />
                                        }
                                        <div className="section-title" >
                                            <h3 className='label-company'>{this.state.companyname}</h3>
                                        </div>

                                        <div className='store-status'>
                                            {
                                                this.state.permitefazerpedido ? 
                                                <div className='store-status-open'>
                                                    <StoreIcon sx={{ color: '#4caf50', fontSize: 18, marginTop: '2px'}}/>
                                                    <p className='store-label-status-open'>Aberto agora</p>
                                                </div>: 
                                                <div className='store-status-close'>
                                                    <StoreIcon sx={{ color: '#f43127', fontSize: 18, marginTop: '2px'}}/>
                                                    <p className='store-label-status-close'>Loja Fechada</p> 
                                                </div>
                                            }                                            

                                            <div className='store-status-hour'>
                                                <AccessTimeIcon sx={{ color: '#5046BB', fontSize: 15, marginTop: '2px'}}/>
                                                <p className='store-status-label-hour'>{this.state.houropen} - {this.state.hourclose}</p>
                                            </div> 
                                                                            
                                            {
                                                this.state.minimumdeliverytime && 
                                                this.state.minimumdeliverytime > 0 ?
                                                <div style={{display: 'flex', marginBottom: 15}}>
                                                    <div className='store-status-time'>
                                                        <DeliveryDiningIcon sx={{ color: '#5046BB', fontSize: 28, marginTop: '2px', marginRight: '5px'}}/>
                                                        <div>
                                                            <p className='store-status-label-time'>{this.state.minimumdeliverytime} - {this.state.maximumdeliverytime}</p>
                                                            <p className='store-status-info-time'>MINUTOS</p>
                                                        </div>
                                                    </div>

                                                    {
                                                        this.state.timereadytopickup && 
                                                        this.state.timereadytopickup > 0 ?
                                                        <Fragment>
                                                            <div className='store-divide-time'/>        
                                                            <div className='store-status-time'>
                                                                <LocalMallIcon sx={{ color: '#5046BB', fontSize: 24, marginTop: '2px', marginRight: '5px'}}/>
                                                                <div>
                                                                    <p className='store-status-label-time'>{this.state.timereadytopickup}</p>
                                                                    <p className='store-status-info-time'>MINUTOS</p>
                                                                </div>
                                                            </div>
                                                        </Fragment> : <Fragment />
                                                    }
                                                </div> : <Fragment/>
                                            }  
                                            
                                            {
                                                this.state.cellphone && 
                                                this.state.cellphone !== '' ?
                                                <a style={{marginBottom: 5}} className='store-phone' href={`https://api.whatsapp.com/send?text=Olá, estou realizando um pedido pelo LivnFAST 😄&phone=+55${this.state.cellphonelink}`}>
                                                    <WhatsAppIcon sx={{color: '#008069', fontSize: 20, marginTop: '1px'}}/>
                                                    <p className='store-label-phone'>{this.state.cellphone}</p>
                                                </a> : <Fragment />   
                                            } 
                                            {
                                                this.state.phone && 
                                                this.state.phone !== '' ?
                                                <a className='store-phone' href={`tel:+55${this.state.phonelink}`}>
                                                    <LocalPhoneIcon sx={{color: '#6b6b6b', fontSize: 20, marginTop: '1px'}}/>
                                                    <p className='store-label-phone'>{this.state.phone}</p>
                                                </a> : <Fragment />
                                            } 

                                            <div style={{marginBottom: 20}}>
                                                <div>
                                                    <LocationOnIcon sx={{color: '#6b6b6b', fontSize: 15, marginTop: '1px'}}/>
                                                    <div>
                                                        <p className='store-label-address'>{this.state.address} - {this.state.number}</p>
                                                        <p className='store-label-address'>{this.state.districtName} - {this.state.countyName} - {this.state.uf}</p>
                                                        <p className='store-label-address'>CEP: {this.state.cep}</p>
                                                    </div>
                                                </div>
                                            </div> 
                                        </div>

                                        <Button className='button-start' onClick={() => { this.onStart() }}>
                                            {
                                                this.state.permitefazerpedido ? 
                                                <Fragment>
                                                    {
                                                        global.order.items.length > 0 ?
                                                        <p className='call-label'>Continuar pedido</p> : 
                                                        <p className='call-label'>Iniciar novo pedido</p>
                                                    }
                                                </Fragment> : 
                                                <p className='call-label'>Confira nosso menu</p>
                                            }
                                        </Button>
                                        
                                        <Button className='button-orders' onClick={() => { this.setState({ showHistorico: true }) }}>
                                            <p className='call-label'>Consultar meus pedidos</p>
                                        </Button>
                                    </div>

                                </Fragment> :
                                <Fragment>
                                    {
                                        this.state.error ? 
                                        <div className='div-label-message'>
                                            <p className='label-message'>{this.state.message}</p>
                                            <Button className='button-refresh' onClick={() => { this.onLoadCardapio() }}>
                                                <p className='call-label'>Recarregar</p>
                                            </Button>
                                        </div> :
                                        <div className='modal-loading'>
                                            <Oval
                                                height={20}
                                                width={20}
                                                color="#ffb03b"
                                                wrapperStyle={{}}
                                                wrapperClass=""
                                                visible={true}
                                                ariaLabel='oval-loading'
                                                secondaryColor="#ffb03b"
                                                strokeWidth={2}
                                                strokeWidthSecondary={2}/>
                                            <p className='label-loader'>Consultando produtos. Aguarde...</p>
                                        </div>
                                    }
                                </Fragment> 
                            }
                        </div>

                    </div>
                </Modal> 
            ) 
        }

        return (
            <Fragment> 
                <img src={banner} className="fixed-top" style={{zIndex: -10}}/> 
                <header id="header" className="d-flex align-items-center fixed-top">                    
                    <div className="container-xl justify-content-between" style={styles.header_container}>
                        <div className="me-auto" style={{width: '100%'}}>

                            {
                                this.state.showInfo ?
                                <Info 
                                    show={this.state.showInfo}
                                    weekday={this.state.weekday}
                                    onClose={this.onCloseInfo.bind(this)} /> : <Fragment />
                            }

                            <div className='div-store'>                                
                                <div className='store-div-logo' onClick={() => { this.setState({ loader: true })}}>
                                    {
                                        this.state.logo !== '' ?
                                        <img className='store-logo' src={`data:image/png;base64,${this.state.logo}`}/> :
                                        <Fragment />
                                    }
                                    {
                                        this.state.permitefazerpedido ? 
                                        <div className='div-store-status-open'>
                                            <StoreIcon sx={{ color: '#4caf50', fontSize: 14, marginTop: '2px'}}/>
                                            <p className='div-store-status-label-open'>Aberto</p>
                                        </div>: 
                                        <div className='div-store-status-close'>
                                            <StoreIcon sx={{ color: '#f43127', fontSize: 18, marginTop: '2px'}}/>
                                            <p className='div-store-status-label-close'>Fechado</p> 
                                        </div>
                                    } 
                                </div>

                                <div className='div-info-store'>
                                    <h2 className='section-companyname'>{this.state.companyname}</h2>
                                    <div style={{marginLeft: 10, marginRight: 10}}>
                                        {
                                            this.state.minimumdeliverytime && 
                                            this.state.minimumdeliverytime > 0 ?
                                            <div style={{display: 'flex'}}>
                                                <div className='store-status-time'>
                                                    <DeliveryDiningIcon sx={{ color: '#7b7b7b', fontSize: 26, marginTop: '2px', marginRight: '5px'}}/>
                                                    <div style={{textAlign: 'center'}}>
                                                        <p className='store-status-label-header'>{this.state.minimumdeliverytime} - {this.state.maximumdeliverytime}</p>
                                                        <p className='store-status-info-time'>MINUTOS</p>
                                                    </div>
                                                </div>

                                                {
                                                    this.state.timereadytopickup && 
                                                    this.state.timereadytopickup > 0 ?
                                                    <Fragment>
                                                    <div className='store-divide-time'/>
                                                    <div className='store-status-time'>
                                                        <LocalMallIcon sx={{ color: '#7b7b7b', fontSize: 22, marginTop: '2px', marginRight: '5px'}}/>
                                                        <div style={{textAlign: 'center'}}>
                                                            <p className='store-status-label-header'>{this.state.timereadytopickup}</p>
                                                            <p className='store-status-info-time'>MINUTOS</p>
                                                        </div>
                                                    </div>
                                                    </Fragment> : <Fragment />
                                                }

                                                <div className='store-divide-time'/>

                                                <div className='store-div-info' onClick={() => { this.setState({ showInfo: true }) }}>
                                                    <InfoIcon sx={{color: '#5046BB', fontSize: 22, paddingBottom: '3px'}}/>  
                                                    <p className='store-status-label-header' style={{fontSize: 10}}>+ INFO</p> 
                                                </div>
                                            </div> : <Fragment/>
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className={`navbar-menu ${this.state.stickyClass}`}>                    
                                <div className="menu container">    
                                    <div className="row">            
                                        <div className="col-lg-12 d-flex justify-content-center">                                            
                                            {
                                                this.state.openSearch ?                                          
                                                <div className='open-search'>
                                                    <CloseIcon onClick={() => { this.onCloseSearch() }} sx={{
                                                        color: '#7b7b7b', 
                                                        fontSize: 22, 
                                                        position: 'absolute',
                                                        left: 10,
                                                        background: '#fff',
                                                        borderRadius: '50px 0px 0px 50px',
                                                        height: 28,
                                                        paddingLeft: '8px',
                                                        paddingRight: '8px',
                                                        width: 39,
                                                        zIndex: 999,
                                                        top: 4
                                                    }}/>
                                                    <TextField
                                                        inputProps = {{ 
                                                            sx: {
                                                                borderStyle: 'solid',
                                                                borderColor: '#7b7b7b', 
                                                                '&::placeholder': { 
                                                                    color: '#cfcfcf',  
                                                                    fontSize: '12px'
                                                                }
                                                            } 
                                                        }}
                                                        placeholder="Pesquisar"
                                                        id="textarea-search"
                                                        margin="none" 
                                                        color="info"
                                                        fullWidth 
                                                        value={this.state.textSearch}
                                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                            this.onChangeText( event.target.value )
                                                        }} /> 
                                                </div> : <Fragment />  
                                            }
                             
                                            <div className='menu-button-search' onClick={() => {this.setState({openSearch: true})}}>
                                                <SearchIcon sx={{color: '#7b7b7b', fontSize: 22}}/>
                                            </div>
                                            <ul id="menu-flters" style={{paddingBottom: 5}}>                                                    
                                                {
                                                    this.state.categories && 
                                                    this.state.categories.length > 0 ?
                                                    <Fragment>
                                                        {                                                    
                                                            this.state.categories.map((categorie, index)  => (
                                                                <Button 
                                                                    key={index} 
                                                                    id={`menu-categorie-${categorie.id}`}
                                                                    className={index === 0 ? 'filter-active' :'filter'}
                                                                    onClick={() => {this.onFilterChange(`group-${categorie.id}`, categorie.id)}}>
                                                                    {categorie.name.charAt(0).toUpperCase() + categorie.name.slice(1).toLowerCase()}
                                                                </Button>
                                                            ))
                                                        }
                                                    </Fragment> : 
                                                    <Fragment></Fragment>
                                                }
                                            </ul>
                                        </div> 
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </header>       

                {
                    this.state.showcomposicao && 
                    this.state.product && 
                    this.state.product.id > 0 &&
                    <Composicao 
                        confirm={true}
                        show={this.state.showcomposicao} 
                        product={this.state.product}
                        tempadrao={this.state.tempadrao}
                        temopcional={this.state.temopcional}
                        onClose={this.onCloseDetalhes.bind(this)}
                        onConfirme={this.onConfirmeDetalhes.bind(this)}
                        permitefazerpedido={this.state.permitefazerpedido} />   
                }  

                {
                    this.state.showcompo &&
                    this.state.combo && 
                    this.state.combo.id > 0 && 
                    <Combo
                        show={this.state.showcompo} 
                        kit={this.state.combo}
                        permitefazerpedido={this.state.permitefazerpedido} 
                        onConfirmPressed={ this.onConfirmeKit.bind(this) }
                        onCancelPressed={ this.onCancelKit.bind(this) } />
                } 

                {
                    this.state.showpizza && 
                    this.state.pizza && 
                    this.state.pizza.id > 0 &&
                    <Pizza
                        confirm={true}
                        show={this.state.showpizza} 
                        pizza={this.state.pizza}
                        permitefazerpedido={this.state.permitefazerpedido} 
                        onConfirmPressed={ this.onConfirmePizza.bind(this) }
                        onCancelPressed={ this.onCancelPizza.bind(this) }  />
                        
                }

                {
                    this.state.confirmar &&
                    <Pedido
                        code={this.state.code}
                        name={this.state.name}
                        show={this.state.confirmar} 
                        typeservice={this.state.typeservice}
                        departament={this.state.departament}
                        onClose={this.onClosePedido.bind(this)}
                        onConfirm={this.onConfirmPedido.bind(this)}/>
                }   

                {
                    this.state.permitefazerpedido ?
                    <Fragment>
                        {
                            global.order.items.length > 0 ?
                            <div className='confirm-order'>
                                <Button className='confirm-button-order' onClick={() => {this.onConfirmar()}}>
                                    <ShoppingCartOutlinedIcon />
                                    <p className='confirm-quantity'>{global.order.items.length}</p>
                                    <p className='confirm-label'>Confirmar pedido</p>
                                    <p className='order-value-confirm'>R$ {getValue(global.order.items).toLocaleString('pt-BR', { minimumFractionDigits: 2 })}</p>
                                </Button> 
                            </div> : <Fragment />
                        }
                    </Fragment> : <Fragment />  
                }
                
                {
                    this.state.search ? <Fragment /> : 
                    <div style={{position: 'relative'}}>   
                        <div id="menu" className="menu container" style={{paddingTop: 210}}> 
                            <div style={{ backgroundColor: '#FAF7F2', padding: 20}}>
                                <div className="row">                                 
                                    <div id="filter-container" className="col-12">                                    
                                        {
                                            this.state.categories.map(categorie => (
                                                <Fragment key={categorie.id}>
                                                    <div id={`group-${categorie.id}`}>
                                                    
                                                    <div className={`col-11 filter-item filter-0`}  ref={el => (this.innercontainer = el)}>
                                                        <p className='menu-title'>{categorie.name.charAt(0).toUpperCase() + categorie.name.slice(1).toLowerCase()}</p>
                                                    </div>
                                                    {
                                                        categorie.items && 
                                                        categorie.items.length > 0 ? 
                                                        <Fragment>
                                                            {             
                                                                categorie.items.map(item => (
                                                                    <div key={item.id} className={`col-11 filter-item filter-${item.salesgroup}`}>
                                                                        <div className="col-lg-12 menu-item">
                                                                        
                                                                
                                                                            <div className="menu-content" onClick={() => {this.onDetalhes(item)}}>                                                                            
                                                                                <div className='item-image'>
                                                                                    {
                                                                                        item.image && 
                                                                                        item.image !== '' ?
                                                                                        <img className='menu-image' src={`data:image/png;base64,${item.image}`}/> :
                                                                                        <div className='menu-not-image' />
                                                                                    }
                                                                                </div>
                                                                                
                                                                                <div className='menu-product'>
                                                                                    <p className='name'>
                                                                                        { item.name.charAt(0).toUpperCase() + item.name.slice(1).toLowerCase() }
                                                                                    </p>   
                                                                                    <p className='item-price'>{item.valor} <span className='item-unidade'>- {item.unit}</span></p> 
                                                                                </div>                                                                                                            
                                                                            </div>
                                                                                
                                                                            {
                                                                                item.detail && 
                                                                                item.detail !== '' ?
                                                                                <div className="menu-ingredients">
                                                                                    <p>{item.detail}</p>
                                                                                </div> :
                                                                                <Fragment />
                                                                            }

                                                                        </div>
                                                                    </div> 
                                                                ))

                                                            }
                                                        </Fragment> : <Fragment/> 
                                                    }
                                                    </div>
                                                </Fragment>
                                            ))
                                        }                                
                                    </div>

                                </div>
                            </div> 
                        </div>
                    </div>
                }

                <footer id="footer">
                    <div className="container"> 
                        <p className="credits">
                            Desenvolvido por 
                            <a href="https://api.whatsapp.com/send?text=Olá, conheci a plataforma de vocês atraves do LivnFAST e gostaria de mais informações sobre o sistema Livn&phone=+5546991250760">
                                <img style={{width: 50, height: 'auto', marginBottom: 5, marginLeft: 5}} src={logo}/>
                            </a>
                        </p>
                    </div>
                </footer>
            </Fragment>
        )
    }
}

export default Cardapio;

const styles = {
    header_container: {
        display: 'flex', 
        flexWrap: 'wrap', 
        flexDirection: 'row', 
        alignItems: 'center'
    }, 
    modal: {
        hwidth: '100vw',
        height: '100vh',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
    }, 
    modal_confirme: {
        height: 50, 
        backgroundColor: 'rgb(237, 247, 237)', 
        padding: 40,
        borderRadius: 5, 
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
    }, 
    modal_message: {
        fontSize: 15, 
        margin: 0, 
        marginLeft: 10
    }, 
    modal_loader: {
        height: 50, 
        backgroundColor: '#fff', 
        padding: 40,
        borderRadius: 5, 
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
    }
}