import React, { Fragment } from 'react';

import MopedOutlinedIcon from '@mui/icons-material/MopedOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ShoppingBagOutlinedIcon from '@mui/icons-material/ShoppingBagOutlined';

import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css'; 

import { Oval } from  'react-loader-spinner';
import { global } from '../commons/Globals';
import { Button, Modal } from '@mui/material';
import { GET, POST, validateERROR } from '../commons/Service';

import {
    getValue, 
    compositionEditable,
    getCompositionAdditional
} from '../commons/Service.Product';
import Cliente from './Cliente';
import Pagamento from './Pagamento';

class Pedido extends React.Component {


    constructor(props) {
        super(); 
        this.state = {
            clientHeight: window.innerHeight - 280, 
            customerOpeningCommand: false,
            disabledAction: false,
            enableConfirm: false,
            showPagamento: false,
            showCliente: false,
            validando: false,
            labelloader: '',
            operation: 0,
            moneytype: 0,

            valorPedido: 0,
            deliveryFee: 0,
            tipoEntrega: 2,
            zIndexButtomEntrega: 99,
            zIndexButtomRetirada: 0,
            ColorButtomEntrega: '#FF9800',
            ColorButtomRetirada: '#f3f3f3',
            IconColorButtomEntrega: '#FFF',
            IconColorButtomRetirada: '#737373',

            paymentmethods: [], 
            operators: [],
            flags: [],

            settingsperson: [],
            districts: [],
            counties: [],
            items: [], 
            
            fields: [],
            client: null,
            address: null
        }
    }

    componentDidMount() {
        window.addEventListener('resize', this.handleResizedScreen);

        let items = global.order.items;
        for (let I = 0; I < items.length; I++) {
            this.onGetDetalhes(items[I], items[I].type === 2, items[I].type === 3);
        } 
            
        this.setState({ 
            items: items, 
            enableConfirm: true
        });  
    }

    handleResizedScreen = () => {          
        this.setState({ clientHeight: window.innerHeight - 280 });
    };
      
    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResizedScreen);
    }

    onGetDetalhes = (item, hekit, hePizza) => {
        if (hekit){
            let detalhes = getCompositionAdditional(item.structure, hekit, hePizza);
            item.detail = detalhes;

        } else if ( item && item.structure &&
                    (item.manufactured === 'S') && 
                    (item.manufacturedAntecipated === 'N') && 
                    (compositionEditable(item.structure)) ){
            let detalhes = getCompositionAdditional(item.structure, hekit, hePizza);
            item.detail = detalhes;
        } else {
            item.detail = '';
        }
    }

    getValor = () => {
        return getValue(this.state.items);
    }

    getName = (texto) => {
        let nameFormat = '';
        if (texto && (texto !== '')){
            nameFormat = texto.charAt(0).toUpperCase() + texto.slice(1).toLowerCase();
        }  
        return nameFormat;        
    }

    onPressRemove = (item, index) => {
        confirmAlert({
            title: 'Atenção!',
            message: `Deseja remover o produto ${item.name} do seu pedido?`,
            buttons: [
                { label: 'Sim', 
                    onClick: () => {
                        let items = global.order.items;    
                        for (let I = 0; I < items.length; I++) {
                            if ((items[I].id === item.id) && (I === index)){
                                items.splice(I, 1);;
                                this.setState({ items: items });
                                if (items.length === 0){
                                    this.props.onClose()
                                }
                                break;
                            }
                        }
                    }
                }, 
                { label: 'Não' }
            ],
            closeOnEscape: false,
            closeOnClickOutside: false,
            overlayClassName: "class-alert-livn"
        })         
    }

    onConfirmar = () => {   
        if (!this.state.disabledAction){ 
            this.setState({ 
                labelloader: 'Validando pedido. Aguarde...',
                customerOpeningCommand: false,
                disabledAction: true,
                validando: true,
                deliveryFee: 0,
                operation: 0
            }, () => {
                GET('validate/', 20000).then((response) => { 
                    let operation = 0;
                    if (response.operation && (response.operation > 0)){
                        operation = response.operation;
                    }
                    if (operation === 0){
                        operation = global.operation;
                    }

                    if (response.deliveryfee && (response.deliveryfee > 0)){
                        global.deliveryfee = response.deliveryfee;
                    }

                    this.setState({ 
                        operation: operation,
                        customerOpeningCommand: true, 
                        counties: response.counties,
                        districts: response.districts,
                        settingsperson: response.items
                    })

                    GET('finance/releasetype/', 20000).then((res) => {                         
                        
                        this.setState({ 
                            fields: [],
                            client: null,
                            address: null,
                            validando: false, 
                            showCliente: true,
                            showPagamento: false,
                            valorPedido: this.getValor(),
                            paymentmethods: res.paymentmethods, 
                            moneytype: res.moneytype, 
                            operators: res.operators,
                            flags: res.flags
                        }) 

                    }).catch((error) => {
                        let message = '';
                        let erro = validateERROR(error);
                        if ((erro) && (erro.message) && (erro.message !== '')){ 
                            message = erro.message;
                        } else {
                            message = 'Erro ao validar formas de pagamento';
                        }

                        this.setState({ disabledAction: false, validando: false }, () => {
                            confirmAlert({
                                title: 'Erro ao consultar formas de pagamento!',
                                message: message,
                                buttons: [{ label: 'Ok' }],
                                closeOnEscape: false,
                                closeOnClickOutside: false,
                                overlayClassName: "class-alert-livn"
                            }) 
                        })
                    })

                }).catch((error) => {
                    let message = '';
                    let erro = validateERROR(error);
                    if ((erro) && (erro.message) && (erro.message !== '')){ 
                        message = erro.message;
                    } else {
                        message = 'Erro ao validar pedido';
                    }

                    this.setState({ disabledAction: false, validando: false }, () => {
                        if ((erro) && (erro.status) && (erro.status === 400)){
                            confirmAlert({
                                title: 'Atenção!',
                                message: message,
                                buttons: [{ label: 'Ok' }],
                                closeOnEscape: false,
                                closeOnClickOutside: false,
                                overlayClassName: "class-alert-livn"
                            })
                        } else {
                            confirmAlert({
                                title: 'Erro ao validar pedido!',
                                message: message,
                                buttons: [{ label: 'Ok' }],
                                closeOnEscape: false,
                                closeOnClickOutside: false,
                                overlayClassName: "class-alert-livn"
                            }) 
                        }  
                    })
                })
            })
        }      
    }  

    onCloseCliente = () => {
        this.setState({ 
            validando: false, 
            showCliente: false, 
            showPagamento: false,
            disabledAction: false,
            deliveryFee: 0,
            fields: [],
            client: null,
            address: null,
            counties: [],
            districts: [],
            settingsperson: []
        })
    }

    onClosePagamento = () => {
        this.setState({ 
            validando: false, 
            showCliente: false, 
            showPagamento: false,
            disabledAction: false
        })
    }

    onConfirmeCliente = (client, fields, address) => { 
        let v_deliveryfee = 0;
        if (address && address.deliveryFee && address.county > 0 && this.state.tipoEntrega === 2){
            v_deliveryfee = address.deliveryFee;
        } 

        this.setState({
            fields: fields,
            client: client,
            address: address, 
            showCliente: false, 
            deliveryFee: v_deliveryfee
        }, () => {
            this.setState({ showPagamento: true })
        })       
    }   

    onConfirmePagamento = (payment) => {
        let pagamento = {
            valor: payment.value,
            motivomov: payment.payment.id,
            debito: payment.payment.debit.id,
            tipodebito: payment.payment.debit.type,
            
            /* Dados quando é cartão */
            tipocartao: payment.operationType,
            bandeira: payment.idFlag
        }; 

        this.setState({ 
            labelloader: 'Confirmando pedido. Aguarde...',
            customerOpeningCommand: false,
            validando: true,
            operation: 0
        }, () => { 
            this.onEnviarPedido(this.state.client, this.state.fields, this.state.address, pagamento)
        })
    }
    
    onEnviarPedido = (client, fields, address, pagamento) => {
        this.setState({ validando: true, labelloader: 'Enviando pedido. Aguarde...' });        
        
        /* Valida status da loja */
        GET('store/status', 10000).then((result) => {
            if (result.available === 'A'){
                let v_county = 0;
                let v_district = 0;
                let v_address = '';
                let v_number = '';
                let v_complement = '';
                let v_cep = '';
                let v_deliveryfee = 0;

                if (address && address.county > 0 && this.state.tipoEntrega === 2){
                    v_county = address.county;
                    v_district = address.district;
                    v_address = address.address;
                    v_number = address.number;
                    v_complement = address.complement;
                    v_cep = address.cep;
                    v_deliveryfee = address.deliveryFee;
                } else if (client){
                    v_county = client.pesmunicipio;
                    v_district = client.pesbairro;
                    v_address = client.pesendereco;
                    v_number = client.pesnumero;
                    v_complement = client.pescomplemento;
                    v_cep = client.pescep;
                }

                try {
                    v_address = v_address.normalize('NFD').replace(/[\u0300-\u036f]/g, "");;
                    v_number = v_number.normalize('NFD').replace(/[\u0300-\u036f]/g, "");;
                    v_complement = v_complement.normalize('NFD').replace(/[\u0300-\u036f]/g, "");;
                    v_cep = v_cep.normalize('NFD').replace(/[\u0300-\u036f]/g, "");;
                } catch (error) {}

                POST('open/', {  
                    openCommand: {
                        iddepartamento: this.props.departament,
                        customerOpeningCommand: true, 
                        client: client, 
                        fields: fields,
                        barcode: ''
                    }, 
                    orderCommand: {
                        ordersheet: {}, 
                        departament: this.props.departament,
                        order: global.order, 
                        operation: 0,
                        reference: '',
                        control: 0,
                        origin: 0
                    }, 
                    payment: pagamento,
                    deliverytype: this.state.tipoEntrega,
                    deliveryfee: v_deliveryfee,
                    county: v_county,
                    district: v_district,
                    address: v_address,
                    number: v_number,
                    complement: v_complement,
                    cep: v_cep
                }, 60000).then((result) => { 

                    global.order.items = [];
                    this.setState({ validando: false, disabledAction: false }, () => {
                        this.props.onConfirm(client.pescpfcnpj, result.orderId);
                    });
                    
                }).catch((error) => {
                    let message = '';
                    let erro = validateERROR(error);
                    if ((erro) && (erro.message) && (erro.message !== '')){ 
                        message = erro.message;
                    } else {
                        message = 'Erro ao confirmar pedido';
                    }

                    this.setState({ validando: false, disabledAction: false }, () => {
                        if ((erro) && (erro.status) && (erro.status === 400)){
                            confirmAlert({
                                title: 'Atenção!',
                                message: message,
                                buttons: [{ label: 'Ok' }],
                                closeOnEscape: false,
                                closeOnClickOutside: false,
                                overlayClassName: "class-alert-livn"
                            })
                        } else {
                            confirmAlert({
                                title: 'Erro ao enviar pedido!',
                                message: message,
                                buttons: [{ label: 'Ok' }],
                                closeOnEscape: false,
                                closeOnClickOutside: false,
                                overlayClassName: "class-alert-livn"
                            }) 
                        }  
                    })
                })
            } else {   
                this.setState({ disabledAction: false });
                let message = 'Loja fechada. Não foi possível enviar o pedido :(';
                if ((result.description) && (result.description !== '')){ 
                    message = `Loja fechada. \nNão foi possível enviar o pedido :(\n${result.description}`;
                }                      
            
                confirmAlert({
                    title: 'Atenção!',
                    message: message,
                    buttons: [{ label: 'Ok' }],
                    closeOnEscape: false,
                    closeOnClickOutside: false,
                    overlayClassName: "class-alert-livn"
                })
            }
        }).catch((error) => { 
            this.setState({ disabledAction: false });

            let message = ''
            let erro = validateERROR(error)
            if ((erro) && (erro.message) && (erro.message !== '')){ 
                message = erro.message
            } else {
                message = 'Não foi possível consultar o status da loja :(';
            }                            
            
            confirmAlert({
                title: 'Erro ao consultar o status da loja!',
                message: message,
                buttons: [{ label: 'Ok' }],
                closeOnEscape: false,
                closeOnClickOutside: false,
                overlayClassName: "class-alert-livn"
            })
        });
        
        
    }

    onRetirada = () => {
        this.setState({
            tipoEntrega: 1,
            zIndexButtomEntrega: 0,
            zIndexButtomRetirada: 99,
            ColorButtomEntrega: '#F3F3F3',
            ColorButtomRetirada: '#FF9800',
            IconColorButtomEntrega: '#737373',
            IconColorButtomRetirada: '#FFF',
        })
    }

    onEntrega = () => {
        this.setState({
            tipoEntrega: 2,
            zIndexButtomEntrega: 99,
            zIndexButtomRetirada: 0,
            ColorButtomEntrega: '#FF9800',
            ColorButtomRetirada: '#F3F3F3',
            IconColorButtomEntrega: '#FFF',
            IconColorButtomRetirada: '#737373'
        })
    }

    render() {
        return ( 
            <Modal
                open={this.props.show}
                disableAutoFocus={true}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <div className='pedido-detail'>
                    {
                        this.state.validando ?
                        <div className='loader-valid'> 
                            <div className='loader-loading'>
                                <Oval
                                    height={20}
                                    width={20}
                                    color="#fff"
                                    wrapperStyle={{}}
                                    wrapperClass=""
                                    visible={true}
                                    ariaLabel='oval-loading'
                                    secondaryColor="#ffb03b"
                                    strokeWidth={5}
                                    strokeWidthSecondary={5}/>
                                <p className='loader-label-loader'>{this.state.labelloader}</p>
                            </div>
                        </div> : <Fragment />
                    }

                    {
                        this.state.showCliente &&  
                        <Cliente 
                            show={this.state.showCliente}
                            counties={this.state.counties}
                            districts={this.state.districts}
                            settings={this.state.settingsperson}
                            valorPedido={this.state.valorPedido}
                            deliverytype={this.state.tipoEntrega}
                            onClose={this.onCloseCliente.bind(this)}
                            onConfirme={this.onConfirmeCliente.bind(this)}/>
                    }

                    {
                        this.state.showPagamento &&  
                        <Pagamento 
                            show={this.state.showPagamento}
                            flags={this.state.flags}
                            operators={this.state.operators}
                            moneytype={this.state.moneytype}
                            paymentmethods={this.state.paymentmethods}
                            deliverytype={this.state.tipoEntrega}
                            valorPedido={this.state.valorPedido + this.state.deliveryFee}
                            onClose={this.onClosePagamento.bind(this)}
                            onConfirme={this.onConfirmePagamento.bind(this)}/>
                    }

                    <div className='pedido-detail-header'>
                        <h2 className='pedido-title'>Resumo do seu pedido</h2>
                        <div className='pedido-detail-close' onClick={() => { this.props.onClose() }}><ArrowBackIosNewIcon/></div>
                    </div>

                    <div className='pedido-detail-entrega'>
                        <div className='pedido-tipo-entrega'>
                            <Button  className='pedido-buttom-entrega' onClick={() => {this.onEntrega()}} style={{
                                zIndex: this.state.zIndexButtomEntrega, 
                                background: this.state.ColorButtomEntrega
                            }}>
                                <MopedOutlinedIcon sx={{ color: this.state.IconColorButtomEntrega }}/>
                                <p className='buttom-entrega-left' style={{color: this.state.IconColorButtomEntrega}}>entrega</p>
                            </Button>
                        </div>
                        <div className='pedido-tipo-entrega'>
                            <Button className='pedido-buttom-retirada' onClick={() => {this.onRetirada()}} style={{
                                zIndex: this.state.zIndexButtomRetirada, 
                                background: this.state.ColorButtomRetirada
                            }}>
                                <p className='buttom-entrega-right' style={{color: this.state.IconColorButtomRetirada}}>retirada</p>
                                <ShoppingBagOutlinedIcon sx={{ color: this.state.IconColorButtomRetirada }}/>
                            </Button>
                        </div>
                    </div>
                    
                    <div className='pedido-detail-container' style={{maxHeight: this.state.clientHeight}}> 
                        
                        {             
                            this.state.items.map((item, index)  => (
                                <div key={index} className='pedido-item'>
                                    <div className='pedido-item-list'>
                                        <p className='pedido-item-amount'>{item.amount} <span className='pedido-detail-amount'> x </span> </p>
                                        <p className='pedido-item-name'>{this.getName(item.name)}</p>
                                        <div className='pedido-item-action' onClick={() => {this.onPressRemove(item, index)}}>
                                            <DeleteOutlinedIcon sx={{ color: '#737373' }}/>
                                        </div>
                                    </div>

                                    {
                                        item.amount > 1 ?
                                        <div className='pedido-item-list'>
                                            <p className='pedido-item-price' style={{display: 'flex'}}>Valor total 
                                                <p className='pedido-item-price-value-default' style={{fontSize: 11, textTransform: 'none', paddingTop: 2}}>{item.amount} x</p>
                                                <p className='pedido-item-price-value-default'>R$ {item.unitPrice.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}</p>
                                                <p className='pedido-item-price-value'>= R$ {(item.amount * item.unitPrice).toLocaleString('pt-BR', { minimumFractionDigits: 2 })}</p>
                                            </p>
                                        </div> :
                                        <div className='pedido-item-list'>
                                            <p className='pedido-item-price' style={{display: 'flex'}}>Valor unitário 
                                                <p className='pedido-item-price-value'>R$ {item.unitPrice.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}</p>
                                            </p>
                                        </div> 
                                    }

                                    {
                                        item.detail && 
                                        item.detail !== '' ?
                                        <div className='pedido-item-list'>
                                            <p className='pedido-item-detail'>{item.detail}</p>
                                        </div> : <Fragment />
                                    }

                                    {                                                
                                        item.note && 
                                        item.note !== '' ?
                                        <div>
                                            <p className='pedido-item-label-note'>Observação</p>
                                            <p className='pedido-item-note'>{item.note}</p>
                                        </div> : <Fragment />
                                    }

                                    {
                                        item.additionalValue && 
                                        item.additionalValue > 0 ?
                                        <div className='pedido-item-list'>
                                            <p className='pedido-item-total'>Total do produto:</p>
                                            <p className='pedido-item-total-value'>R$ {(item.unitPrice + item.additionalValue).toLocaleString('pt-BR', { minimumFractionDigits: 2 })}</p>
                                        </div> : <Fragment />
                                    }

                                </div>                                                    
                            ))                                        
                        }

                    </div>     

                    <div className='pedido-footer'>
                        <div className='pedido-footer-total'>
                            <p className='pedido-footer-total-label'>Total do pedido:</p>
                            <p className='pedido-footer-total-value'>R$ {this.getValor().toLocaleString('pt-BR', { minimumFractionDigits: 2 })}</p>
                        </div>
                        {
                            this.state.enableConfirm ?
                            <Button disabled={this.state.disabledAction} className='pedido-confirm' onClick={() => {this.onConfirmar()}}>
                                <p className='confirme-label'>Confirmar</p>
                            </Button> : 
                            <Button className='pedido-confirm-disabled'>
                                <p className='confirme-label'>Confirmar</p>
                            </Button>
                        }
                    </div>
                </div>
            </Modal> 
        )
    }
}

export default Pedido;
