let global = {
    //URL: 'https://devel.livn.com.br:9199/api/v1/',
    //URL: 'http://192.168.1.205:9199/api/v1/',
    URL: 'https://demo.livn.com.br:9199/api/v1/',
    username: 'cardapio.livn',
    password: 'y<y|8>#m@;mF3]a',
    deviceId: 'catalog_livn', 
    authorization: '',
    deliveryfee: 0,
    operation: 0,
    token: '',
    logo: '',
    open: '',
    close: '',
    weekday: [],
    minimumdeliverytime: 0,
    maximumdeliverytime: 0,
    timereadytopickup: 0,
    description: '',
    address: '',
    uf: '',
    cep: '',
    phone: '',
    cellphone: '',
    countyName: '',
    districtName: '',
    number: '',
    Store: '',
    reference: null,
    order: { items: [] }
}

export { global }
