import React, { Fragment } from 'react';

import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import AddLocationAltOutlinedIcon from '@mui/icons-material/AddLocationAltOutlined';

import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css'; 

import { Oval } from  'react-loader-spinner';
import { global } from '../commons/Globals';
import { GET, validateERROR } from '../commons/Service';
import { Button, Modal, TextField, Select, MenuItem, Autocomplete } from '@mui/material';

import dayjs from 'dayjs';
import Moment from 'moment';
import ptBR from 'dayjs/locale/pt-br';

import TelefoneBrasileiroInput from "react-telefone-brasileiro";

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Endereco from './Endereco';

class Cliente extends React.Component {

    constructor(props) {
        super();
        this.state = {
            clientHeight: window.innerHeight - 150, 
            disabledAction: false,
            permiteeditar: false,
            showEndereco: false,
            validando: false,
            cpfvalidator: '', 
            labelloader: '', 
            nomepessoa: '',
            idcidade: 0,
            idpessoa: 0,
            address: [],

            deliveryFee: 0,
            addressSelect: null,
            cidadeslist: [],   
            bairroslist: [], 
            cidades: [],
            bairros: [],
            campos: [],    
            values: {},
            cpf: ''
        }
    }

    componentDidMount() {
        window.addEventListener('resize', this.handleResizedScreen);

        let cidadeslist = [];
        for (let index = 0; index < this.props.counties.length; index++) {
            const countie = this.props.counties[index];
            let name = `${countie.name} - ${countie.uf}`;            
            let item = cidadeslist.find(({ label }) => label === name);

            if (!item){
                cidadeslist.push({ label: name, id: countie.id });
            }
        }

        this.setState({
            campos: this.props.settings, 
            cidades: this.props.counties,
            bairros: this.props.districts,
            cidadeslist: cidadeslist
        })
    }

    handleResizedScreen = () => {   
        if (this.state.idpessoa > 0 && this.props.deliverytype === 2){
            this.setState({ clientHeight: window.innerHeight - 270 });
        } else {
            this.setState({ clientHeight: window.innerHeight - 150 });
        }     
    };
      
    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResizedScreen);
    }
    
    onChangeCPF = (text) => {
        var cpf = text.replace(/[^0-9]/g,'');
        this.setState({ cpf: cpf }, () => {
            if (cpf.length === 11){
                this.onValidaCPF();
            }
        });
    }

    onBlurCPF = () => {
        this.onValidaCPF();
    }

    onValidaCPF = () => {
        if (this.state.cpf  !== ''){
            if (this.state.cpfvalidator !== this.state.cpf){
                this.setState({ 
                    labelloader: 'Consultado CPF. Aguarde...',
                    cpfvalidator: this.state.cpf,
                    validando: true
                }, () => {

                    GET('document/' + this.state.cpf + '/', 20000).then((response) => {   
                        
                        if (response.client && (response.client.pescodigo > 0)){
                            let address = [];
                            for (let index = 0; index < response.address.length; index++) {
                                const element = response.address[index];
                                element.selected = false;
                                this.onValidaFeeAdress(element);
                                address.push(element);
                            }

                            this.setState({ 
                                validando: false, 
                                address: address,
                                permiteeditar: false, 
                                idpessoa: response.client.pescodigo ,
                                nomepessoa: response.client.pesnomerazao
                            }, () => {
                                this.onLoadCliente(response.client);
                            })
                        } else {
                            this.setState({ validando: false, permiteeditar: true, idpessoa: 0, nomepessoa: '', address: [] }, () => {
                                this.onLoadCliente(null);
                            })
                        }

                    }).catch((error) => {
                        let message = '';
                        let erro = validateERROR(error);
                        if ((erro) && (erro.message) && (erro.message !== '')){ 
                            message = erro.message;
                        } else {
                            message = 'Erro ao validar CPF';
                        }

                        this.setState({ 
                            permiteeditar: false,
                            validando: false, 
                            cpfvalidator: "",
                            nomepessoa: '',
                            idpessoa: 0,
                            address: [],
                            cpf: ""
                        }, () => {
                            this.onLoadCliente(null);
                            if ((erro) && (erro.status) && (erro.status === 400)){                               
                                confirmAlert({
                                    title: 'Atenção!',
                                    message: message,
                                    buttons: [{ label: 'Ok' }],
                                    closeOnEscape: false,
                                    closeOnClickOutside: false,
                                    overlayClassName: "class-alert-livn"
                                }) 
                            } else {       
                                confirmAlert({
                                    title: 'Erro ao validar CPF!',
                                    message: message,
                                    buttons: [{ label: 'Ok' }],
                                    closeOnEscape: false,
                                    closeOnClickOutside: false,
                                    overlayClassName: "class-alert-livn"
                                }) 
                            }  
                        })
                    })

                }) 
            }            
        } else {
            this.setState({ validando: false, permiteeditar: false, idpessoa: 0, nomepessoa: '', address: [] }, () => {
                this.onLoadCliente(null);
            });
        }
    }

    onValidaFeeAdress = (address) => {
        address.deliveryFee = 0;
        address.deliveryValid = false;
        let item = this.state.bairros.find(({ id }) => id === address.district);                 
        if (item && (item.id > 0)){
            address.deliveryValid = true;
            if (this.props.deliverytype === 2){
                if (item.idfee && (item.idfee > 0)){
                    address.deliveryFee = item.fee;
                } else {
                    address.deliveryFee = global.deliveryfee;
                }
            }
        }
    }   

    onLoadCliente = (client) => {
        this.handleResizedScreen();
        let campos = this.state.campos;        
        for (let index = 0; index < campos.length; index++) {
            campos[index].value = null
            campos[index].valuedisplay = null
        }

        if (client && client.pescodigo && (client.pescodigo > 0)){
            for (let index = 0; index < campos.length; index++) {
                const campo = campos[index];
                
                switch(campo.type) {
                    case 3: 
                        if (campo.field !== ''){
                            if (client[campo.field]){
                                campo.value = client[campo.field] 
                                campo.valuedisplay = client[campo.field] 
                            }
                        }
                        break;
                    case 6: 
                        if (campo.field !== ''){
                            if (client[campo.field]){
                                campo.value = client[campo.field] 
                                campo.valuedisplay = client[campo.field] 
                            }
                        }
                        break;
                    case 8: 
                        if (campo.field !== ''){
                            if (campo.field === 'pessexo'){
                                if (client[campo.field]){
                                    campo.value = client[campo.field] 
                                    campo.valuedisplay = client[campo.field].toUpperCase() === "M" ? "MASCULINO" : client[campo.field].toUpperCase() === "F" ? "FEMININO" : ""  
                                }
                            } else {
                                if (client[campo.field]){
                                    campo.value = client[campo.field] 
                                    campo.valuedisplay = client[campo.field].toUpperCase() 
                                }
                            } 
                        }
                        break;
                    case 9: 
                        if (campo.field !== ''){                                
                            if (campo.field === 'pesmunicipio'){
                                if (client[campo.field] && (client[campo.field] > 0)){                                  
                                    let countie = this.state.cidades.find(({ id }) => id === client[campo.field]);
                                    if (countie && (countie.id > 0)){
                                        campo.value = countie.id;
                                        campo.valuedisplay = `${countie.name} - ${countie.uf}`;
                                    }
                                }
                            } else if (campo.field === 'pesbairro'){
                                if (client[campo.field] && (client[campo.field] > 0)){                                    
                                    let obairro = this.state.bairros.find(({ id }) => id === client[campo.field]);
                                    if (obairro && (obairro.id > 0)){
                                        campo.value = obairro.id;
                                        campo.valuedisplay = obairro.name;
                                    } 
                                }
                            }
                            
                        }
                        break;
                }                    
            }
        }
        this.loadDados(client, campos);
    }

    convertLocalTimeToUTCTime = (dateString) => {
        let date = new Date(dateString);    
        return new Date(
            date.getUTCFullYear(),
            date.getUTCMonth(),
            date.getUTCDate(),
            date.getUTCHours(),
            date.getUTCMinutes(),
            date.getUTCSeconds(),
        ).toISOString();
    };

    getName = (texto) => {
        let nameFormat = '';
        if (texto && (texto !== '')){
            const arrayText = texto.split(' ')
            if (arrayText && (arrayText.length > 0)){
                for (let i = 0; i < arrayText.length; i++) {
    
                    let name = arrayText[i].substring(0, 1);
                    name += arrayText[i].substring(1, arrayText[i].length).toLowerCase();
                    nameFormat += ' ' + name;
                }
            }
        }
        return nameFormat;    
    }

    getCEP = (value) => {
        let cepFormat = '';
        if (value && (value !== '')){
            cepFormat = value.replace(/[^0-9]/g,'');
            cepFormat = cepFormat.replace(/\D/g, '')
                .replace(/(\d{5})(\d)/, '$1-$2')
                .replace(/(-\d{3})\d+?$/, '$1')
        }
        return cepFormat;
    }

    onSelectAddress = (item) => {
        let list =  [...this.state.address];
        for (let index = 0; index < list.length; index++) {
            list[index].selected = list[index].id === item.id;
        }
        this.setState({addressSelect: item, address: list, deliveryFee: item.deliveryFee })
    }

    getAddress = (index, address) => {
        if ( (address.county && address.county > 0) && 
             (address.district && address.district > 0) &&
             (address.address && address.address !== '') &&
             (address.number && address.number !== '') &&
             (address.cep && address.cep !== '') ){
            return <div key={index} className='card-address' style={address.selected ? {background: 'rgb(243 243 243 / 51%)'} : {}} onClick={() => { this.onSelectAddress(address) }}>
                <p className='label-address'>{this.getName(address.address)}, {address.number}</p>
                <p className='label-district'>{this.getName(address.districtname)} - {this.getName(address.countyname)}</p>
                <p className='label-cep'>{this.getCEP(address.cep)}</p>
                <div className='view-fee'>
                    {
                        address.deliveryValid ? 
                        <Fragment>                                    
                            <p className='label-fee'>TAXA DE ENTREGA:</p>
                            <p className='label-fee-value'>{address.deliveryFee.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}</p>
                        </Fragment> : 
                        <p className='label-fee-value' style={{color: '#FF6A62', fontSize: 11, marginTop: 6}}>INDISPONÍVEL</p>
                    }
                </div>
                {
                    address.selected ?
                    <div className='address-selected'>
                        <CheckOutlinedIcon sx={{ color: '#fff', fontSize: 24 }}/> 
                    </div> : <Fragment />
                }
            </div>
        } 
        return <Fragment />
    }

    getField = (index, field) => {          
        let _data = '';
        if (field.type === 3){
            if (field.valuedisplay && (field.valuedisplay !== '')){
                _data = field.valuedisplay;
                try {
                    _data = this.convertLocalTimeToUTCTime(_data);
                    _data = Moment(new Date(_data)).format('YYYY-DD-MM');
                } catch(e) {
                    _data = '';   
                };
            }
        }
        
        switch(field.type) {
            case 3: return <div key={index} className='MuiInputDate-div'>
                <LocalizationProvider 
                    adapterLocale={ptBR}  
                    placeholder={field.name}
                    dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['DatePicker', 'DatePicker']}>
                        {
                            field.valuedisplay && field.valuedisplay !== '' ?
                            <DatePicker                         
                                localeText={{
                                    okButtonLabel: 'Confirmar',
                                    cancelButtonLabel: 'Cancelar',                                 
                                    datePickerToolbarTitle: 'Selecione a data'
                                }}  
                                value={dayjs(field.valuedisplay)}
                                defaultValue={dayjs(field.valuedisplay)}
                                disabled={!this.state.permiteeditar}
                                onChange={(newValue) => { 
                                    this.onChangeText(newValue, field) 
                                }} /> :
                            <DatePicker                         
                                localeText={{
                                    okButtonLabel: 'Confirmar',
                                    cancelButtonLabel: 'Cancelar',                                 
                                    datePickerToolbarTitle: 'Selecione a data'
                                }}  
                                value={null}
                                label={field.name}
                                disabled={!this.state.permiteeditar} 
                                onChange={(newValue) => { 
                                    this.onChangeText(newValue, field) 
                                }}/>
                        }
                    </DemoContainer>
                </LocalizationProvider>
            </div>

            case 6: return <div style={{paddingTop: 10}} key={index}>
                {
                    field.field === 'pescelular' ?
                    <div className='MuiFormControl-root MuiFormControl-marginDense MuiFormControl-fullWidth MuiTextField-root css-1z10yd4-MuiFormControl-root-MuiTextField-root'>
                        
                        {
                            field.valuedisplay && field.valuedisplay !== '' ? <Fragment /> :
                            <label class="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-sizeMedium MuiInputLabel-standard MuiFormLabel-colorInfo Mui-disabled MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-sizeMedium MuiInputLabel-standard css-ahcq22-MuiFormLabel-root-MuiInputLabel-root" data-shrink="false" for="textarea-detail" id="textarea-detail-label">
                                {field.name}
                            </label>
                        }

                        <div className='MuiInputBase-root MuiInput-root MuiInput-underline MuiInputBase-colorInfo MuiInputBase-fullWidth MuiInputBase-formControl'>
                            <TelefoneBrasileiroInput
                                separaNono
                                temDDD
                                separaDDD
                                id="textarea-detail-fone"
                                className="MuiInputBase-input"
                                disabled={!this.state.permiteeditar}
                                value={field.valuedisplay ? field.valuedisplay : ""}
                                onChange={(event) => {
                                    this.onChangeText(event.target.value, field)
                                }} />
                        </div>
                    </div> :
                    <TextField
                        variant="standard"
                        label={field.name}
                        id="textarea-detail"
                        margin="dense" 
                        color="info"
                        fullWidth 
                        disabled={!this.state.permiteeditar}
                        required={field.required && (field.required === "S")}
                        value={field.valuedisplay ? field.valuedisplay : ""}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            this.onChangeText(event.target.value, field)
                        }} />  
                }
                </div>

            case 8: return <div style={{paddingTop: 10}} key={index} className='MuiInputSelect-div'> { this.onGetItems(field) } </div>

            case 9: return <div style={{paddingTop: 10}} key={index}> { this.onGetSearch(field) } </div>

            default: return <div key={index}></div>
        }
    }

    onGetItems = (field) => {
        if (field.field === 'pessexo'){
            return ( 
                <Select
                    value={field.value ? field.value : ''}
                    disabled={!this.state.permiteeditar}
                    placeholder={field.name}
                    id="textarea-detail"
                    displayEmpty
                    renderValue={(value) => ( value !== '' ? field.valuedisplay : field.name )}
                    fullWidth onChange={(event) => {
                        this.onChangeText(event.target.value, field)
                    }}>
                    <MenuItem value={"M"}>MASCULINO</MenuItem>
                    <MenuItem value={"F"}>FEMININO</MenuItem>
                </Select>               
            )
        } else if (field.field === 'pesestadocivil'){
            return (    
                <Select
                    value={field.value ? field.value : ''}
                    disabled={!this.state.permiteeditar}
                    placeholder={field.name}
                    id="textarea-detail"
                    displayEmpty
                    renderValue={(value) => ( value !== '' ? field.valuedisplay : field.name )}
                    fullWidth onChange={(event) => {
                        this.onChangeText(event.target.value, field)
                    }}>
                    <MenuItem value={"SOLTEIRO(A)"}>SOLTEIRO(A)</MenuItem>
                    <MenuItem value={"CASADO(A)"}>CASADO(A)</MenuItem>
                    <MenuItem value={"DIVORCIADO(A)"}>DIVORCIADO(A)</MenuItem>
                    <MenuItem value={"VIÚVO(A)"}>VIÚVO(A)</MenuItem>
                </Select>               
            ) 
        } else {
            return ( <Fragment /> )
        }
    }

    onGetSearch = (field) => {
        if (this.state.permiteeditar){
            if (field.field === 'pesmunicipio'){
                return (   
                    <Autocomplete
                        fullWidth 
                        disabled={false}
                        id="textarea-detail"
                        options={this.state.cidadeslist}
                        required={field.required && (field.required === "S")}    
                        renderInput={(params) => <TextField {...params} variant="standard" label={field.name} />}                
                        onChange={(event, newValue) => { this.onSelectCountie(newValue) }} />
                )
            } else if (field.field === 'pesbairro'){
                return (   
                    <Autocomplete
                        fullWidth 
                        id="textarea-detail"
                        value={field.valuedisplay}
                        options={this.state.bairroslist}
                        disabled={this.state.idcidade === 0}
                        required={field.required && (field.required === "S")}
                        renderInput={(params) => <TextField {...params} variant="standard" label={field.name} />}                
                        onChange={(event, newValue) => { this.onSelectDistrict(newValue) }} />
                )
            } else {
                return ( <Fragment /> )
            } 
        } else {
            return (   
                <TextField
                    label={field.name}
                    id="textarea-detail"
                    margin="dense" 
                    color="info"
                    fullWidth 
                    disabled={true}
                    variant="standard"
                    value={field.valuedisplay ? field.valuedisplay : ""}/> 
            )
        }
    }

    onSelectCountie = (cidade) => {
        let campos = this.state.campos; 
        for (let index = 0; index < campos.length; index++) {
            const campo = campos[index]; 
            if (campo.field === 'pesmunicipio'){           
                
                if (cidade && (cidade.id > 0)){
                    campo.value = cidade.id;
                    campo.valuedisplay = cidade.label;
                } else {
                    campo.value = null;
                    campo.valuedisplay = null;
                }

                break;
            }          
        }

        let limparBairro = true;
        if ((this.state.idcidade > 0) && 
            (cidade && (cidade.id > 0)) && 
            (this.state.idcidade === cidade.id)){
            limparBairro = false;
        }  
        if (limparBairro){
            for (let index = 0; index < campos.length; index++) { 
                if (campos[index].field === 'pesbairro'){
                    campos[index].value = null;
                    campos[index].valuedisplay = null;
                    break;
                }
            }
        }    

        if (cidade && (cidade.id > 0)){
            let lista = this.state.bairros.filter((bairro) => { return bairro.county === cidade.id });
            
            let bairroslist = [];
            for (let index = 0; index < lista.length; index++) {
                const district = lista[index];           
                let item = bairroslist.find(({ label }) => label === district.name);

                if (!item){
                    bairroslist.push({ label: district.name, id: district.id });
                }
            }

            this.setState({ bairroslist: bairroslist, idcidade: cidade.id });
        } else {
            this.setState({ bairroslist: [], idcidade: 0 });
        }
        this.loadDados(null, campos);
    }

    onSelectDistrict = (bairro) => {
        let campos = this.state.campos; 
        for (let index = 0; index < campos.length; index++) {
            const campo = campos[index]; 
            if (campo.field === 'pesbairro'){           
                
                if (bairro && (bairro.id > 0)){
                    campo.value = bairro.id;
                    campo.valuedisplay = bairro.label;
                } else {
                    campo.value = null;
                    campo.valuedisplay = null;
                }

                break;
            }          
        }
        this.loadDados(null, campos);
    }

    getBorderColor = (field) => {
        if (this.state.permiteeditar){
            if (field.required === 'S'){
                if (field.valuedisplay && field.valuedisplay !== ''){
                    return '#b3ddff';
                } else {
                    return '#FF5722';
                }
            } else {
                return '#00000042';
            }
        } else {
            return '#00000042';
        }
    }

    onChangeText = (value, field) => {
        let campos = this.state.campos; 
        for (let index = 0; index < campos.length; index++) {
            const campo = campos[index]; 
            if (campo.id === field.id){           
                campo.value = value;

                if (field.field === 'pessexo'){
                    campo.valuedisplay = '';
                    if (value === 'M'){
                        campo.valuedisplay = 'MASCULINO'
                    } else if (value === 'F'){
                        campo.valuedisplay = 'FEMININO'
                    }
                } else {
                    campo.valuedisplay = value;
                    try {
                        campo.valuedisplay = campo.valuedisplay.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
                    } catch (error) {}
                }

                break;
            }          
        }

        this.loadDados(null, campos);
    }

    loadDados = (ocliente, campos) => {
        let dados = {};
        
        if (ocliente && (ocliente.pescodigo > 0)){
            dados['pescodigo'] = ocliente.pescodigo;
        } else {
            dados['pescodigo'] = null;
        }
        
        for (let index = 0; index < campos.length; index++) { 
            if (campos[index].type === 3){
                if (campos[index].value){
                    dados[campos[index].field] = new Date(campos[index].value);
                } else {
                    dados[campos[index].field] = '';
                }
            } else {
                dados[campos[index].field] = campos[index].value;
            }
        }        
        this.setState({ campos: campos, values: dados });
    }

    onConfirmar = () => {
        if (!this.state.disabledAction){            
            this.setState({disabledAction: true}, () => {            

                let valido = true;
                let endereco = {
                    county: 0, 
                    district: 0,
                    deliveryFee: 0,
                    address: '',
                    number: '',
                    complement: '',
                    cep: ''
                }

                if (this.state.cpf === ''){
                    valido = false;
                    confirmAlert({
                        title: 'Atenção!',
                        message: 'CPF não informado.',
                        buttons: [{ label: 'Ok' }],
                        closeOnEscape: false,
                        closeOnClickOutside: false,
                        overlayClassName: "class-alert-livn"
                    })
                } else if (this.state.idpessoa > 0){
                    if (this.state.addressSelect){
                            
                        let countie = this.state.cidades.find(({ id }) => id === this.state.addressSelect.county);
                        if (countie && (countie.id > 0)){

                            endereco.county = this.state.addressSelect.county; 
                            endereco.district = this.state.addressSelect.district;
                            endereco.address = this.state.addressSelect.address;
                            endereco.number = this.state.addressSelect.number;
                            endereco.complement = this.state.addressSelect.complement;
                            endereco.cep = this.state.addressSelect.cep;
                            if (this.props.deliverytype === 2){
                                endereco.deliveryFee = this.state.addressSelect.deliveryFee;
                            }
                            
                        } else {
                            valido = false;
                            confirmAlert({
                                title: 'Atenção!',
                                message: `Endereço selecionado não é válido.\n\nMunicípio: ${this.state.addressSelect.countyname} não é valido para entrega!`,
                                buttons: [{ label: 'Ok' }],
                                closeOnEscape: false,
                                closeOnClickOutside: false,
                                overlayClassName: "class-alert-livn"
                            })
                        }

                    } else if (this.props.deliverytype === 2) {
                        valido = false;
                        confirmAlert({
                            title: 'Atenção!',
                            message: 'Endereço não selecionado.',
                            buttons: [{ label: 'Ok' }],
                            closeOnEscape: false,
                            closeOnClickOutside: false,
                            overlayClassName: "class-alert-livn"
                        })
                    }
                } 
                
                if (valido){
                    valido = false;
                    let mensagem = '';
                    if (this.state.values['pescodigo'] && (this.state.values['pescodigo'] > 0)){
                        valido = true;
                    } else {                
                        mensagem = this.validaOnObrigatorios();
                        valido = mensagem === '';
                    }

                    if (valido && this.state.idpessoa === 0){
                        let fone = this.state.values['pescelular'];  
                        if (fone && fone !== ''){
                            var numberFone = fone.replace(/[^0-9]/g,'');
                            if (numberFone.length < 10){
                                valido = false;
                                confirmAlert({
                                    title: 'Atenção!',
                                    message: 'Número do celular informado é inválido.',
                                    buttons: [{ label: 'Ok' }],
                                    closeOnEscape: false,
                                    closeOnClickOutside: false,
                                    overlayClassName: "class-alert-livn"
                                }) 
                            } 
                        } 
                    }

                    if (valido){
                        let values = this.state.values;
                        const json = JSON.stringify(this.state.campos);
                        let campos = JSON.parse(json);

                        values['pescpfcnpj'] = this.state.cpf;
                        campos.push({
                            "id": 0,
                            "column": 95,
                            "field": "pescpfcnpj",
                            "name": "CPF/CNPJ",
                            "type": 6,
                            "required": "N",
                            "group": 0,
                            "table": "pessoa"
                        });

                        if (this.state.idpessoa === 0){
                            
                            if (this.state.values['pesmunicipio'] && (this.state.values['pesmunicipio'] > 0)){
                                endereco.county = this.state.values['pesmunicipio'];
                            } 
                            if (this.state.values['pesbairro'] && (this.state.values['pesbairro'] > 0)){
                                endereco.district = this.state.values['pesbairro'];

                                if (this.props.deliverytype === 2){
                                    let item = this.state.bairros.find(({ id }) => id === this.state.values['pesbairro']);                 
                                    if (item && (item.id > 0)){
                                        if (item.idfee && (item.idfee > 0)){
                                            endereco.deliveryFee = item.fee;
                                        } else {
                                            endereco.deliveryFee = global.deliveryfee;
                                        }
                                    }
                                }
                            } 
                            if (this.state.values['pesendereco'] && (this.state.values['pesendereco'] !== '')){
                                endereco.address = this.state.values['pesendereco'];
                                try {
                                    endereco.address = endereco.address.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
                                } catch (error) {}
                            } 
                            if (this.state.values['pesnumero'] && (this.state.values['pesnumero'] !== '')){
                                endereco.number = this.state.values['pesnumero'];
                                try {
                                    endereco.number = endereco.number.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
                                } catch (error) {}
                            } 
                            if (this.state.values['pescomplemento'] && (this.state.values['pescomplemento'] !== '')){
                                endereco.complement = this.state.values['pescomplemento'];
                                try {
                                    endereco.complement = endereco.complement.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
                                } catch (error) {}
                            } 
                            if (this.state.values['pescep'] && (this.state.values['pescep'] !== '')){
                                endereco.cep = this.state.values['pescep'];
                                try {
                                    endereco.cep = endereco.cep.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
                                } catch (error) {}
                            } 

                        }

                        this.props.onConfirme(values, campos, endereco);

                    } else {
                        this.setState({disabledAction: false}, () => {
                            if (mensagem !== ''){
                                confirmAlert({
                                    title: 'Atenção!',
                                    message: `Informações obrigatórias não preenchidas: \n${mensagem}`,
                                    buttons: [{ label: 'Ok' }],
                                    closeOnEscape: false,
                                    closeOnClickOutside: false,
                                    overlayClassName: "class-alert-livn"
                                })
                            }
                        })
                    }
                } else {
                    this.setState({disabledAction: false})
                }
            })
        }
    }

    validaOnObrigatorios = () => {
        let mensagem = '';
        for (let index = 0; index < this.state.campos.length; index++) { 
            if ((!this.state.campos[index].value) && (this.state.campos[index].required === 'S')){
                mensagem += ` » ` + this.state.campos[index].name + `\n`;
            }
        } 
        return mensagem;
    }

    onCloseEndereco = () => {
        this.setState({ showEndereco: false });
    }

    onConfirmeEndereco = (values) => {
        let address = [];
        for (let index = 0; index < values.length; index++) {
            const element = values[index];
            element.selected = false;
            this.onValidaFeeAdress(element);
            address.push(element);
        }        
        this.setState({showEndereco: false, address: address })       
    }  

    render() {
        return ( 
            <Modal
                open={this.props.show}
                disableAutoFocus={true}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <div className='pedido-detail'>
                    {
                        this.state.validando ?
                        <div className='loader-valid'> 
                            <div className='loader-loading'>
                                <Oval
                                    height={20}
                                    width={20}
                                    color="#fff"
                                    wrapperStyle={{}}
                                    wrapperClass=""
                                    visible={true}
                                    ariaLabel='oval-loading'
                                    secondaryColor="#ffb03b"
                                    strokeWidth={5}
                                    strokeWidthSecondary={5}/>
                                <p className='loader-label-loader'>{this.state.labelloader}</p>
                            </div>
                        </div> : <Fragment />
                    }

                    <div className='pedido-detail-header' style={{marginBottom: 0}}>
                        {
                            this.state.idpessoa > 0 && 
                            this.props.deliverytype === 2 ? 
                            <h2 className='pedido-title'>Selecione seu endereço</h2> :
                            <h2 className='pedido-title'>Informe seus dados</h2> 
                        }                        
                        <div className='pedido-detail-close' onClick={() => { this.props.onClose() }}><ArrowBackIosNewIcon/></div>
                    </div>

                    {
                        this.state.showEndereco &&  
                        <Endereco 
                            personId={this.state.idpessoa}
                            show={this.state.showEndereco}
                            counties={this.props.counties}
                            districts={this.props.districts}
                            onClose={this.onCloseEndereco.bind(this)}
                            onConfirme={this.onConfirmeEndereco.bind(this)}/>
                    }
                    
                    <div className='pedido-detail-container' style={{maxHeight: this.state.clientHeight}}> 
                        <div className='pedido-detail-document'>
                            <TextField
                                inputProps = {{ autoFocus: true }}
                                variant="standard"
                                disabled={this.state.validando}
                                label="Informe seu CPF"
                                id="textarea-comand"
                                margin="normal" 
                                color="warning"
                                fullWidth 
                                type="number"
                                value={this.state.cpf}
                                onBlur={() => { this.onBlurCPF() }}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    this.onChangeCPF(event.target.value);
                                }} /> 
                        </div>


                        {
                            this.state.idpessoa > 0 && 
                            this.props.deliverytype === 2 ?
                            <Fragment>

                                {
                                    this.state.nomepessoa && 
                                    this.state.nomepessoa !== '' ?
                                    <div className='div-person'>
                                        <span className='span-name-person'>Olá, </span>
                                        <p className='label-name-person'>{this.state.nomepessoa}</p>
                                    </div> : <Fragment />
                                }
                                
                                <Button className='buttom-novo-endereco' onClick={() => { this.setState({ showEndereco: true }) }}>
                                    <AddLocationAltOutlinedIcon style={{color: '#5046BB', fontSize: 21}}/>
                                    <p>Adicionar novo endereço</p>
                                </Button>

                                {
                                    this.state.address.map((address, index) => {
                                        return ( this.getAddress(index, address) )
                                    })
                                }    
                            </Fragment> : 
                            <Fragment>
                                <div className='dados-divider'/> 
                                { 
                                    this.state.campos && 
                                    this.state.campos.length > 0 ?
                                    this.state.campos.map((field, index) => {
                                        return ( this.getField(index, field) )
                                    }) : <Fragment/>
                                }
                            </Fragment>
                        }
                    </div>           

                    <div className='cliente-footer' style={this.state.idpessoa > 0 && this.props.deliverytype === 2 ? {height: 208} : {}}>
                        {                                
                            this.state.idpessoa > 0 && 
                            this.props.deliverytype === 2 ?
                            <div>
                                <div className='cliente-footer-total'>
                                    <p className='cliente-footer-label'>Valor dos produtos:</p>
                                    <p className='cliente-footer-value'>R$ {this.props.valorPedido.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}</p>
                                </div>
                                
                                <div className='cliente-footer-total'>
                                    <p className='cliente-footer-label'>Taxa de entrega:</p>
                                    <p className='cliente-footer-value' style={{color: '#5c52bf'}}>R$ {this.state.deliveryFee.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}</p>
                                </div>

                                <div className='cliente-footer-total'>
                                    <p className='cliente-footer-total-label'>Total do pedido:</p>
                                    <p className='cliente-footer-total-value'>R$ {(this.props.valorPedido + this.state.deliveryFee).toLocaleString('pt-BR', { minimumFractionDigits: 2 })}</p>
                                </div>

                                <Button disabled={this.state.disabledAction} className='cliente-confirm' onClick={() => {this.onConfirmar()}}>
                                    <p className='confirme-label'>Confirmar endereço</p>
                                </Button>
                            </div> : 
                            <Button disabled={this.state.disabledAction} className='cliente-confirm' onClick={() => {this.onConfirmar()}}>
                                <p className='confirme-label'>Confirmar dados</p>
                            </Button>
                        }
                    </div>
                </div>
            </Modal> 
        )
    }
}

export default Cliente;
