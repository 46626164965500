import React, { Fragment } from 'react';

import Moment from 'moment';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent, { timelineOppositeContentClasses } from '@mui/lab/TimelineOppositeContent';

import { compositionEditable, getCompositionAdditional, getValue } from '../commons/Service.Product';

import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import MopedOutlinedIcon from '@mui/icons-material/MopedOutlined';
import ShoppingBagOutlinedIcon from '@mui/icons-material/ShoppingBagOutlined';
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';

import 'react-confirm-alert/src/react-confirm-alert.css'; 

import { GET } from '../commons/Service';
import { Modal } from '@mui/material';


class PedidoDetalhe extends React.Component {

    constructor(props) {
        super();
        this.state = {
            clientHeight: window.innerHeight - 60, 
            showLoaderStatus: false,
            orderStatusId: 999,
            orderValue: 0,
            order: null, 
            events: [],
            items: []
        }
    }

    componentDidMount() {
        window.addEventListener('resize', this.handleResizedScreen);

        let items = [];
        let orderValue = 0;
        if (this.props.order.data && this.props.order.data !== ''){
            let orderItens = JSON.parse(this.props.order.data);
            if (orderItens && orderItens.order && orderItens.order.items && orderItens.order.items.length > 0){
                items = orderItens.order.items;
                orderValue = getValue(items);
                for (let I = 0; I < items.length; I++) {
                    this.onGetDetalhes(items[I], items[I].type === 2, items[I].type === 3);
                } 
            }
        }  
        if (this.props.order.fee && this.props.order.fee > 0){
            orderValue += this.props.order.fee;
        }
        
        this.setState({ showLoaderStatus: true, order: this.props.order, items: items, orderValue: orderValue }, () => {
            this.onGetTimeline(this.props.order.id);           

            if ((this.props.order.statusId !== 3) && (this.props.order.statusId !== 7)){
                if (this.interval > 0){
                    clearInterval(this.interval);
                }
                this.interval = setInterval(() => {                
                    this.onGetTimeline(this.props.order.id);
                }, 10000)
            }
        })
    }

    handleResizedScreen = () => {   
        this.setState({ clientHeight: window.innerHeight - 60 });   
    };
      
    componentWillUnmount() {
        clearInterval(this.interval);
        window.removeEventListener('resize', this.handleResizedScreen);
    }

    onGetDetalhes = (item, hekit, hePizza) => {
        if ( item && item.structure &&
            (item.manufactured === 'S') && 
            (item.manufacturedAntecipated === 'N') && 
            (compositionEditable(item.structure)) ){
            let detalhes = getCompositionAdditional(item.structure, hekit, hePizza);
            item.detail = detalhes;
        } else {
            item.detail = '';
        }
    }
    
    getName = (texto) => {
        let nameFormat = '';
        if (texto && (texto !== '')){
            const arrayText = texto.split(' ')
            if (arrayText && (arrayText.length > 0)){
                for (let i = 0; i < arrayText.length; i++) {
    
                    let name = arrayText[i].substring(0, 1);
                    name += arrayText[i].substring(1, arrayText[i].length).toLowerCase();
                    nameFormat += ' ' + name;
                }
            }
        }
        return nameFormat;    
    }

    getDateOrder = () => {
        let dateFormat = '';
        if (this.state.order.creationdate && this.state.order.creationdate !== ''){
            let date = new Date(this.state.order.creationdate);
            dateFormat = Moment(date).format('DD/MM/YYYY HH:mm:ss');
        }
        return dateFormat;
    }

    onGetPaymentDetail = (operationType) => {
        let paymentDetail = '';
        if (operationType === "C"){
            paymentDetail = 'Crédito'
        } else if (operationType === "D"){
            paymentDetail = 'Débito'
        } else if (operationType === "V"){
            paymentDetail = 'Voucher'
        } else if (operationType === "F"){
            paymentDetail = 'Frota'
        }
        return paymentDetail;
    }

    getCEP = (value) => {
        let cepFormat = '';
        if (value && (value !== '')){
            cepFormat = value.replace(/[^0-9]/g,'');
            cepFormat = cepFormat.replace(/\D/g, '')
                .replace(/(\d{5})(\d)/, '$1-$2')
                .replace(/(-\d{3})\d+?$/, '$1')
        }
        return cepFormat;
    }

    onGetStatusOrder = (statusId) => {  
        let statusName = '';      
        switch(statusId) {
            case 999: statusName = "CONSULTANDO"; break;
            case 99: statusName = "PEDIDO ENVIADO"; break;
            case 1: statusName = "AGUARDANDO"; break;
            case 2: statusName = "CONFIRMADO"; break;
            case 3: statusName = "CANCELADO"; break;
            case 4: statusName = "PREPARANDO"; break;
            case 5: statusName = "PRONTO"; break;
            case 6: statusName = "SAIU PARA ENTREGA"; break;
            case 7: statusName = "FINALIZADO"; break;
        }
        return statusName;
    }

    onGetStatusTime = (date) => {
        let dateFormat = '';
        if (date && date !== ''){
            let data = new Date(date);
            dateFormat = Moment(date).format('DD/MM/YYYY HH:mm:ss');
        }
        return dateFormat;
    }

    onStatusProgress = () => {  
        let statusProgress = true;      
        switch(this.state.orderStatusId) {
            case 3: statusProgress = false; break;
            case 7: statusProgress = false; break;
        }
        if (statusProgress){
            return (
                <div className='div-status-order'>
                    <div className='div-progress-order'>
                        <LinearProgress sx={{'& .MuiLinearProgress-bar': {backgroundColor: '#3f51b5'} }}/>
                    </div>
                </div>
            )
        }
        return  <Fragment />
    }

    onGetTimeline = (OrderId) => {
        GET('order/timeline/' + OrderId, 20000).then((response)=> {

            const interval = setInterval(() => {                
                if (response && response.id > 0){ 
                    
                    let events = [{
                        id: 0,
                        orderId: this.state.order.id,
                        statusId: 99,
                        at: this.state.order.creationdate
                    }];

                    for (let index = 0; index < response.events.length; index++) {
                        events.push(response.events[index]);                        
                    }
                    
                    clearInterval(interval);               
                    this.setState({ 
                        events: events,
                        showLoaderStatus: false,
                        orderStatusId: response.statusId > 0 ? response.statusId : 99 
                    })
                }
            }, 1000)

        }).catch((error) => { })
    }

    renderItem = (item, index) => {
        return (
            <div key={index} className='pedido-item'>
                <div className='pedido-item-list'>
                    <p className='pedido-item-amount'>{item.amount} <span className='pedido-detail-amount'> x </span> </p>
                    <p className='pedido-item-name'>{this.getName(item.name)}</p>
                </div>

                {
                    item.amount > 1 ?
                    <div className='pedido-item-list'>
                        <p className='pedido-item-price' style={{display: 'flex'}}>Valor total 
                            <p className='pedido-item-price-value-default' style={{fontSize: 11, textTransform: 'none', paddingTop: 2}}>{item.amount} x</p>
                            <p className='pedido-item-price-value-default'>R$ {item.unitPrice.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}</p>
                            <p className='pedido-item-price-value'>= R$ {(item.amount * item.unitPrice).toLocaleString('pt-BR', { minimumFractionDigits: 2 })}</p>
                        </p>
                    </div> :
                    <div className='pedido-item-list'>
                        <p className='pedido-item-price' style={{display: 'flex'}}>Valor unitário 
                            <p className='pedido-item-price-value'>R$ {item.unitPrice.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}</p>
                        </p>
                    </div> 
                }

                {
                    item.detail && 
                    item.detail !== '' ?
                    <div className='pedido-item-list'>
                        <p className='pedido-item-detail'>{item.detail}</p>
                    </div> : <Fragment />
                }

                {                                                
                    item.note && 
                    item.note !== '' ?
                    <div>
                        <p className='pedido-item-label-note'>Observação</p>
                        <p className='pedido-item-note'>{item.note}</p>
                    </div> : <Fragment />
                }

                {
                    item.additionalValue && 
                    item.additionalValue > 0 ?
                    <div className='pedido-item-list'>
                        <p className='pedido-item-total'>Total do produto:</p>
                        <p className='pedido-item-total-value'>R$ {(item.unitPrice + item.additionalValue).toLocaleString('pt-BR', { minimumFractionDigits: 2 })}</p>
                    </div> : <Fragment />
                }

            </div> 
        )
    }

    render() {
        return ( 
            <Modal
                open={this.props.show}
                disableAutoFocus={true}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <div className='pedido-detail'>
                    {
                        this.state.order && 
                        this.state.order.id > 0 ? 
                        <Fragment>
                            <div className='pedido-detail-header' style={{marginBottom: 0}}>
                                <h2 className='pedido-title'>Detalhes do pedido #{this.state.order.id}</h2>
                                <div className='pedido-detail-close' onClick={() => { this.props.onClose() }}><ArrowBackIosNewIcon/></div>
                            </div>
                            
                            <div className='pedido-detail-container' style={{maxHeight: this.state.clientHeight}}> 
                                <div className='div-pedido-historico'>
                                    <div className='div-pedido-justify-content'>
                                        <p className='label-pedido-orderId'>PEDIDO <span className='span-pedido-orderId'>#{this.state.order.id}</span></p>
                                        <div className='div-pedido-deliveryType'>
                                        {
                                            this.state.order.deliveryType === 2 ?
                                            <MopedOutlinedIcon sx={{fontSize: 17, color: '#3f51b5'}}/> :
                                            <ShoppingBagOutlinedIcon sx={{fontSize: 17, color: '#3f51b5'}}/>
                                        }
                                        </div>
                                    </div>

                                    <div className='div-pedido-justify-content' style={{marginTop: 5}}>
                                        <p className='label-pedido-date'>{this.getDateOrder()}</p>
                                        <p className='label-pedido-value-detail'>R$ {this.state.orderValue.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}</p>
                                    </div>

                                    {
                                        this.state.orderStatusId > 0 ?
                                        <Fragment>
                                            <div className='div-status-orderId'>
                                                {
                                                    this.state.orderStatusId === 3 ?
                                                    <p className='label-status-orderId' style={{color: '#d32f2f', backgroundColor: '#d32f2f38'}}>{this.onGetStatusOrder(this.state.orderStatusId)}</p> : 
                                                    <Fragment>
                                                        {
                                                            this.state.orderStatusId === 7 ?
                                                            <p className='label-status-orderId' style={{color: '#4caf50', backgroundColor: '#4caf503b'}}>{this.onGetStatusOrder(this.state.orderStatusId)}</p> :
                                                            <p className='label-status-orderId' style={{color: '#3f51b5', backgroundColor: '#3f51b533'}}>{this.onGetStatusOrder(this.state.orderStatusId)}</p>
                                                        }
                                                    </Fragment>
                                                }
                                            </div>

                                            { this.onStatusProgress() }

                                            {
                                                this.state.events.length > 0 ?
                                                <Timeline sx={{[`& .${timelineOppositeContentClasses.root}`]: {flex: 0, padding: 0} }}>
                                                    {
                                                        this.state.events.map((event, index)  => (                                                            
                                                            <TimelineItem className='order-timeline-item'>
                                                                <TimelineOppositeContent />
                                                                <TimelineSeparator className='order-timeline-separator'>
                                                                    <TimelineDot variant="outlined" sx={{
                                                                        borderColor: 
                                                                        index === 0 ? '#5046bb' : 
                                                                                event.statusId === 3 ? '#f43127' : 
                                                                                    event.statusId === 7 ? '#4caf50' : '#bdb7b7'
                                                                    }} />
                                                                    {
                                                                        index === (this.state.events.length - 1) ? 
                                                                        <Fragment>
                                                                            {
                                                                                event.statusId === 3 ||  event.statusId === 7 ? <Fragment /> : 
                                                                                <TimelineConnector />
                                                                            }
                                                                        </Fragment> : <TimelineConnector />
                                                                    }                                                                    
                                                                </TimelineSeparator>
                                                                <TimelineContent className='order-timeline-content'>
                                                                    {this.onGetStatusOrder(event.statusId)}
                                                                    <span className='order-timeline-content-span'>
                                                                        {this.onGetStatusTime(event.at)}
                                                                    </span>
                                                                </TimelineContent>
                                                            </TimelineItem>     

                                                        )) 
                                                    }
                                                </Timeline> : <Fragment />
                                            }
                                        </Fragment> : <Fragment />
                                    }
                                    
                                    {
                                        this.state.showLoaderStatus ?
                                        <div className='div-progress-status'>
                                            <CircularProgress size={15} sx={{'& .MuiLinearProgress-bar': {backgroundColor: '#3f51b5'}}} />
                                            <p className='label-progress-status'>Consultando status do pedido</p>
                                        </div> : <Fragment />
                                    }
                                </div>

                                <div className='div-order-product'>
                                    <p className='label-order-product'>Produtos</p>

                                    <div>
                                    {             
                                        this.state.items.map((item, index)  => (
                                            this.renderItem(item, index)                                                  
                                        ))                                        
                                    }
                                    </div>
                                </div>

                                {
                                    this.state.order.deliveryType === 2 ?
                                    <div className='div-order-address'>
                                        <p className='label-order-address'>Endereço</p>

                                        <div className='card-address' style={{marginLeft: 0}}>
                                            <p className='label-address'>{this.getName(this.state.order.address)}, {this.state.order.number}</p>
                                            <p className='label-district'>{this.getName(this.state.order.districtname)} - {this.getName(this.state.order.countyname)}</p>
                                            <p className='label-cep'>{this.getCEP(this.state.order.cep)}</p>
                                            <div className='view-fee'>
                                                {
                                                    this.state.order.fee && 
                                                    this.state.order.fee > 0 ? 
                                                    <Fragment>                                    
                                                        <p className='label-fee'>TAXA DE ENTREGA:</p>
                                                        <p className='label-fee-value'>{this.state.order.fee.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}</p>
                                                    </Fragment> : <Fragment />
                                                }
                                            </div>
                                        </div>

                                    </div> : <Fragment />
                                }

                                {
                                    this.state.order.payment && 
                                    this.state.order.payment > 0 ? 
                                    <div className='div-order-payment'>
                                        <p className='label-order-payment'>Pagamentos</p>

                                        <div className='pedido-item' style={{display: 'flex'}}>
                                            <p className='pedido-payment-name' style={{marginLeft: 0}}>{this.getName(this.state.order.paymentname)}</p>
                                            {
                                                this.state.order.cardType && 
                                                this.state.order.cardType !== '' ?
                                                <p className='pedido-payment-name' style={{marginLeft: 5}}>- {this.onGetPaymentDetail(this.state.order.cardType)}</p>: <Fragment />
                                            }

                                            {
                                                this.state.order.bandImage && 
                                                this.state.order.bandImage !== '' ?
                                                <Fragment>
                                                    <img className='flag-image-detail' src={`data:image/png;base64,${this.state.order.bandImage}`}/>
                                                    {
                                                        this.state.order.bandName && 
                                                        this.state.order.bandName !== '' ?
                                                        <p className='pedido-payment-name' style={{marginLeft: 5}}>{this.getName(this.state.order.bandName)}</p> : <Fragment />
                                                    }
                                                </Fragment> : 
                                                <Fragment>
                                                    {
                                                        this.state.order.bandName && 
                                                        this.state.order.bandName !== '' ?
                                                        <p className='pedido-payment-name' style={{marginLeft: 5}}>- {this.getName(this.state.order.bandName)}</p> : <Fragment />
                                                    }
                                                </Fragment>
                                            }                                            

                                            {
                                                this.state.order.paymentValue &&
                                                this.state.order.paymentValue > 0 && 
                                                this.state.order.paymentValue > (this.state.orderValue + this.state.order.fee) ? 
                                                <p className='pedido-detail-payment'>Troco para {this.state.order.paymentValue.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}</p> : <Fragment/>
                                            }
                                        </div>
                                        
                                    </div> : <Fragment />
                                }
                            </div>
                        </Fragment> : <Fragment />
                    }

                </div>
            </Modal> 
        )
    }
}

export default PedidoDetalhe;
