import React, { Fragment } from 'react';

import { Dialog, DialogTitle, DialogActions, DialogContent, Button } from '@mui/material';
import RemoveIcon from '@mui/icons-material/Remove';
import Checkbox from '@mui/material/Checkbox';
import AddIcon from '@mui/icons-material/Add';

import {
    getComponentManufactured, 
    compositionEditable, 
    onConfirmeStructure,
    onConfirmePizza
} from '../commons/Service.Product';
import Composicao from './Composicao';
import Pizza from './Pizza';

class Variavel extends React.Component {
    constructor(props) {
        super();
        this.state = {
            clientHeight: window.innerHeight - 60,
            items: [], 
            quantity: 0,
            variable: null, 
            itemSelected: null,
            product: null,
            pizza: null,
            permiteAdd: false,
            permiteConfirmar: false,
            showStructure: false, 
            tempadrao: false,
            temopcional: false,
            showPizza: false,  
            heCheckBox: false
        }
    }

    componentDidMount() {
        window.addEventListener('resize', this.handleResizedScreen);

        let heCheckBox = false;
        if ( (this.props.variable.requerid === 'S') && (this.props.variable.quantity === 1) ){

            if  (!(typeof this.props.variable.maximum === 'undefined')){
                heCheckBox = ( (this.props.variable.maximum === 'S') && (this.props.variable.quantitymaximum === 1) )   
            } else {
                heCheckBox = true  
            }

        } else if (!(typeof this.props.variable.maximum === 'undefined')){
            heCheckBox = ( (this.props.variable.maximum === 'S') && (this.props.variable.quantitymaximum === 1) ) 
        }

        this.setState({ 
            heCheckBox: heCheckBox,
            permiteAdd: !heCheckBox,
            variable: this.props.variable, 
            items: this.props.variable.components 
        })
    }

    handleResizedScreen = () => {        
        this.setState({ clientHeight: window.innerHeight - 60 });
    };
      
    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResizedScreen);
    }

    handleCancel = () => {
        this.props.onCancel();
    }

    handleOk = () => {
        if (this.state.heCheckBox){                         
            for (let index = 0; index < this.state.variable.components.length; index++) {
                const element = this.state.variable.components[index];
                if (element.id === this.state.itemSelected.id){
                    element.selected = 1;
                    break;
                }                    
            }
            this.state.variable.selected = 1;
            this.props.onConfirme(this.state.variable)

        } else {
            for (let index = 0; index < this.state.variable.components.length; index++) {
                const element = this.state.variable.components[index];                    
                const item = this.state.items.filter(item => { return item.id === element.id })
                if (item && (item.length > 0) && (item[0].id > 0)){
                    element.selected = item[0].selected
                }
            }
            this.state.variable.selected = 1;
            this.props.onConfirme(this.state.variable)
        }
    }

    onPressItem = (item) => {
        if (this.state.heCheckBox){  
            if (item.component.type === 3){
                this.setState({ 
                    pizza: getComponentManufactured(item.component, item.value, item.id, false), 
                    itemSelected: item, 
                    showPizza: true,
                    quantity: 1
                }) 

            } else if ( (item.component.manufactured === 'S') && 
                        (item.component.manufacturedAntecipated === 'N') && 
                        (compositionEditable(item.component.structure)) ){                
                
                let tempadrao = false;
                let temopcional = false;

                for (let index = 0; index < item.component.structure.length; index++) {
                    const element = item.component.structure[index];
                    if (element.default && (element.default === 'S')){
                        tempadrao = true
                    } else {
                        temopcional = true
                    }

                    if (tempadrao && temopcional){
                        break;
                    }
                }

                let product = getComponentManufactured(item.component, item.value, item.id, false);
                product.valor = `R$ ${product.unitPrice.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}`;

                this.setState({ 
                    product: product, 
                    itemSelected: item, 
                    temopcional: temopcional,
                    tempadrao: tempadrao,
                    showStructure: true,
                    quantity: 1
                })

            } else {
                this.setState({ itemSelected: item, quantity: 1 }, () => { 
                    this.onPermiteConfirmar();
                    this.onPermiteAdd();
                })  
            }                 
        }   
    }

    onPressAdd = (item) => {
        if (item.component.type === 3){
            this.setState({ 
                pizza: getComponentManufactured(item.component, item.value, item.id, false), 
                showPizza: true
            })   

        } else if ( (item.component.manufactured === 'S') && 
                    (item.component.manufacturedAntecipated === 'N') && 
                    (compositionEditable(item.component.structure)) ){

            let tempadrao = false;
            let temopcional = false;

            for (let index = 0; index < item.component.structure.length; index++) {
                const element = item.component.structure[index];
                if (element.default && (element.default === 'S')){
                    tempadrao = true
                } else {
                    temopcional = true
                }

                if (tempadrao && temopcional){
                    break;
                }
            }

            let product = getComponentManufactured(item.component, item.value, item.id, false);
            product.valor = `R$ ${product.unitPrice.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}`;
            this.setState({ 
                product: product, 
                temopcional: temopcional,
                tempadrao: tempadrao,
                showStructure: true
            })

        } else {
            let quantity = 0
            let lista = [...this.state.items]
            for (let index = 0; index < lista.length; index++) {
                const element = lista[index];
                if (element.id === item.id){
                    element.selected += 1
                }
                quantity += element.selected
            }
            this.setState({ quantity: quantity, items: lista }, () => { 
                this.onPermiteConfirmar();
                this.onPermiteAdd(); 
            })
        }
    }

    onPressRemove = (item) => {
        let quantity = 0
        let lista = [...this.state.items]
        for (let index = 0; index < lista.length; index++) {
            const element = lista[index];
            if (element.id === item.id){

                if (element.selected > 1){
                    if ( (!(typeof element.selecionados === 'undefined')) && (element.selecionados.length > 0) ){
                        if ( (!(typeof element.selecionados === 'undefined')) && (element.selecionados.length > 0) ){
                            element.selecionados.splice(element.selecionados.length - 1, 1)
                        }    
                        element.selected -= 1
                    } else {
                        element.selected -= 1
                    }   

                } else {
                    if ( (!(typeof element.selecionados === 'undefined')) && (element.selecionados.length > 0) ){
                        element.selecionados.splice(0, 1)
                    }    
                    element.selected -= 1
                }
            }
            quantity += element.selected
        }

        this.setState({ quantity: quantity, items: lista }, () => { 
            this.onPermiteConfirmar();
            this.onPermiteAdd(); 
        })
    }

    onPermiteAdd = () => {
        let permiteAdd = true;

        if (!(typeof this.state.variable.maximum === 'undefined')){            
            if (this.state.variable.maximum === 'S'){
                permiteAdd = this.state.quantity < this.state.variable.quantitymaximum
            }
        }  

        this.setState({ permiteAdd: permiteAdd });
    }

    onPermiteConfirmar = () => {
        let permiteConfirmar = false;

        if (this.state.variable.requerid === 'S'){
            permiteConfirmar = this.state.quantity >= this.state.variable.quantity  
        } else {
            permiteConfirmar = this.state.quantity > 0
        }

        this.setState({ permiteConfirmar: permiteConfirmar });
    }

    getName = (texto) => {
        let nameFormat = '';
        if (texto && (texto !== '')){
            nameFormat = texto.charAt(0).toUpperCase() + texto.slice(1).toLowerCase();
        }  
        return nameFormat; 
    }

    onCloseDetalhes = () => {
        this.setState({ 
            showStructure: false, 
            itemSelected: null,
            temopcional: false, 
            tempadrao: false,
            product: null, 
            quantity: 0
        }, () => {
            this.onPermiteConfirmar();
            this.onPermiteAdd(); 
        })   
    }

    onConfirmeDetalhes = (product) => {
        onConfirmeStructure(product, this.state.items, true);

        let permiteMaisQueUM = false;
        if  (!(typeof this.state.variable.maximum === 'undefined')){
            if (this.state.variable.maximum === 'S'){
                permiteMaisQueUM = this.state.variable.quantitymaximum > 1 
            } else {
                permiteMaisQueUM = this.state.variable.quantity > 1 
            }  
        } else {
            permiteMaisQueUM = this.state.variable.quantity > 1  
        }

        if (permiteMaisQueUM){
            let quantity = 0
            for (let index = 0; index < this.state.items.length; index++) {
                const element = this.state.items[index];
                quantity += element.selected
            }
            this.setState({ 
                showStructure: false, 
                product: null,
                quantity: quantity
            }, () => {
                this.onPermiteConfirmar();
                this.onPermiteAdd(); 
            })
        } else {
            this.setState({ 
                showStructure: false, 
                product: null,
                quantity: 1
            }, () => {                        
                this.onPermiteConfirmar();
                this.onPermiteAdd(); 
            })
        }
 
    }

    onCancelPizza = () => {
        this.setState({ 
            itemSelected: null,
            showPizza: false, 
            pizza: null, 
            quantity: 0
        }, () => {
            this.onPermiteConfirmar();
            this.onPermiteAdd(); 
        })  
    }

    onConfirmouPizza = (pizza) => {
        onConfirmePizza(pizza)
        onConfirmeStructure(pizza, this.state.items, true)

        let permiteMaisQueUM = false;
        if  (!(typeof this.state.variable.maximum === 'undefined')){
            if (this.state.variable.maximum === 'S'){
                permiteMaisQueUM = this.state.variable.quantitymaximum > 1 
            } else {
                permiteMaisQueUM = this.state.variable.quantity > 1 
            }  
        } else {
            permiteMaisQueUM = this.state.variable.quantity > 1  
        }

        if (permiteMaisQueUM){
            let quantity = 0
            for (let index = 0; index < this.state.items.length; index++) {
                const element = this.state.items[index];
                quantity += element.selected
            }
            this.setState({ 
                showPizza: false, 
                pizza: null,
                quantity: quantity
            }, () => {
                this.onPermiteConfirmar();
                this.onPermiteAdd(); 
            })
        } else {
            this.setState({ 
                showPizza: false, 
                pizza: null,
                quantity: 1
            }, () => {                
                this.onPermiteConfirmar();
                this.onPermiteAdd(); 
            })
        }

    }

    renderItem = (item, index) => {
        return (
            <Fragment>
                {
                    this.state.heCheckBox ?
                    <div className='product-detail-composition' onClick={() => {this.onPressItem(item)}}>                                                         
                        {
                            item.customize && 
                            item.customize === 'S' ?
                            <Fragment>
                                {
                                    this.state.itemSelected && 
                                    this.state.itemSelected.id === item.id ? 
                                    <Checkbox checked /> : 
                                    <Checkbox checked={false}/>
                                }
                            </Fragment> : 
                            <Checkbox disabled checked />
                        }
                        <p className='product-aditional-component' style={{
                            width: item.value && item.value > 0 ? 'calc(100% - 130px)' : 'calc(100% - 50px)'
                        }}>{this.getName(item.component.name)}</p>
                        
                        {
                            item.value && item.value > 0 ?
                            <div className='product-detail-value'>
                                <span className='product-label-value'>+ R$ {item.value.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}</span>
                            </div> : <Fragment />
                        }
                    </div> : 
                    <div className='product-variable-aditional'>
                        <p className='product-aditional-component' style={{
                            width: item.value && item.value > 0 ? 'calc(100% - 180px)' : 'calc(100% - 100px)'
                        }}>
                            {this.getName(item.component.name)}
                        </p>
                        {
                            this.state.permiteAdd ? 
                            <div className='product-action'onClick={() => {this.onPressAdd(item)}}>
                                <AddIcon color="primary"/>
                            </div> : 
                            <div className='product-action' disabled><AddIcon color="disabled"/></div>
                        }
                        <div className='product-selected'>
                            <p className='product-text-selected'>{item.selected}</p>
                        </div>
                        {
                            item.selected && 
                            item.selected > 0 ?
                            <div className='product-action' onClick={() => {this.onPressRemove(item)}}>
                                <RemoveIcon color="error"/>
                            </div> : 
                            <div className='product-action'><RemoveIcon color="disabled"/></div>
                        }
                        {
                            item.value && item.value > 0 ?
                            <div className='product-detail-value'>
                                <span className='product-label-value'>+ R$ {item.value.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}</span>
                            </div> : <Fragment />
                        }
                    </div>  
                }
            </Fragment>
        )
    }

    render() {
        return ( 
            <Fragment>
                
                {
                    this.state.showStructure && 
                    this.state.product && 
                    this.state.product.id > 0 &&
                    <Composicao 
                        confirm={false}
                        show={this.state.showStructure} 
                        product={this.state.product}
                        tempadrao={this.state.tempadrao}
                        temopcional={this.state.temopcional}
                        onClose={this.onCloseDetalhes.bind(this)}
                        onConfirme={this.onConfirmeDetalhes.bind(this)}
                        permitefazerpedido={true} />   
                }                        
                
                {
                    this.state.showPizza && 
                    this.state.pizza && 
                    this.state.pizza.id > 0 &&
                    <Pizza 
                        confirm={false}
                        show={this.state.showPizza} 
                        pizza={this.state.pizza}
                        permitefazerpedido={true} 
                        onConfirmPressed={ this.onConfirmouPizza.bind(this) }
                        onCancelPressed={ this.onCancelPizza.bind(this) } />   
                }

                {                    
                    this.props.inComponent ? 
                    <Fragment >
                        <p className='product-detail-title' style={{marginTop: 20}}>{this.getName(this.props.variable.name)}</p>
                        <div>
                            {
                                this.state.items.map((item, index)  => (
                                    <div key={index}>
                                        { this.renderItem(item, index) }
                                    </div>                                                    
                                )) 
                            }
                        </div>

                        <div style={{textAlign: 'center'}}>
                            <Button autoFocus className='product-cancel' onClick={() => {this.handleCancel()}} style={{marginRight: 5}}>
                                <p className='confirme-label'>Cancelar</p>
                            </Button>
                            {
                                this.state.permiteConfirmar ? 
                                <Button className='product-confirm' onClick={() => {this.handleOk()}} style={{marginLeft: 5}}>
                                    <p className='confirme-label'>Confirmar</p>
                                </Button> : 
                                <Button disabled={true} className='product-confirm-disable' style={{marginLeft: 5}}>
                                    <p className='confirme-label'>Confirmar</p>
                                </Button> 
                            }
                        </div>
                    </Fragment> :
                    <Dialog
                        sx={{ '& .MuiDialog-paper': { width: '90%', maxHeight: this.state.clientHeight } }}
                        maxWidth="xs"
                        open={this.props.open}>
                        <DialogTitle>{this.getName(this.props.variable.name)}</DialogTitle>                      

                        <DialogContent dividers={true}>
                            {
                                this.state.items.map((item, index)  => (
                                    <div key={index}>
                                        { this.renderItem(item, index) }
                                    </div>                                                    
                                )) 
                            }
                        </DialogContent>

                        <DialogActions className='button-dialogActions'>                            
                            <Button autoFocus className='product-cancel' onClick={() => {this.handleCancel()}} style={{marginRight: 5}}>
                                <p className='confirme-label'>Cancelar</p>
                            </Button>
                            {
                                this.state.permiteConfirmar ? 
                                <Button className='product-confirm' onClick={() => {this.handleOk()}} style={{marginLeft: 5}}>
                                    <p className='confirme-label'>Confirmar</p>
                                </Button> : 
                                <Button disabled={true} className='product-confirm-disable' style={{marginLeft: 5}}>
                                    <p className='confirme-label'>Confirmar</p>
                                </Button> 
                            }
                        </DialogActions>
                    </Dialog>  
                }
            </Fragment>
        )
    }
}

export default Variavel;
