import React, { Fragment } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import { Button, Modal, TextField } from '@mui/material';
import { global } from '../commons/Globals';

import 'react-confirm-alert/src/react-confirm-alert.css'; 

import { 
    compareArrays, 
    compositionEditable, 
    getAdditionalValueComposition, 
    getComponentManufactured, 
    getVariable, 
    onConfirmePizza, 
    onConfirmeStructure, 
    onConfirmeVariable
} from '../commons/Service.Product';
import Variavel from './Variavel';
import Composicao from './Composicao';
import Pizza from './Pizza';

class Combo extends React.Component {
    constructor(props) {
        super();
        this.state = {
            clientHeight: window.innerHeight - 60,
            items: [],
            variable: null,
            product: null,
            pizza: null,
            kit: null,
            valor: 0, 
            stage: 1,
            note: '',
            tempadrao: false,
            temopcional: false,
            showStructure: false,
            showVariable: false, 
            showPizza: false,
            valid: false
        }
    }

    componentDidMount() {
        window.addEventListener('resize', this.handleResizedScreen);
        this.validStage(0);
    }

    static getDerivedStateFromProps(props, state){
        if (!compareArrays(props.kit.structure, state.items)){ 
            return { 
                items: props.kit.structure, 
                kit: props.kit 
            }
        }
        return null; 
    }

    handleResizedScreen = () => {        
        this.setState({ clientHeight: window.innerHeight - 60 });
    };
      
    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResizedScreen);
    }

    validStage = (stage) => {
        if (this.props.permitefazerpedido){
            for (let index = stage; index < this.state.items.length; index++) {
                const element = this.state.items[index];
                this.setState({ stage: index + 1 })            

                if ((element.variableComposition) && (element.variable) && (element.variableComposition > 0)){
                    this.setState({
                        variable: getVariable(element.variable), 
                        showVariable: true
                    })             
                    break;
                } else if (element.component.type === 3){
                    this.setState({ 
                        pizza: getComponentManufactured(element.component, element.value, element.id, false), 
                        showPizza: true
                    }) 
                    break;  
                } else if ((element.component) && 
                        (element.component.manufactured === 'S') && 
                        (element.component.manufacturedAntecipated === 'N') &&
                        (compositionEditable(element.component.structure))){                
                    
                    let tempadrao = false;
                    let temopcional = false;

                    for (let index = 0; index < element.component.structure.length; index++) {
                        const component = element.component.structure[index];
                        if (component.default && (component.default === 'S')){
                            tempadrao = true
                        } else {
                            temopcional = true
                        }

                        if (tempadrao && temopcional){
                            break;
                        }
                    }

                    let product = getComponentManufactured(element.component, element.value, element.id, false);
                    product.valor = `R$ ${product.unitPrice.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}`;
                    
                    this.setState({ 
                        product: product, 
                        showStructure: true,
                        tempadrao: tempadrao,
                        temopcional: temopcional
                    })      
                    break;
                } else {
                    element.selected = 1;                
                    if (this.state.items.length === index + 1){
                        this.setState({ valid: true });
                        break;
                    }   
                }
            } 
        } else {
            this.setState({ valid: true });
        }
    }

    getName = (texto) => {
        let nameFormat = '';
        if (texto && (texto !== '')){
            nameFormat = texto.charAt(0).toUpperCase() + texto.slice(1).toLowerCase();
        }  
        return nameFormat;      
    }

    onChangeNote = (text) => {
        this.setState({ note: text });
    }

    onConfirmar = () => {        
        if (this.props.onConfirmPressed){
            const kitJSON = JSON.stringify(this.props.kit);
            let item = JSON.parse(kitJSON);
            item.structure = [];
            item.structure = [...this.state.items];
            
            let additionalValue = 0;
            if (item.structure && (item.structure.length > 0)){
                additionalValue = getAdditionalValueComposition(item.structure, item.type === 3, 1);
            }

            let structure = undefined;
            if (item.structure && (item.structure.length > 0)){
                structure = item.structure;
            }

            let printerId = 0;
            if (item.printerId && (item.printerId > 0)){
                printerId = item.printerId;
            }

            let nControle = global.order.items.length;
            for (let index = 0; index < global.order.items.length; index++) {
                if (global.order.items[index].controle && (global.order.items[index].controle > 0)){
                    if (global.order.items[index].controle > nControle){
                        let nControle = global.order.items[index].controle;  
                    }
                }            
            }
            nControle += 1;

            global.order.items.push({
                controle: nControle,
                id: item.id, 
                group: item.groupId,
                name: item.name,
                unitPrice: item.unitPrice, 
                manufactured: item.manufactured, 
                manufacturedAntecipated: item.manufacturedAntecipated,
                timeControl: item.timeControl,
                serviceProvision: item.serviceProvision, 
                component: null,
                additionalValue: additionalValue,
                salesgroup: item.salesgroup, 
                amount: 1, 
                type: item.type,
                sheet: item.sheet,
                stock: item.stock,
                typeControlStock: item.typeControlStock,
                typeProductionControlStock: 0,
                typeProduction: 0,
                structure: structure, 
                printerId: printerId,
                note: item.note              
            });

            this.props.onConfirmPressed();
        }
    }

    onCancelVariable = () => {
        this.setState({ showVariable: false, variable: null }, () => {
            this.props.onCancelPressed();
        })
    }

    onVariableConfirme = (variable) => {
        if ((variable) && (variable.id > 0)){            
            let product = this.state.kit;
            onConfirmeVariable(variable, product.structure);

            this.setState({
                valor: getAdditionalValueComposition(this.state.items, false, 1),
                showVariable: false, 
                variable: null, 
                kit: product
            }, () => {
                if (this.state.items.length > this.state.stage){
                    this.validStage(this.state.stage)
                } else {
                    this.setState({ valid: true })
                }
            })
        }
    }

    onCloseDetalhes = () => {
        this.setState({ showStructure: false, product: null }, () => {
            this.props.onCancelPressed();
        }) 
    }

    onConfirmeDetalhes = (product) => {
        if ((product) && (product.id > 0)){ 
            onConfirmeStructure(product, this.state.items, false);

            this.setState({ 
                valor: getAdditionalValueComposition(this.state.items, false, 1),
                showStructure: false, 
                product: null 
            }, () => {
                if (this.state.items.length > this.state.stage){
                    this.validStage(this.state.stage)
                } else {
                    this.setState({ valid: true })
                }
            })
        }
    }

    onCancelPizza = () => {
        this.setState({ showPizza: false, pizza: null }, () => {
            this.props.onCancelPressed();
        }) 
    }

    onConfirmouPizza = (pizza) => {
        onConfirmePizza(pizza);
        onConfirmeStructure(pizza, this.state.items, false);
        this.setState({ 
            valor: getAdditionalValueComposition(this.state.items, false, 1),
            showPizza: false, 
            pizza: null 
        }, () => {
            if (this.state.items.length > this.state.stage){
                this.validStage(this.state.stage)
            } else {
                this.setState({ valid: true })
            }
        })
    }

    renderItems = (item) => {
        if (item.variable && (item.variable.id > 0) && (item.variable.requerid === 'S')){
            return (
                <div className='product-detail-aditional' style={{height: 50 + (item.variable.components.length * 28)}}>
                    <p className='product-aditional-component' style={{marginLeft: 10, fontWeight: '800', width: 'calc(100% - 10px)'}}>
                        {this.getName(item.variable.name)}
                        {
                            item.variable.components.map(variable => (
                                <Fragment key={variable.id}>
                                    <div style={{display: 'flex'}}>
                                        {
                                            variable.selected && 
                                            variable.selected > 0 ?
                                            <div className='combo-item-variable-selected'>
                                                <CheckIcon sx={{ color: '#4caf50', fontSize: 25 }}/>
                                                <p className='combo-item-variable-quantity-selected'>{variable.selected}</p>
                                            </div> : <Fragment />
                                        }
                                        <p className='combo-item-variable' style={variable.selected && variable.selected > 0 ? {marginLeft: 10} : {}}>{variable.component.name}</p>
                                        {
                                            variable.value && variable.value > 0 ? 
                                            <div className='product-detail-value' style={{paddingTop: 0, marginLeft: 10}}>
                                                <span className='product-label-value' style={{fontSize: 9}}>+ R$ {variable.value.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}</span>
                                            </div> : <Fragment />
                                        }
                                    </div>
                                </Fragment>                                                    
                            ))    
                        }
                    </p>
                </div>
            )
        } else {
            return (
                <div className='product-detail-aditional'> 
                    <p className='product-aditional-component' style={{marginLeft: '10px', display: 'flex', width: 'calc(100% - 10px)'}}>
                        <p className='product-aditional-quantity'>{item.quantity} x </p>
                        <p className='product-aditional-name'>{this.getName(item.component.name)}</p>
                    </p>
                </div>
            )
        }
    }

    render() {
        return ( 
            <Modal
                open={this.props.show}
                disableAutoFocus={true}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                {
                    this.props.kit ? 
                    <div className='product-detail' style={{maxHeight: this.state.clientHeight}}>
                        <div className='product-detail-close' onClick={() => { this.props.onCancelPressed() }}><CloseIcon/></div>                                                   
                        
                        <div className='product-detail-header' style={{marginBottom: 0}}>
                            {
                                this.props.kit.image && 
                                this.props.kit.image !== '' ? 
                                <div className='product-detail-image' style={{backgroundImage: 'url(data:image/png;base64,'+this.props.kit.image+')'}}/> :
                                <div className='product-detail-image-notfound'/>
                            }               
                            <h5 className='product-detail-name'>{this.props.kit.name}</h5>
                            <h1 className='product-value'>
                                {this.props.kit.unitPrice.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}
                                {
                                    this.state.valor && this.state.valor > 0 ? 
                                    <span className='product-value-aditional'> + {this.state.valor.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}</span> : <Fragment />
                                }
                            </h1>
                        </div>
                        
                        <div className='combo-stage'>
                            <p className='combo-label-stage'>Etapa </p>
                            <p className='combo-item-stage'>{this.state.stage}</p>
                            <p className='combo-label-stage'>de</p>
                            <p className='combo-item-stage'>{this.state.items.length}</p>
                        </div>
                        
                        {
                            this.state.showStructure && 
                            this.state.product && 
                            this.state.product.id > 0 &&
                            <Composicao 
                                confirm={false}
                                show={this.state.showStructure} 
                                product={this.state.product}
                                tempadrao={this.state.tempadrao}
                                temopcional={this.state.temopcional}
                                onClose={this.onCloseDetalhes.bind(this)}
                                onConfirme={this.onConfirmeDetalhes.bind(this)}
                                permitefazerpedido={this.props.permitefazerpedido} />   
                        }
                        
                        {
                            this.state.showPizza && 
                            this.state.pizza && 
                            this.state.pizza.id > 0 &&
                            <Pizza 
                                confirm={false}
                                show={this.state.showPizza} 
                                pizza={this.state.pizza}
                                permitefazerpedido={this.props.permitefazerpedido} 
                                onConfirmPressed={ this.onConfirmouPizza.bind(this) }
                                onCancelPressed={ this.onCancelPizza.bind(this) } />   
                        }

                        <div className='product-detail-container' style={{
                            maxHeight: this.props.permitefazerpedido ? this.state.clientHeight - (this.state.valid ? 365 : 265) : this.state.clientHeight - 265
                        }}> 
                            
                            {
                                this.state.showVariable && 
                                this.state.variable && 
                                this.state.variable.id > 0 ?
                                <Variavel 
                                    inComponent={true}
                                    open={this.state.showVariable}
                                    variable={this.state.variable}
                                    onCancel={this.onCancelVariable.bind()}
                                    onConfirme={this.onVariableConfirme.bind()}/> : 
                                <Fragment>
                                    <p className='product-detail-title' style={{marginTop: 20}}>Produtos</p>
                                    
                                    {             
                                        this.props.kit.structure.map(item => (
                                            <Fragment key={item.id}>
                                                { this.renderItems(item) }
                                            </Fragment>                                                    
                                        ))                                        
                                    }

                                    {
                                        this.props.permitefazerpedido ?
                                        <TextField
                                            placeholder="Observação"
                                            id="textarea-obs"
                                            margin="normal" 
                                            color="warning"
                                            fullWidth 
                                            multiline
                                            rows={4}
                                            value={this.state.note}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                this.onChangeNote(event.target.value);
                                            }}/> : <Fragment />
                                    }
                                </Fragment>
                            }                            
                        </div>

                        {
                            this.props.permitefazerpedido && this.state.valid ?
                            <div className='product-footer'>
                                <Button className='product-confirm' onClick={() => {this.onConfirmar()}}>
                                    <CheckIcon />
                                    <p className='confirme-label'>Adicionar combo</p>
                                </Button> 
                            </div> : <Fragment />
                        }

                    </div> : <Fragment />
                }
                
            </Modal>  
        )
    }
}

export default Combo;
