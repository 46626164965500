import React, { Fragment } from 'react';

import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';

import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css'; 

import { Button, Modal } from '@mui/material';
import PagamentoDetalhe from './PagamentoDetalhe';

class Pagamento extends React.Component {

    constructor(props) {
        super();
        this.state = {
            clientHeight: window.innerHeight - 150,
            disabledAction: false,
            paymentSelected: null,
            showDetalhe: false,
            paymentmethods: [], 
            operators: [],
            moneytype: 0,
            noChange: true,
            valuePayment: 0,
            operationType: '',
            paymentDetail: '',  
            idFlag: 0,
            flags: []
        }
    }

    componentDidMount() {
        window.addEventListener('resize', this.handleResizedScreen);
        
        let paymentmethods = [];
        for (let index = 0; index < this.props.paymentmethods.length; index++) {
            const element = this.props.paymentmethods[index];
            element.selected = false;
            paymentmethods.push(element);
        }
        
        this.setState({
            paymentmethods: paymentmethods, 
            operators: this.props.operators,
            moneytype: this.props.moneytype,
            flags: this.props.flags
        })
    }

    handleResizedScreen = () => {        
        this.setState({ clientHeight: window.innerHeight - 150 });
    };
      
    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResizedScreen);
    }

    getName = (texto) => {
        let nameFormat = '';
        if (texto && (texto !== '')){
            const arrayText = texto.split(' ')
            if (arrayText && (arrayText.length > 0)){
                for (let i = 0; i < arrayText.length; i++) {
    
                    let name = arrayText[i].substring(0, 1);
                    name += arrayText[i].substring(1, arrayText[i].length).toLowerCase();
                    nameFormat += ' ' + name;
                }
            }
        }
        return nameFormat;    
    }

    onSelectPayment = (payment) => {
        if (payment.id === this.state.moneytype){
            this.setState({ 
                paymentSelected: payment,
                showDetalhe: true 
            })

        } else if (payment.debit.type === 8){
            this.setState({ 
                paymentSelected: payment,
                showDetalhe: true 
            })

        } else {
            let list =  [...this.state.paymentmethods];
            for (let index = 0; index < list.length; index++) {
                list[index].selected = list[index].id === payment.id;
            }
            this.setState({
                idFlag: 0,
                noChange: true,
                operationType: '',
                paymentDetail: '',
                paymentmethods: list, 
                paymentSelected: payment, 
                valuePayment: this.props.valorPedido
            })
        }
    }

    onClose = () => {
        let list =  [...this.state.paymentmethods];
        for (let index = 0; index < list.length; index++) {
            list[index].selected = false;
        }

        this.setState({ 
            idFlag: 0,
            noChange: true,
            valuePayment: 0,
            operationType: '',
            paymentDetail: '',  
            showDetalhe: false, 
            paymentSelected: null,
            paymentmethods: list 
        })
    }

    onConfirme = (value, operationType, paymentDetail, idFlag, noChange) => {         
        let list =  [...this.state.paymentmethods];
        for (let index = 0; index < list.length; index++) {
            list[index].selected = list[index].id === this.state.paymentSelected.id;
        }
        this.setState({ 
            operationType: operationType, 
            paymentDetail: paymentDetail,
            paymentmethods: list, 
            valuePayment: value,
            showDetalhe: false,
            noChange: noChange,
            idFlag: idFlag 
        })       
    }  

    onConfirmar = () => {             
        if (this.state.paymentSelected && this.state.paymentSelected.id > 0){
            
            if (!this.state.disabledAction){
                this.setState({disabledAction: true}, () => {
                    this.props.onConfirme({          
                        operationType: this.state.operationType, 
                        payment: this.state.paymentSelected,  
                        value: this.state.valuePayment,
                        idFlag: this.state.idFlag
                    })
                }) 
            }          

        } else {
            confirmAlert({
                title: 'Atenção!',
                message: 'Pagamento não selecionado.',
                buttons: [{ label: 'Ok' }],
                closeOnEscape: false,
                closeOnClickOutside: false,
                overlayClassName: "class-alert-livn"
            })
        }
    }

    render() {
        return ( 
            <Modal
                open={this.props.show}
                disableAutoFocus={true}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <div className='pedido-detail'>
                    <div className='pedido-detail-header' style={{marginBottom: 0}}>
                        <h2 className='pedido-title'>Selecione a forma de pagamento</h2>
                        <div className='pedido-detail-close' onClick={() => { this.props.onClose() }}><ArrowBackIosNewIcon/></div>
                    </div>

                    {
                        this.state.showDetalhe &&  
                        <PagamentoDetalhe 
                            show={this.state.showDetalhe}
                            flags={this.state.flags}
                            payment={this.state.paymentSelected}
                            valorPedido={this.props.valorPedido}
                            operators={this.state.operators}
                            moneytype={this.state.moneytype}
                            onClose={this.onClose.bind(this)}
                            onConfirme={this.onConfirme.bind(this)}/>
                    }
                    
                    <div className='pedido-detail-container' style={{maxHeight: this.state.clientHeight}}> 
                        <div className='div-payment'>
                        { 
                            this.state.paymentmethods && 
                            this.state.paymentmethods.length > 0 ?
                            this.state.paymentmethods.map((payment, index) => {
                                return ( 
                                    <div key={index} className='card-payment' onClick={() => { this.onSelectPayment(payment) }}>
                                        {
                                            payment.selected ?
                                            <Fragment>
                                                <RadioButtonCheckedIcon sx={{ color: '#5046BB', fontSize: 20 }}/>
                                            </Fragment> : 
                                            <RadioButtonUncheckedIcon sx={{ color: '#5046BB', fontSize: 20 }}/>
                                        }
                                        <div style={{display: 'flex'}}>
                                            <p className='name-payment'>{this.getName(payment.name)}</p>
                                            {
                                                payment.selected && 
                                                this.state.paymentDetail !== '' ?
                                                <p className='detail-payment'>{this.state.paymentDetail}</p> : <Fragment />
                                            }
                                        </div>
                                        
                                    </div>    
                                )
                            }) : <Fragment/>
                        }
                        </div>
                    </div>           

                    <div className='cliente-footer'>
                        <Button disabled={this.state.disabledAction} className='cliente-confirm' onClick={() => {this.onConfirmar()}}>
                            <p className='confirme-label'>Confirmar pagamento</p>
                        </Button>
                    </div>
                </div>
            </Modal> 
        )
    }
}

export default Pagamento;
