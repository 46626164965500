import React, { Fragment } from 'react';

import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import MopedOutlinedIcon from '@mui/icons-material/MopedOutlined';
import ShoppingBagOutlinedIcon from '@mui/icons-material/ShoppingBagOutlined';

import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css'; 

import { Oval } from  'react-loader-spinner';
import { GET, validateERROR } from '../commons/Service';
import { Modal, TextField } from '@mui/material';

import { getValue } from '../commons/Service.Product';
import PedidoDetalhe from './PedidoDetalhe';

class Historico extends React.Component {

    constructor(props) {
        super();
        this.state = {
            clientHeight: window.innerHeight - 60, 
            showDetail: false,
            validando: false,
            cpfvalidator: '', 
            cpfconsulta: '', 
            labelloader: '',
            personName: '',
            orderDetail: 0,
            pedidos: [],
            order: null,
            cpf: ''
        }
    }

    componentDidMount() {
        window.addEventListener('resize', this.handleResizedScreen);
        
        if (this.props.document && this.props.document !== ''){
            let orderDetail = 0;
            if (this.props.orderId && this.props.orderId > 0){
                orderDetail = this.props.orderId;
            }
            this.setState({orderDetail: orderDetail}, () => {
                this.onChangeCPF(this.props.document)
            })
        }
    }

    handleResizedScreen = () => {   
        this.setState({ clientHeight: window.innerHeight - 60 });   
    };
      
    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResizedScreen);
    }
    
    onChangeCPF = (text) => {
        var cpf = text.replace(/[^0-9]/g,'');
        this.setState({ cpf: cpf }, () => {
            if (cpf.length === 11){
                this.onValidaCPF();
            }
        });
    }

    onBlurCPF = () => {
        //this.onValidaCPF();
    }

    onValidaCPF = () => {
        if (this.state.cpf  !== ''){
            if (this.state.cpfvalidator !== this.state.cpf){
                this.setState({ 
                    labelloader: 'Validando CPF. Aguarde...',
                    cpfvalidator: this.state.cpf,
                    cpfconsulta: this.state.cpf,
                    validando: true
                }, () => {

                    GET('order/status/' + this.state.cpf + '/', 30000).then((response) => { 

                        let pedidos = [];
                        let personName = '';
                        if (response.items && response.items.length > 0){
                            pedidos = response.items;
                            personName = response.items[0].personName;
                        } else if (response.personId && response.personId > 0){
                            personName = response.personName;
                        } else {

                        }

                        this.setState({ 
                            permiteeditar: false,
                            validando: false, 
                            cpfvalidator: "", 
                            pedidos: pedidos, 
                            personName: personName
                        }, () => {
                            if (this.state.orderDetail > 0){
                                let detail = pedidos.find(({ id }) => id === this.props.orderId);                 
                                if (detail && (detail.id > 0)){
                                    this.setState({ orderDetail: 0, order: detail, showDetail: true })
                                }
                            }
                        })

                    }).catch((error) => {
                        let message = '';
                        let erro = validateERROR(error);
                        if ((erro) && (erro.message) && (erro.message !== '')){ 
                            message = erro.message;
                        } else {
                            message = 'Falha ao consultar histórico';
                        }
                        this.setState({ 
                            permiteeditar: false,
                            validando: false, 
                            cpfvalidator: "",
                            cpf: ""
                        }, () => {                            
                            confirmAlert({
                                title: 'Erro ao consultar histórico!',
                                message: message,
                                buttons: [{ label: 'Ok' }],
                                closeOnEscape: false,
                                closeOnClickOutside: false,
                                overlayClassName: "class-alert-livn"
                            }) 
                        })
                    })

                }) 
            }            
        } 
    }

    getName = (texto) => {
        let nameFormat = '';
        if (texto && (texto !== '')){
            const arrayText = texto.split(' ')
            if (arrayText && (arrayText.length > 0)){
                for (let i = 0; i < arrayText.length; i++) {
    
                    let name = arrayText[i].substring(0, 1);
                    name += arrayText[i].substring(1, arrayText[i].length).toLowerCase();
                    nameFormat += ' ' + name;
                }
            }
        }
        return nameFormat;    
    }

    leftPad = (value, totalWidth, paddingChar) => {
        let length = totalWidth - value.toString().length + 1;
        return Array(length).join(paddingChar || '0') + value;
    };

    getPeriodoOrder = (order) => {
        let dateFormat = '';
        if (order.creationdate && order.creationdate !== ''){
            let data = new Date(order.creationdate);
            if (data){
                let day = data.getDate();
                let month = data.getMonth() + 1;
                dateFormat = `${this.leftPad(day, 2, '0')}`;

                switch(month) {
                    case 1: dateFormat += " \ Janeiro"; break;
                    case 2: dateFormat += " \ Fevereiro"; break;
                    case 3: dateFormat += " \ Março"; break;
                    case 4: dateFormat += " \ Abril"; break;
                    case 5: dateFormat += " \ Maio"; break;
                    case 6: dateFormat += " \ Junho"; break;
                    case 7: dateFormat += " \ Julho"; break;
                    case 8: dateFormat += " \ Agosto"; break;
                    case 9: dateFormat += " \ Setembro"; break;
                    case 10: dateFormat += " \ Outubro"; break;
                    case 11: dateFormat += " \ Novembro"; break;
                    case 12: dateFormat += " \ Dezembro"; break;
                }

            }
        }
        return dateFormat;
    }

    getValorOrder = (order) => {
        let valor = 0;
        if (order.data && order.data !== ''){
            let orderItens = JSON.parse(order.data);
            if (orderItens && orderItens.order && orderItens.order.items && orderItens.order.items.length > 0){
                valor = getValue(orderItens.order.items);
            }
        }
        if (order.fee && order.fee > 0){
            valor += order.fee;
        }
        return valor;
    }

    onCloseDetail = () => {
        this.setState({ showDetail: false })
    }

    onGetStatusOrder = (statusId) => {  
        let statusName = "EM PROCESSO";      
        switch(statusId) {
            case 3: statusName = "CANCELADO"; break;
            case 7: statusName = "FINALIZADO"; break;
        }
        return statusName;
    }

    render() {
        return ( 
            <Modal
                open={this.props.show}
                disableAutoFocus={true}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <div className='pedido-detail'>
                    {
                        this.state.validando ?
                        <div className='loader-valid'> 
                            <div className='loader-loading'>
                                <Oval
                                    height={20}
                                    width={20}
                                    color="#fff"
                                    wrapperStyle={{}}
                                    wrapperClass=""
                                    visible={true}
                                    ariaLabel='oval-loading'
                                    secondaryColor="#ffb03b"
                                    strokeWidth={5}
                                    strokeWidthSecondary={5}/>
                                <p className='loader-label-loader'>{this.state.labelloader}</p>
                            </div>
                        </div> : <Fragment />
                    }

                    {
                        this.state.showDetail ? 
                        <PedidoDetalhe 
                            show={this.state.showDetail}
                            order={this.state.order}
                            onClose={this.onCloseDetail.bind(this)}/> : 
                        <Fragment />
                    }

                    <div className='pedido-detail-header' style={{marginBottom: 0}}>
                        <h2 className='pedido-title'>Histórico de pedidos</h2>
                        <div className='pedido-detail-close' onClick={() => { this.props.onClose() }}><ArrowBackIosNewIcon/></div>
                    </div>
                    
                    <div className='pedido-detail-container' style={{maxHeight: this.state.clientHeight}}> 
                        <div className='pedido-historico-document'>
                            <TextField
                                inputProps = {{ autoFocus: true }}
                                variant="standard"
                                disabled={this.state.validando}
                                label="Informe seu CPF"
                                id="textarea-comand"
                                margin="normal" 
                                color="warning"
                                fullWidth 
                                type="number"
                                value={this.state.cpf}
                                onBlur={() => { this.onBlurCPF() }}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    this.onChangeCPF(event.target.value);
                                }} /> 
                        </div>

                        {
                            this.state.personName !== '' ?
                            <div style={{marginBottom: 15}}>
                                <div className='div-person'>
                                    <span className='span-name-person'>Olá, </span>
                                    <p className='label-name-person'>{this.state.personName}</p>
                                </div>
                                {
                                    this.state.pedidos && 
                                    this.state.pedidos.length > 0 ?
                                    <span className='span-name-person'>UHULL, você tem {this.state.pedidos.length} pedidos no LivnFAST</span> :
                                    <span className='span-name-person'>Não encontramos nenhum pedido :(</span>
                                }
                            </div> : 
                            <Fragment>
                                {
                                    this.state.cpfconsulta !== '' ?
                                    <Fragment>
                                    {
                                        this.state.validando ? <Fragment /> : <div className='span-name-person'>Não conseguimos localizar o cadastro para o CPF informado :(</div>
                                        
                                    }
                                    </Fragment> : <Fragment />
                                }
                            </Fragment>
                        }

                        {
                            this.state.pedidos && 
                            this.state.pedidos.length > 0 ?
                            <Fragment>
                                {
                                    this.state.pedidos.map((order, index) => {
                                        return (
                                            <div key={index} className='div-pedido-historico' onClick={() => { this.setState({ order: order, showDetail: true }) }}>
                                                <div className='div-pedido-justify-content'>
                                                    <p className='label-pedido-date'>{this.getPeriodoOrder(order)}</p>
                                                    <p className='label-pedido-value'>R$ {this.getValorOrder(order).toLocaleString('pt-BR', { minimumFractionDigits: 2 })}</p>
                                                </div>
                                                <div className='div-pedido-justify-content'>
                                                    <p className='label-pedido-orderId'>PEDIDO <span className='span-pedido-orderId'>#{order.id}</span></p>   
                                                    <div className='div-pedido-deliveryType'>
                                                    {
                                                        order.deliveryType === 2 ?
                                                        <MopedOutlinedIcon sx={{fontSize: 17, color: '#3f51b5'}}/> :
                                                        <ShoppingBagOutlinedIcon sx={{fontSize: 17, color: '#3f51b5'}}/>
                                                    }
                                                    </div>   
                                                </div>                                              
                                                {
                                                    order.statusId && 
                                                    order.statusId > 0 ? 
                                                    <div className='div-status-orderId'>
                                                        {
                                                            order.statusId === 3 ?
                                                            <p className='label-status-orderId' style={{color: '#d32f2f', backgroundColor: '#d32f2f38'}}>{this.onGetStatusOrder(order.statusId)}</p> : 
                                                            <Fragment>
                                                                {
                                                                    order.statusId === 7 ?
                                                                    <p className='label-status-orderId' style={{color: '#4caf50', backgroundColor: '#4caf503b'}}>{this.onGetStatusOrder(order.statusId)}</p> :
                                                                    <p className='label-status-orderId' style={{color: '#3f51b5', backgroundColor: '#3f51b533'}}>{this.onGetStatusOrder(order.statusId)}</p>
                                                                }
                                                            </Fragment>
                                                        }
                                                    </div> : <Fragment />
                                                }
                                            </div>
                                        )
                                    })
                                } 
                            </Fragment> : <Fragment />
                        }
                    </div>

                </div>
            </Modal> 
        )
    }
}

export default Historico;
