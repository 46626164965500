import React from 'react';

export const compareArrays = (a, b) => {
    if (a && b){
        if (a.length != b.length){
            return false;
        } else {
            return JSON.stringify(a) == JSON.stringify(b)
        }
    } else {
        return false;
    }
}

export const compositionEditable = (structure) => {
    let editable = false
    for (let index = 0; index < structure.length; index++) {
        if ((structure[index].variableComposition) && (structure[index].variable) && (structure[index].variableComposition > 0)){
            editable = true
            break 
        } else if (structure[index].default === 'N'){
            editable = true
            break 
        } else if ((structure[index].default === 'S') & (structure[index].customize === 'S')){
            editable = true
            break 
        }                       
    }
    return editable
}

const structureValidate = (structure, result) => {
    for (let index = 0; index < structure.length; index++) {
        if ((structure[index].variableComposition) && 
            (structure[index].variable) && 
            (structure[index].variableComposition > 0) && 
            (structure[index].variable.requerid === 'S')){                
            if ((!structure[index].selected) || (structure[index].selected <= 0)){
                result.message += ` » ` + structure[index].variable.name
                result.valid = false
                break 
            }
        } else if ( (structure[index].selected > 0) &&
                    (structure[index].component) && 
                    (structure[index].component.manufactured === 'S') && 
                    (structure[index].component.manufacturedAntecipated === 'N') && 
                    (compositionEditable(structure[index].component.structure)) ){
            result.message += ` » ` + structure[index].component.name
            result = structureValidate(structure[index].component.structure, result)
        } 
    }
    return result
}

export const compositionValidate = (structure) => {
    let result = { valid: true, message: '' }
    for (let index = 0; index < structure.length; index++) {
        if ((structure[index].variableComposition) && 
            (structure[index].variable) && 
            (structure[index].variableComposition > 0) && 
            (structure[index].variable.requerid === 'S')){                
            if ((!structure[index].selected) || (structure[index].selected <= 0)){
                result.message = structure[index].variable.name
                result.valid = false
                break 
            }
        } else if ( (structure[index].selected > 0) && 
                    (structure[index].component) && 
                    (structure[index].component.manufactured === 'S') && 
                    (structure[index].component.manufacturedAntecipated === 'N') && 
                    (compositionEditable(structure[index].component.structure)) ){
            result.message = structure[index].component.name
            result = structureValidate(structure[index].component.structure, result)
        }                      
    }
    return result
}

export const getComponentManufactured = (component, unitPrice, controle, editando) => {
    const myObj = JSON.stringify(component);
    let product = JSON.parse(myObj);
    product.unitPrice = unitPrice;
    if (typeof product.controle === 'undefined'){
        product.controle = controle;
    }
    if (editando){
        for (let index = 0; index < product.structure.length; index++) {
            if (typeof product.structure[index].selected === 'undefined'){
                if (product.structure[index].default === 'S'){
                    product.structure[index].selected = 1;
                } else {
                    product.structure[index].selected = 0;
                }
            }                        
        }
    } else {
        for (let index = 0; index < product.structure.length; index++) {
            if (product.type === 3){

                if ((product.structure[index].variable) && 
                    (product.structure[index].variable.components) && 
                    (product.structure[index].variable.components.length > 0)){
                    product.structure[index].variable.selected = 0;

                    for (let c = 0; c < product.structure[index].variable.components.length; c++) {
                        const element = product.structure[index].variable.components[c];
                        element.selected = 0;

                        if ((element.component) && 
                            (element.component.manufactured === 'S') && 
                            (element.component.manufacturedAntecipated === 'N') && 
                            (element.component.structure) && 
                            (element.component.structure.length > 0)){
                            getStructComponent(element.component.structure);
                        }
                        
                    }
                } else if ( (product.structure[index].component) && 
                            (product.structure[index].component.id > 0) ){
                    
                    const element = product.structure[index];
                    element.selected = 0;

                    if ((element.component) && 
                        (element.component.manufactured === 'S') && 
                        (element.component.manufacturedAntecipated === 'N') && 
                        (element.component.structure) && 
                        (element.component.structure.length > 0)){
                        getStructComponent(element.component.structure);
                    }
                }


            } else {
                if (product.structure[index].default === 'S'){
                    product.structure[index].selected = 1; 

                    if ((product.structure[index].component) && 
                        (product.structure[index].component.manufactured === 'S') && 
                        (product.structure[index].component.manufacturedAntecipated === 'N') && 
                        (product.structure[index].component.structure) && 
                        (product.structure[index].component.structure.length > 0)){
                        getStructComponent(product.structure[index].component.structure);
                    }

                } else {
                    product.structure[index].selected = 0;
                } 
            }                       
        }
    }
    return product;
}

const getStructComponent = (structure) => {
    for (let index = 0; index < structure.length; index++) {
        const element = structure[index];
        
        if (element.default === 'S'){
            element.selected = 1;

            if ((element.component) && 
                (element.component.manufactured === 'S') && 
                (element.component.manufacturedAntecipated === 'N') && 
                (element.component.structure) && 
                (element.component.structure.length > 0)){
                getStructComponent(element.component.structure);
            }

        } else {
            element.selected = 0;
        }
    }
}

export const getVariable = (variable) => {
    const myObj = JSON.stringify(variable)
    let variableObj = JSON.parse(myObj)
    for (let index = 0; index < variableObj.components.length; index++) {
        variableObj.components[index].selected = 0

        if ((variableObj.components[index].component) && 
            (variableObj.components[index].component.manufactured === 'S') && 
            (variableObj.components[index].component.manufacturedAntecipated === 'N') && 
            (variableObj.components[index].component.structure) && 
            (variableObj.components[index].component.structure.length > 0)){
            getStructComponent(variableObj.components[index].component.structure)
        }
    } 
    return variableObj;
}

export const onConfirmeVariable = (variable, lista) => {
    let component = lista.filter(product => { return product.variableComposition === variable.id }) 
    if (component && (component.length > 0) && (component[0].id > 0)){
        if (!component[0].selected){
            component[0].selected = 0
        }
        component[0].selected += 1;

        
        for (let i = 0; i < component[0].variable.components.length; i++) {
            component[0].variable.components[i].selected = 0
        }

        const items = variable.components.filter(item => { return item.selected > 0 })
        for (let index = 0; index < items.length; index++) {
            for (let i = 0; i < component[0].variable.components.length; i++) {
                const element = component[0].variable.components[i];
                if (element.id === items[index].id){
                    element.selected = items[index].selected

                    if ( items[index].selecionados && (!(typeof items[index].selecionados === 'undefined')) && (items[index].selecionados.length > 0) ){
                        element.selecionados = items[index].selecionados
                    } 

                    if ((element.component) && 
                        (element.component.manufactured === 'S') && 
                        (element.component.manufacturedAntecipated === 'N') /*&& 
                        (compositionEditable(element.component.structure))*/){
                        onValidateComposition(element.component, items[index].component.structure)
                    } 

                    break
                }                    
            }
        }
    }
}

const onValidateComposition = (component, structure) => {
    const items = structure.filter(item => { return item.selected > 0 })
    for (let index = 0; index < items.length; index++) {
        for (let i = 0; i < component.structure.length; i++) {
            const element = component.structure[i]                
            if (element.id === items[index].id){
                element.selected = items[index].selected

                if (!((items[index].quantity === null) || (typeof items[index].quantity === 'undefined'))){
                    if ((element.component) && (element.component.type === 6)){
                        element.quantity = items[index].quantity
                    }
                }
                
                if ((element.component) && 
                    (element.component.manufactured === 'S') && 
                    (element.component.manufacturedAntecipated === 'N') /*&& 
                    (compositionEditable(element.component.structure))*/){
                    onValidateComposition(element.component, items[index].component.structure)

                } else if ((element.variableComposition) && (element.variable) && (element.variableComposition > 0)){
                    
                    let variables = items[index].variable.components.filter(variable => { return variable.selected > 0 })
                    for (let j = 0; j < variables.length; j++) {

                        for (let c = 0; c < element.variable.components.length; c++) {
                            const variable = element.variable.components[c];
                            if (variable.id === variables[j].id){
                                variable.selected = variables[j].selected
                                if ((variable.component) && 
                                    (variable.component.manufactured === 'S') && 
                                    (variable.component.manufacturedAntecipated === 'N') /*&& 
                                    (compositionEditable(variable.component.structure))*/){
                                    onValidateComposition(variable.component, variables[j].component.structure)                
                                }
                                break
                            }
                        }
                    }   
                }

                break
            }
        }            
    }
}

export const onConfirmeStructure = (product, items, hevariavel) => {
    for (let index = 0; index < items.length; index++) {
        const element = items[index];
        
        if ((product.type === 5) &&
            (element.variable) && 
            (element.variable.components) && 
            (element.variable.components.length > 0)){

            for (let v = 0; v < element.variable.components.length; v++) {
                const component_variable = element.variable.components[v];
            
                let controle = 0
                if (typeof component_variable.controle === 'undefined'){
                    controle = component_variable.componentId
                } else {
                    controle = component_variable.controle
                }
                if (controle === product.controle){ 
                    if (component_variable.default === 'S'){
                        if ((element.selected === null) || (typeof component_variable.selected === 'undefined')){
                            component_variable.selected = 1
                        }
                    } else {
                        if ((component_variable.selected === null) || (typeof component_variable.selected === 'undefined')){
                            component_variable.selected = 0
                        }
                    }

                    let structure = null;
                    if (component_variable.component && component_variable.component.structure && (component_variable.component.structure.length > 0)){
                        structure = component_variable.component.structure 
                    } else if (component_variable.structure && (component_variable.structure.length > 0)){
                        structure = component_variable.structure 
                    }

                    if (structure){
                        for (let i = 0; i < structure.length; i++) {
                            const component = structure[i];
                            
                            let item = product.structure.filter(item => { return component.id === item.id })
                            if (item && (item.length > 0) && (item[0].id > 0)){
                                component.selected = 0
                                if (item[0].selected){
                                    component.selected = item[0].selected
                                }
                                if (component.selected > 0){
                                    onValidateStructure(component, item[0])
                                }
                            }
                        }
                    }
                }                    
            }

        } else {
            let controle = 0
            if (typeof element.controle === 'undefined'){
                controle = element.id
            } else {
                controle = element.controle
            }

            if (controle === product.controle){
                let structure = null;
                if (element.component && element.component.structure && (element.component.structure.length > 0)){
                    structure = element.component.structure 
                } else if (element.structure && (element.structure.length > 0)){
                    structure = element.structure 
                }

                if (hevariavel && structure){
                    if (typeof element.selecionados === 'undefined'){
                        element.selecionados = []
                    }
                }
                
                if (hevariavel && structure && (element.selected > 0)){
                    const myObj = JSON.stringify(element.component)
                    let oComponet = JSON.parse(myObj) 
                    element.component = oComponet

                    element.selecionados.push(oComponet)

                } else if (hevariavel && structure){              
                    element.selecionados.push(element.component)
                }
                
                if (element.default === 'S'){
                    if ((element.selected === null) || (typeof element.selected === 'undefined')){
                        element.selected = 1
                    }
                } else {
                    if ((element.selected === null) || (typeof element.selected === 'undefined')){
                        element.selected = 0
                    }
                    element.selected += 1
                }

                if (structure){
                    for (let i = 0; i < structure.length; i++) {
                        const component = structure[i];

                        if ((product.type === 3) &&
                            (component.variable) && 
                            (component.variable.components) && 
                            (component.variable.components.length > 0)){
                            component.selected = 0;

                            let ovariable = product.structure.filter(item => { return component.id === item.id })
                            if (ovariable && (ovariable.length > 0) && (ovariable[0].id > 0)){

                                for (let v = 0; v < component.variable.components.length; v++) {
                                    const component_variable = component.variable.components[v];

                                    let item = ovariable[0].variable.components.filter(comp => { return component_variable.id === comp.id })
                                    if (item && (item.length > 0) && (item[0].id > 0)){
                                        component_variable.selected = 0
                                        if (item[0].selected){
                                            component_variable.selected = item[0].selected
                                            if (item[0].selected > 0){
                                                component.selected = 1
                                            }

                                            if (!((item[0].quantity === null) || (typeof item[0].quantity === 'undefined'))){
                                                if ((component.component) && (component.component.type === 6)){
                                                    component.quantity = item[0].quantity
                                                }
                                            }
                                        }

                                        if (component_variable.selected > 0){
                                            onValidateStructure(component_variable, item[0])
                                        }
                                    }
                                }
                            }                                   

                        } else {
                            let item = product.structure.filter(item => { return component.id === item.id })
                            if (item && (item.length > 0) && (item[0].id > 0)){
                                component.selected = 0
                                if (item[0].selected){
                                    component.selected = item[0].selected
                                }                                    

                                if (!((item[0].quantity === null) || (typeof item[0].quantity === 'undefined'))){
                                    if ((component.component) && (component.component.type === 6)){
                                        component.quantity = item[0].quantity
                                    }
                                }

                                if (component.selected > 0){
                                    onValidateStructure(component, item[0])
                                }
                            }
                        }                            
                    }
                }
            }
        }  
    }
}

const onValidateStructure = (component, product) => {
    if ((component.variableComposition) && (component.variable) && (component.variableComposition > 0)){
        for (let index = 0; index < component.variable.components.length; index++) {
            const element = component.variable.components[index];
            
            let item = product.variable.components.filter(item => { return element.id === item.id })
            if (item && (item.length > 0) && (item[0].id > 0)){

                if ( item[0].selecionados && (!(typeof item[0].selecionados === 'undefined')) && (item[0].selecionados.length > 0) ){
                    element.selecionados = item[0].selecionados
                } 
                
                element.selected = 0
                if (item[0].selected){
                    element.selected = item[0].selected
                }
                if (element.selected > 0){
                    onValidateStructure(element, item[0])
                }
            }
        }
    } else if (component.component && component.component.structure && (component.component.structure.length > 0)){
        for (let i = 0; i < component.component.structure.length; i++) {
            const structure = component.component.structure[i];
            
            let item = product.component.structure.filter(item => { return structure.id === item.id })
            if (item && (item.length > 0) && (item[0].id > 0)){
                structure.selected = 0
                if (item[0].selected){
                    structure.selected = item[0].selected
                }
                if (structure.selected > 0){
                    onValidateStructure(structure, item[0])
                }
            }
        }
    }
}

export const onConfirmeStructureProduto = (items) => {
    for (let index = 0; index < items.length; index++) {
        const element = items[index];
        
        if (element.default === 'S'){
            if ((element.selected === null) || (typeof element.selected === 'undefined')){
                element.selected = 1
            }

            if (element.component && element.component.structure && (element.component.structure.length > 0)){
                onConfirmeStructureProduto(element.component.structure)
            } 
        } else if (element.component && element.component.structure && (element.component.structure.length > 0)){
            onConfirmeStructureProduto(element.component.structure)
        }
    }
}


export const onConfirmePizza = (pizza) => {
    let qtdeBordas    = 0;
    let indexBorda    = 0;
    let qtdeAcumulada = 0;

    for (let index = 0; index < pizza.structure.length; index++) {
        const element = pizza.structure[index];
        
        if (element.component && (element.component.id > 0)){
            if ((element.component.type === 6) && (element.selected) && (element.selected > 0)){
                qtdeBordas += element.selected
            }
        }              
    }  

    if (qtdeBordas > 0){
        for (let index = 0; index < pizza.structure.length; index++) {
            const element = pizza.structure[index];
            
            if (element.component && (element.component.id > 0)){
                if ((element.component.type === 6) && (element.selected) && (element.selected > 0)){
                    element.quantity = parseFloat((1 / qtdeBordas).toFixed(3)) 
                    
                    qtdeAcumulada += element.quantity
                    indexBorda += 1

                    if (indexBorda === qtdeBordas){
                        if (qtdeAcumulada !== 1){
                            element.quantity = parseFloat((element.quantity + 1 - qtdeAcumulada).toFixed(3))
                        }
                    }
                }
            }              
        }      
    }

}

/* ********************************************** */
/* ******** VALOR ADICIONAL DA COMPOSIÇÃO ******* */
/* ********************************************** */
export const getValue = (items) => {
    let valor = 0; 
    for (let index = 0; index < items.length; index++) {
        const element = items[index];
        valor = valor + (element.amount * element.unitPrice)
        
        if ((element.structure) && (element.structure.length > 0)){
            valor += getAdditionalValueComposition(element.structure, element.type === 3, 1)
        }
    }
    return valor;
}

export const getAdditionalValueComposition = (items, hePizza, qtdeComponent) => {
    let valor = 0;  
    let componentQTDE = 1;
    if ( (!(typeof qtdeComponent === 'undefined')) && (qtdeComponent > 0) ){
        componentQTDE = qtdeComponent
    }
    
    for (let index = 0; index < items.length; index++) {
        const component = items[index];
        if ((hePizza) || (component.selected > 0)){
            if ((component.variableComposition) && (component.variable) && (component.variableComposition > 0)){
                let quantitySelected = 0;
                for (let i = 0; i < component.variable.components.length; i++) {
                    const item = component.variable.components[i];
                    if (item.selected > 0){
                        quantitySelected += item.selected;

                        if ( item.selecionados && (!(typeof item.selecionados === 'undefined')) && (item.selecionados.length > 0) ){

                            for (let s = 0; s < item.selecionados.length; s++) {
                                const selecionado = item.selecionados[s];

                                valor += item.value
                                if ((selecionado.manufactured === 'S') && 
                                    (selecionado.manufacturedAntecipated === 'N') &&
                                    (selecionado.structure) &&
                                    (selecionado.structure.length > 0)){
                                    valor += getAdditionalValueComponent(selecionado.structure, item.quantity)
                                }
                            }

                        } else {
                            valor += (item.selected * item.value)
                            if ((item.component) && 
                                (item.component.manufactured === 'S') && 
                                (item.component.manufacturedAntecipated === 'N') &&
                                (item.component.structure) &&
                                (item.component.structure.length > 0)){
                                valor += getAdditionalValueComponent(item.component.structure, 1)
                            }
                        }
                    }                          
                }

                if (!(typeof component.variable.additional === 'undefined')){
                    if (component.variable.additional === 'S'){
                        if ( (quantitySelected > component.variable.quantityadditional) && (component.variable.valueadditional > 0) ){                                
                            valor += ((quantitySelected - component.variable.quantityadditional) * component.variable.valueadditional);
                        }   
                    }
                }
                
            } else {
                if (!(typeof component.selected === 'undefined')){
                    if (component.component.type === 6){
                        if ( (component.selected > 0) && (!(typeof component.quantity === 'undefined')) ){
                            valor += (component.quantity * component.value)
                        }
                    } else {
                        valor += (component.selected * (componentQTDE * component.value))
                    }
                }

                if ((component.component) && 
                    (component.component.manufactured === 'S') && 
                    (component.component.manufacturedAntecipated === 'N')){
                    valor += getAdditionalValueComponent(component.component.structure, 1)
                }
            }
        }
    }
    return valor;
}

const getAdditionalValueComponent = (structure, qtdeComponent) => {
    let valor = 0
    
    let componentQTDE = 1;
    if ( (!(typeof qtdeComponent === 'undefined')) && (qtdeComponent > 0) ){
        componentQTDE = qtdeComponent
    }

    for (let index = 0; index < structure.length; index++) {
        const element = structure[index];
        if (element.selected > 0){
            if ((element.variableComposition) && (element.variable) && (element.variableComposition > 0)){
                for (let i = 0; i < element.variable.components.length; i++) {
                    const component = element.variable.components[i];
                    if (component.selected > 0){
                        valor += (component.selected * component.value)
                        if ((component.component) && 
                            (component.component.manufactured === 'S') && 
                            (component.component.manufacturedAntecipated === 'N')){
                            valor += getAdditionalValueComponent(component.component.structure, 1)
                        }
                    }                            
                }
            } else {

                if (element.component && (element.component.type === 6)){
                    if ( (element.selected > 0) && (!(typeof element.quantity === 'undefined')) ){
                        valor += (element.quantity * element.value)
                    }
                } else {
                    valor += (element.selected * (componentQTDE * element.value))
                }

                if ((element.component) && 
                    (element.component.manufactured === 'S') && 
                    (element.component.manufacturedAntecipated === 'N')){
                    valor += getAdditionalValueComponent(element.component.structure, 1)
                }
            }
        } 
    }
    return valor
}

/* ********************************************** */
/* *********** DETALHES DA COMPOSIÇÃO *********** */
/* ********************************************** */
export const getCompositionAdditional = (items, hekit, hePizza) => {
    let detalhes = '';           
    for (let index = 0; index < items.length; index++) {
        const component = items[index];

        if (hePizza){                
            if ((component.variableComposition) && (component.variable) && (component.variableComposition > 0)){   
                let classificacao = '';
                if (detalhes !== ''){
                    classificacao += `\n` + component.variable.name + `\n`
                } else {
                    classificacao += component.variable.name + `\n`
                }

                for (let i = 0; i < component.variable.components.length; i++) {
                    const item = component.variable.components[i];
                    if (item.selected > 0){
                        if (classificacao !== ''){
                            detalhes += classificacao
                            classificacao = ''
                        }

                        if (item.selected > 1){
                            detalhes += ` »  ` + item.selected + `x ` + item.component.name
                        } else {
                            detalhes += ` »  ` + item.component.name 
                        }
                        if (item.value > 0){
                            detalhes += `  ( + R$ ` + parseFloat(item.selected * item.value).toFixed(2) + ` )`
                        }
                        detalhes += `\n`

                        if ((item.component) && 
                            (item.component.manufactured === 'S') && 
                            (item.component.manufacturedAntecipated === 'N')){
                            detalhes += getComponentAdditional(item.component.structure, 1)
                        }
                    }                            
                }
            } else if (component.selected > 0) {
                if (component.selected > 1){
                    detalhes += `COM: ` + component.selected + `x ` + component.component.name
                } else {
                    detalhes += `COM: ` + component.component.name 
                }
                if (component.value > 0){
                    detalhes += `  ( + R$ ` + parseFloat(component.selected * component.value).toFixed(2) + ` )`
                }
                detalhes += `\n`
                if ((component.component) && 
                    (component.component.manufactured === 'S') && 
                    (component.component.manufacturedAntecipated === 'N')){
                    detalhes += getComponentAdditional(component.component.structure, 1)
                }
            }

        } else {
            if ((component.selected > 0) && (component.default !== 'S')){
                if ((component.variableComposition) && (component.variable) && (component.variableComposition > 0)){   
                    if (detalhes !== ''){
                        detalhes += `\n` + component.variable.name + `\n`
                    } else {
                        detalhes += component.variable.name + `\n`
                    }

                    for (let i = 0; i < component.variable.components.length; i++) {
                        const item = component.variable.components[i];
                        if (item.selected > 0){
                            
                            if (item.selecionados && item.selecionados.length > 0){

                                for (let j = 0; j < item.selecionados.length; j++) {
                                    const selecionado = item.selecionados[j];
                                    detalhes += ` »  COM: ` + selecionado.name;
                                    if (item.value > 0){
                                        detalhes += `  ( + R$ ` + parseFloat(item.selected * item.value).toFixed(2) + ` )`;
                                    }
                                    detalhes += `\n`;
                                    if ((selecionado.structure) && 
                                        (selecionado.structure.length > 0) &&
                                        (selecionado.manufactured === 'S') && 
                                        (selecionado.manufacturedAntecipated === 'N')){
                                        detalhes += getComponentAdditional(selecionado.structure, 1)
                                    }
                                }

                            } else {
                                if (item.selected > 1){
                                    detalhes += ` »  COM: ` + item.selected + `x ` + item.component.name;
                                } else {
                                    detalhes += ` »  COM: ` + item.component.name; 
                                }
                                if (item.value > 0){
                                    detalhes += `  ( + R$ ` + parseFloat(item.selected * item.value).toFixed(2) + ` )`;
                                }
                                detalhes += `\n`;
    
                                if ((item.component) && 
                                    (item.component.manufactured === 'S') && 
                                    (item.component.manufacturedAntecipated === 'N')){
                                    detalhes += getComponentAdditional(item.component.structure, 1)
                                }
                            }

                        }                            
                    }
                } else {
                    if (component.selected > 1){
                        detalhes += `COM: ` + component.selected + `x ` + component.component.name
                    } else {
                        detalhes += `COM: ` + component.component.name 
                    }
                    if (component.value > 0){
                        detalhes += `  ( + R$ ` + parseFloat(component.selected * component.value).toFixed(2) + ` )`
                    }
                    detalhes += `\n`
                    if ((component.component) && 
                        (component.component.manufactured === 'S') && 
                        (component.component.manufacturedAntecipated === 'N')){
                        detalhes += getComponentAdditional(component.component.structure, 1)
                    }
                }
            } else if ((component.id > 0) && (component.selected === 0) && (component.default === 'S')){
                detalhes += `SEM: ` + component.component.name + `\n`

            } else if ( (component.id > 0) && 
                        (component.selected > 0) && 
                        (component.component) && 
                        (component.default === 'S') &&
                        (component.component.structure) &&
                        (component.component.manufactured === 'S') && 
                        (component.component.manufacturedAntecipated === 'N') ){
                detalhes += `COM: ` + component.component.name + `\n`
                detalhes += getComponentAdditional(component.component.structure, 1)                
            }
        }

    }
    return detalhes;
}

const getComponentAdditional = (structure, nivel) => {
    let detalhes = ''
    for (let index = 0; index < structure.length; index++) {
        const element = structure[index];
        
        if ((element.selected > 0) && (element.default !== 'S')){
            if ((element.variableComposition) && (element.variable) && (element.variableComposition > 0)){                    
                let lista = element.variable.components.filter(componnet => { return componnet.selected > 0 })
                
                for (let n = 0; n < nivel; n++) {
                    if (n === nivel - 1){
                        detalhes += `      ╚ `
                    } else {
                        detalhes += `          `
                    }
                }
                detalhes += ` ` + element.variable.name + `\n`

                for (let i = 0; i < lista.length; i++) {
                    const component = lista[i];
                    if (component.selected > 0){
                        for (let n = 0; n <= nivel; n++) {
                            if (n === nivel){
                                detalhes += `      ╚ `
                            } else {
                                detalhes += `          `
                            }
                        }

                        if (component.selected > 1){
                            detalhes += ` ` + component.selected + `x ` + component.component.name
                        } else {
                            detalhes += ` ` + component.component.name 
                        }
                        if (component.value > 0){
                            detalhes += `  ( + R$ ` + parseFloat(component.selected * component.value).toFixed(2) + ` )`
                        }
                        detalhes += `\n`

                        if ((component.component) && 
                            (component.component.manufactured === 'S') && 
                            (component.component.manufacturedAntecipated === 'N')){
                            detalhes += getComponentAdditional(component.component.structure, nivel + 2)
                        }
                    }                            
                }
            } else {
                for (let n = 0; n < nivel; n++) {
                    if (n === nivel - 1){
                        detalhes += `      ╚ `
                    } else {
                        detalhes += `          `
                    }
                }

                if (element.selected > 1){
                    detalhes += ` ` + element.selected + `x ` + element.component.name
                } else {
                    detalhes += ` ` + element.component.name 
                }
                if (element.value > 0){
                    detalhes += `  ( + R$ ` + parseFloat(element.selected * element.value).toFixed(2) + ` )`
                }
                detalhes += `\n`

                if ((element.component) && 
                    (element.component.manufactured === 'S') && 
                    (element.component.manufacturedAntecipated === 'N')){
                    detalhes += getComponentAdditional(element.component.structure, nivel + 1)
                }
            }
        } else if (element.component && (element.id > 0) && (element.selected === 0) && (element.default === 'S')){
            for (let n = 0; n < nivel; n++) {
                if (n === nivel - 1){
                    detalhes += `      ╚ `
                } else {
                    detalhes += `          `
                }
            }
            detalhes += `SEM: ` + element.component.name + `\n`
        } else if ( (element.id > 0) && 
                    (element.selected > 0) && 
                    (element.component) && 
                    (element.default === 'S') &&
                    (element.component.structure) &&
                    (element.component.manufactured === 'S') && 
                    (element.component.manufacturedAntecipated === 'N') ){
            
            for (let n = 0; n < nivel; n++) {
                if (n === nivel - 1){
                    detalhes += `      ╚ `
                } else {
                    detalhes += `          `
                }
            }

            detalhes += `COM: ` + element.component.name + `\n`
            detalhes += getComponentAdditional(element.component.structure, nivel + 1)                
        } 
    }
    return detalhes
}